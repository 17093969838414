// React
import React from 'react';

// UI
import { FlexboxGrid, Col, Button, IconButton } from 'rsuite';
import AttachmentIcon from '@rsuite/icons/Attachment';
import TrashIcon from '@rsuite/icons/Trash';
import { FileAttachmentInterface } from '../ChatRoom';

interface FileAttachmentProps {
	files: any;
	setFiles: any;
}
export function ChatRoomAttachmentFile(fileAttachmentProps: FileAttachmentProps) {
	const { files, setFiles } = fileAttachmentProps;

	return (
		<div className="p-3">
			<div className="text-base font-semibold text-gray-900 px-2 pb-2">File Attached:-</div>
			{files.map((file: FileAttachmentInterface, index: number) => {
				return (
					<FlexboxGrid key={index} justify="center">
						<FlexboxGrid.Item className="py-3" colspan={24}>
							<FlexboxGrid.Item className="text-center" as={Col} xs={4} md={3} lg={2}>
								<AttachmentIcon className="w-6 h-6 text-gray-500" />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item
								className="truncate text-sm text-gray-600"
								as={Col}
								xs={16}
								md={18}
								lg={20}
							>
								{file.name}
							</FlexboxGrid.Item>
							<FlexboxGrid.Item as={Col} xs={4} md={3} lg={2}>
								<IconButton
									icon={<TrashIcon className="w-6 h-6" />}
									onClick={() => {
										const fileAttached = [...files];
										fileAttached.splice(index, 1);
										setFiles(fileAttached);
									}}
									appearance="subtle"
									style={{ padding: 0 }}
									className="text-center w-full text-red-400"
								/>
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				);
			})}
		</div>
	);
}
