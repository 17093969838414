// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Col, FlexboxGrid } from 'rsuite';
import Avatar from '@mui/material/Avatar';

// Reducer
import { store } from '../../ReduxRoot';
import { useSelector } from 'react-redux';

// FutureLab
import { ChatListDataInterface } from './ChatListItemsMobile';
import { chatItemInFocus } from '../../actions/chatItemInFocusActions';
import { RootState } from '../../reducers/index';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';

// 3rd Party
import moment from 'moment';

interface ChatListChatRequestComponentInterface {
	pendingChatListsItem: ChatListDataInterface;
	userListProfilesDetails: UserProfileDetails[];
}

export function ChatListChatRequest(chatListChatRequest: ChatListChatRequestComponentInterface) {
	const navigate = useNavigate();
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const { pendingChatListsItem, userListProfilesDetails } = chatListChatRequest;

	const getGroupChatNameFromParticipantNameInitials = (): string => {
		const groupParticipantIds = pendingChatListsItem.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		return userListProfilesFullDetails[0].firstname?.charAt(0);
	};

	const getGroupChatNameInitials = (): string => {
		const nameFromDb = pendingChatListsItem?.name?.trim()?.charAt(0);
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNameInitials();
	};

	const getGroupChatNameFromParticipantNames = (): string => {
		const groupParticipantIds = pendingChatListsItem.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		let finalGroupName = '';
		userListProfilesFullDetails.map(
			(userListProfileFullDetails, index) =>
				(finalGroupName += `${index > 0 ? ',' : ''} ${userListProfileFullDetails.firstname}`)
		);
		return finalGroupName;
	};

	const getGroupChatName = (): string => {
		const nameFromDb = pendingChatListsItem.name;
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNames();
	};

	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const selectPendingApprovalChat = (pendingChatListItem: ChatListDataInterface) => {
		if (getWindowSize(windowSize.width) === windowSizeType.XS) {
			navigate('../chat/chat-room-pending');
		}
		store.dispatch(chatItemInFocus(pendingChatListItem));
	};

	return (
		<div>
			<FlexboxGrid.Item
				colspan={24}
				className={
					chatInFocus?.id === pendingChatListsItem?.id
						? 'bg-blue-100 px-3.5 py-4 border-b border-solid'
						: 'px-3.5 py-4 border-b border-solid'
				}
				onClick={() => selectPendingApprovalChat(pendingChatListsItem)}
			>
				<FlexboxGrid.Item>
					<FlexboxGrid.Item as={Col} className="p-0">
						<Avatar variant="rounded" className="chat-list-image-holder">
							{getGroupChatNameInitials().toLocaleUpperCase()}
						</Avatar>
					</FlexboxGrid.Item>
					<FlexboxGrid>
						<FlexboxGrid.Item colspan={24}>
							<FlexboxGrid.Item
								colspan={18}
								as={Col}
								className="capitalize truncate text-gray-900 text-base pl-3 pb-1 font-medium"
							>
								{getGroupChatName()}
							</FlexboxGrid.Item>
							<FlexboxGrid.Item
								colspan={6}
								as={Col}
								className="text-gray-600 text-xxs text-right"
							>
								{moment(pendingChatListsItem.createdAt).format(
									moment(pendingChatListsItem.createdAt).isSame(moment(), 'day')
										? 'h:mma'
										: 'h:mma, D/M'
								)}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item
							className="truncate pl-3 text-sm text-gray-500"
							colspan={24}
						>
							You have a new group invitation
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</FlexboxGrid.Item>
			</FlexboxGrid.Item>
		</div>
	);
}
