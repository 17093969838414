import { LoaderActionTypes, LoaderActions } from '../model/index';
import createReducer from './createReducer';

export const showLoader = createReducer<boolean>(false, {
  [LoaderActionTypes.SHOW_LOADER](state: boolean, action: LoaderActions) {
    state = action.payload;
    return state;
  },
  [LoaderActionTypes.HIDE_LOADER](state: boolean, action: LoaderActions) {
    state = action.payload;
    return state;
  },
})
