// React
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

// FutureLab
import {
	HomePage,
	LoginPage,
	ChatPage,
	SessionsPage,
	ProfilePage,
	SignupPage,
	ResetPasswordPage,
	UpdatePasswordPage,
	AddChatPage,
	AddGroupChatPage,
	ChatRoomPage,
	ChatRoomPendingPage,
	ChatRedirectPage,
	FeedbackSessionPage,
	PreviousSessionPage,
	NewSessionPage,
	NotFoundPage,
} from './pages';
import AppChild from './AppChild';

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route path="/" element={<AppChild />}>
				<Route index element={<Navigate to="chat" />}/>
				<Route path="login" element={<LoginPage />} />
				<Route path="signup" element={<SignupPage />} />
				<Route path="reset-password" element={<ResetPasswordPage />} />
				<Route path="update-password" element={<UpdatePasswordPage />} />
				<Route path="sessions" element={<SessionsPage />} />
				<Route path="profile" element={<ProfilePage />} />
				<Route path="chat" element={<ChatPage />} />
				<Route path="chat/chat-room" element={<ChatRoomPage />} errorElement={<Navigate to="chat" />} />
				<Route path="chat-in-focus/:chatId" element={<ChatRedirectPage />} />
				<Route path="chat/chat-room-pending" element={<ChatRoomPendingPage />} />
				<Route path="chat/add-chat" element={<AddChatPage />} />
				<Route path="chat/add-group-chat" element={<AddGroupChatPage />} />
				<Route path="feedback-session" element={<FeedbackSessionPage />} />
				<Route path="new-session" element={<NewSessionPage />} />
				<Route path="previous-session" element={<PreviousSessionPage />} />
			</Route>
			<Route path="*" element={<NotFoundPage />} />
		</Route>
	)
);
