// React
import { useEffect, useState } from 'react';

// FutureLab
import { StylesDictionary } from '../components/common/WorkInProgressLogo';
import { windowSizeType, getWindowSize } from '../utilities/WindowResize';
import { DesktopChat } from '../components/chat/DesktopChat';

const styles: StylesDictionary = {
	chatListItem: {
		width: '100%',
	},
};

export function ChatPage() {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

  const isMobile = getWindowSize(windowSize.width) === windowSizeType.XS;

	return (
		<div style={styles.chatListItem}>
			<DesktopChat isMobile={isMobile}/>
		</div>
	);
}
