// React
import React from 'react';

// React-Redux
import { useSelector } from 'react-redux';

// UI
import { FlexboxGrid, Col, Avatar } from 'rsuite';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// FutureLab
import { RootState } from '../../reducers/index';
import { returnOneToOneChatUserDetails } from '../../utilities/UserDetails';
import UserDetails from '../popup/userDetails';
import GroupDetails from '../popup/groupDetails';
import { isChatApproved } from '../../utilities/Chat';
import { ModalConfirmation } from '../popup/modalConfirmation';
import { leaveChat } from '../../services/chatService';
import { chatItemInFocus } from '../../actions/chatItemInFocusActions';
import { store } from '../../ReduxRoot';
import { deleteAllMessages } from '../../services/chatService';
import { removeAllChatRoomPinnedMessages } from '../../actions/pinnedMessagesActions';
import avatarPlaceholder from '../../assets/image/avatar_placeholder.png'

const optionsPersonalChat = [
	{ option: 'Delete all chats', action: 'deleteAllChat' },
	// { option: 'Report this user', action: 'reportUser' },
];

const optionsGroupChat = [
	{ option: 'Delete all chats', action: 'deleteAllChat' },
	// { option: 'Report group', action: 'reportGroup' },
	{ option: 'Leave group', action: 'leaveGroup' },
];

export interface OptionStoreItem {
	option: string;
	action: string;
}

export function DesktopChatRoomHeader() {
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const [displayUserDetails, setDisplayUserDetails] = React.useState<boolean>(false);
	const [displayGroupDetails, setDisplayGroupDetails] = React.useState<boolean>(false);
	const [displayModalConfirmation, setDisplayModalConfirmation] = React.useState<boolean>(false);
	const [optionStore, setOptionStore] = React.useState<OptionStoreItem>({ option: '', action: '' });
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;

	const userProfileDetails = returnOneToOneChatUserDetails(chatInFocus, loggedInUserDetails, userListProfilesDetails);

	const userProfileDetailsFullName = `${userProfileDetails?.firstname} ${userProfileDetails?.lastname ?? ''}`;

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getGroupChatNameFromParticipantNameInitials = (): string => {
		const groupParticipantIds = chatInFocus.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		return userListProfilesFullDetails[0].firstname?.charAt(0);
	};

	const getGroupChatNameInitials = (): string => {
		const nameFromDb = chatInFocus?.name?.trim()?.charAt(0);
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNameInitials();
	};

	const getGroupChatNameFromParticipantNames = (): string => {
		const groupParticipantIds = chatInFocus.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		let finalGroupName = '';
		userListProfilesFullDetails.map(
			(userListProfileFullDetails, index) =>
				(finalGroupName += `${index > 0 ? ',' : ''} ${userListProfileFullDetails.firstname}`)
		);
		return finalGroupName;
	};

	const getGroupChatName = (): string => {
		const nameFromDb = chatInFocus.name;
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNames();
	};

	const checkGroupOption = chatInFocus?.chatAdmins?.some(
		(participant) => participant.userId === loggedInUserDetails.id
	)
		? optionsGroupChat
		: [optionsGroupChat[1]];

	const optionSelectorChatMessages = (option: string) => {
		if (option === optionsPersonalChat[0].option || option === optionsGroupChat[0].option) {
			deleteAllChatCallBack();
		}
		if (option === optionsGroupChat[1].option) {
			leaveGroupCallBack();
		}
	};

	const leaveGroupCallBack = () => {
		const currentUserId = loggedInUserDetails.id;
		const currentUserParticipantId = chatInFocus.participants
			.filter((participant) => participant.userId === currentUserId)
			.find((ele) => ele)?._id;
		leaveChat({
			chatId: chatInFocus?.id.toLocaleString(),
			participantId: currentUserParticipantId?.toLocaleString(),
		}).then((res) => {
			store.dispatch(chatItemInFocus(null));
			setDisplayModalConfirmation(false);
		});
	};

	const deleteAllChatCallBack = () => {
		deleteAllMessages({
			chatId: chatInFocus?.id.toLocaleString(),
		}).then((res) => {
			store.dispatch(removeAllChatRoomPinnedMessages(res.data.data.pinnedMessagesDeleted));
			setDisplayModalConfirmation(false);
		});
	};

	const openPopUp = (option: string) => {
		setDisplayModalConfirmation(!displayModalConfirmation);
		handleClose();
	};

	const renderGroupOption = () => {
		return checkGroupOption.map((optionGroupChat, index) => (
			<div key={index} onClick={() => setOptionStore(optionGroupChat)}>
				<MenuItem key={optionGroupChat.option} onClick={() => openPopUp(optionGroupChat.option)}>
					{optionGroupChat.option}
				</MenuItem>
			</div>
		));
	};

	const renderPersonalOption = () => {
		return optionsPersonalChat.map((optionPersonalChat, index) => (
			<div key={index} onClick={() => setOptionStore(optionPersonalChat)}>
				<MenuItem key={optionPersonalChat.option} onClick={() => openPopUp(optionPersonalChat.option)}>
					{optionPersonalChat.option}
				</MenuItem>
			</div>
		));
	};

	const ITEM_HEIGHT = 38;

	return (
		<div>
			{chatInFocus.isGroupChat ? (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="border-b-2 border-solid py-2 px-5">
						<FlexboxGrid.Item as={Col} xs={20} md={22} lg={22} style={{ paddingLeft: 0 }}>
							<FlexboxGrid.Item as={Col}>
								<Avatar
									className="h-12 w-12"
									onClick={() => {
										setDisplayGroupDetails(!displayGroupDetails);
									}}
								>
									{getGroupChatNameInitials().toLocaleUpperCase()}
								</Avatar>
							</FlexboxGrid.Item>
							{displayGroupDetails && (
								<GroupDetails
									setDisplayGroupDetails={setDisplayGroupDetails}
									displayGroupDetails={displayGroupDetails}
									getGroupChatNameInitials={getGroupChatNameInitials}
									getGroupChatName={getGroupChatName}
								/>
							)}
							<FlexboxGrid className="flex items-center h-12">
								<FlexboxGrid.Item
									as={Col}
									colspan={24}
									className="capitalize truncate pl-3 text-base text-gray-900"
								>
									{getGroupChatName()}
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item as={Col} md={2} lg={2} style={{ textAlign: 'right', padding: 0 }}>
							{isChatApproved(chatInFocus, loggedInUserDetails?.id) ? (
								<IconButton onClick={handleClick}>
									<MoreVertIcon />
								</IconButton>
							) : (
								''
							)}
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										maxHeight: ITEM_HEIGHT * 4.5,
										width: '20ch',
										textAlign: 'center',
									},
								}}
							>
								{renderGroupOption()}
							</Menu>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={() => optionSelectorChatMessages(optionStore?.option)}
						/>
					)}
				</FlexboxGrid>
			) : (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="border-b-2 border-solid py-2 px-5">
						<FlexboxGrid.Item as={Col} xs={20} md={22} lg={22} style={{ paddingLeft: 0 }}>
							<FlexboxGrid.Item as={Col}>
								{!!userProfileDetails?.profile_photo ? (
									<span
										onClick={() => {
											setDisplayUserDetails(!displayUserDetails);
										}}
									>
										<img
											src={`${photoUrl}/${userProfileDetails?.id}/${userProfileDetails?.profile_photo}`}
											className="chat-list-image-holder"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = avatarPlaceholder;
                      }}
										/>
									</span>
								) : (
									<Avatar
										className="h-12 w-12"
										onClick={() => {
											setDisplayUserDetails(!displayUserDetails);
										}}
									>
										{userProfileDetails?.firstname?.charAt(0).toLocaleUpperCase()}
										{userProfileDetails?.lastname?.charAt(0).toLocaleUpperCase() ?? ''}
									</Avatar>
								)}
							</FlexboxGrid.Item>
							{displayUserDetails && (
								<UserDetails
									setDisplayUserDetails={setDisplayUserDetails}
									displayUserDetails={displayUserDetails}
									userProfileDetails={userProfileDetails}
									photoUrl={photoUrl}
								/>
							)}
							<FlexboxGrid className="flex items-center h-12">
								<FlexboxGrid.Item
									as={Col}
									colspan={24}
									className="capitalize truncate pl-3 text-base text-gray-900"
								>
									{userProfileDetailsFullName}
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item as={Col} md={2} lg={2} style={{ textAlign: 'right', padding: 0 }}>
							<IconButton onClick={handleClick}>
								<MoreVertIcon />
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										maxHeight: ITEM_HEIGHT * 4.5,
										width: '20ch',
										textAlign: 'center',
									},
								}}
							>
								{renderPersonalOption()}
							</Menu>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={() => optionSelectorChatMessages(optionStore?.option)}
						/>
					)}
				</FlexboxGrid>
			)}
		</div>
	);
}
