import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser'; // Assuming Mixpanel is properly imported and configured

const useTrackPageView = () => {
	const location = useLocation();

	useEffect(() => {
		const trackName = getPathName(location.pathname);
		mixpanel.track(trackName);
	}, [location]);
};

// Helper function to extract the path name
const getPathName = (pathname: string) => {
	const route = pathName.find((route) => route.path === pathname);
	return route ? route.trackName : '';
};

// Define the path name mappings
const pathName = [
	{ path: '/', trackName: 'View Home Page' },
	{ path: '/login', trackName: 'View Login Page' },
	{ path: '/signup', trackName: 'View Sign Up Page' },
	{ path: '/reset-password', trackName: 'View Reset Password Page' },
	{ path: '/update-password', trackName: 'View Update Password Page' },
	{ path: '/sessions', trackName: 'View Sessions Page' },
	{ path: '/profile', trackName: 'View Profile Page' },
	{ path: '/chat', trackName: 'View Chat Page' },
	{ path: '/chat/chat-room', trackName: 'Enter Chat Room' },
	{ path: '/chat-in-focus/:chatId?', trackName: 'Chat Redirect' },
	{ path: '/chat/chat-room-pending', trackName: 'Enter Pending Chat Room' },
	{ path: '/chat/add-chat', trackName: 'Developer Create Chat Room Page' },
	{ path: '/chat/add-group-chat', trackName: 'Developer Create Group Chat Room Page' },
	{ path: '/feedback-session', trackName: 'View Feedback Session Page' },
	{ path: '/new-session', trackName: 'View New Session Page' },
	{ path: '/previous-session', trackName: 'View Previous Session Page' },
];

export default useTrackPageView;
