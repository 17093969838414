// React
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { OptionStoreItem } from '../../chat/DesktopChatRoomHeader';
import { ChatListDataInterface } from '../../chat/ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../../reducers';
import { UserProfileDetails } from '../../../pages';
import { deleteAllMessages, leaveChat } from '../../../services/chatService';
import { store } from '../../../ReduxRoot';
import { chatItemInFocus } from '../../../actions/chatItemInFocusActions';
import { removeAllChatRoomPinnedMessages } from '../../../actions/pinnedMessagesActions';
import GroupChatTopNavBar from './GroupChatTopNavBar';
import PersonalChatTopNavBar from './PersonalChatTopNavBar';

const optionsPersonalChat = [
	{ option: 'Delete all chats', action: 'deleteAllChat' },
	// { option: 'Report this user', action: 'reportUser' },
];

const optionsGroupChat = [
	{ option: 'Delete all chats', action: 'deleteAllChat' },
	// { option: 'Report group', action: 'reportGroup' },
	{ option: 'Leave group', action: 'leaveGroup' },
];

interface MobileChatTopNavBarProps {
	chatInFocus: ChatListDataInterface;
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	photoUrl: string;
	userProfileDetails: UserProfileDetails | undefined;
}

export default function MobileChatTopNavBar(mobileChatTopNavBarProps: MobileChatTopNavBarProps) {
	const { chatInFocus, loggedInUserDetails, userListProfilesDetails, photoUrl, userProfileDetails } =
		mobileChatTopNavBarProps;

	const [displayModalConfirmation, setDisplayModalConfirmation] = useState<boolean>(false);
	const [optionStore, setOptionStore] = useState<OptionStoreItem>({ option: '', action: '' });
	const navigate = useNavigate();

	const optionSelectorChatMessages = (option: string) => {
		if (option === optionsPersonalChat[0].option || option === optionsGroupChat[0].option) {
			deleteAllChatCallBack();
		}
		if (option === optionsGroupChat[1].option) {
			leaveGroupCallBack();
		}
	};

	const leaveGroupCallBack = () => {
		const currentUserId = loggedInUserDetails.id;
		const currentUserParticipantId = chatInFocus.participants
			.filter((participant) => participant.userId === currentUserId)
			.find((ele) => ele)?._id;
		leaveChat({
			chatId: chatInFocus?.id.toLocaleString(),
			participantId: currentUserParticipantId?.toLocaleString(),
		}).then((res) => {
			store.dispatch(chatItemInFocus(null));
			setDisplayModalConfirmation(false);
		});
	};

	const deleteAllChatCallBack = () => {
		deleteAllMessages({
			chatId: chatInFocus?.id.toLocaleString(),
		}).then((res) => {
			store.dispatch(removeAllChatRoomPinnedMessages(res.data.data.pinnedMessagesDeleted));
			setDisplayModalConfirmation(false);
		});
	};

	const goToChatPage = () => {
		navigate('../chat');
		store.dispatch(chatItemInFocus(null));
	};

	return chatInFocus.isGroupChat ? (
		<GroupChatTopNavBar
			chatInFocus={chatInFocus}
			loggedInUserDetails={loggedInUserDetails}
			userListProfilesDetails={userListProfilesDetails}
			goToChatPage={goToChatPage}
			displayModalConfirmation={displayModalConfirmation}
			setDisplayModalConfirmation={setDisplayModalConfirmation}
			optionSelectorChatMessages={optionSelectorChatMessages}
			optionStore={optionStore}
			setOptionStore={setOptionStore}
			optionsGroupChat={optionsGroupChat}
		/>
	) : (
		<PersonalChatTopNavBar
			chatInFocus={chatInFocus}
			loggedInUserDetails={loggedInUserDetails}
			userProfileDetails={userProfileDetails}
			goToChatPage={goToChatPage}
			displayModalConfirmation={displayModalConfirmation}
			setDisplayModalConfirmation={setDisplayModalConfirmation}
			optionSelectorChatMessages={optionSelectorChatMessages}
			optionStore={optionStore}
			setOptionStore={setOptionStore}
			optionsPersonalChat={optionsPersonalChat}
			photoUrl={photoUrl}
		/>
	);
}
