// React
import React, { useState } from 'react';

// FutureLab
import { isChatApproved } from '../../../utilities/Chat';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import UserDetails from '../../popup/userDetails';
import avatarPlaceholder from '../../../assets/image/avatar_placeholder.png'
import { ChatListDataInterface } from '../../chat/ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../../reducers';
import { UserProfileDetails } from '../../../pages';
import { OptionStoreItem } from '../../chat/DesktopChatRoomHeader';

// UI
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Col, Row } from 'rsuite';
import { IconButton, Menu, MenuItem } from '@mui/material';


interface PersonalChatTopNavBarProps {
	chatInFocus: ChatListDataInterface;
	loggedInUserDetails: LoggedInUserDetails;
	userProfileDetails: UserProfileDetails | undefined;
	goToChatPage: Function;
	displayModalConfirmation: boolean;
	setDisplayModalConfirmation: any;
	optionSelectorChatMessages: Function;
	optionStore: OptionStoreItem;
	setOptionStore: any;
	optionsPersonalChat: OptionStoreItem[];
	photoUrl: string;
}

export default function PersonalChatTopNavBar(personalChatTopNavBarProps: PersonalChatTopNavBarProps) {
	const {
		chatInFocus,
		loggedInUserDetails,
		userProfileDetails,
		goToChatPage,
		displayModalConfirmation,
		setDisplayModalConfirmation,
		optionSelectorChatMessages,
		optionStore,
		setOptionStore,
		optionsPersonalChat,
		photoUrl,
	} = personalChatTopNavBarProps;
	const [displayUserDetails, setDisplayUserDetails] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openPopUp = (option: string) => {
		setDisplayModalConfirmation(!displayModalConfirmation);
		handleClose();
	};

	const userProfileDetailsFullName = `${userProfileDetails?.firstname} ${userProfileDetails?.lastname ?? ''}`;

	const renderPersonalOption = () => {
		return optionsPersonalChat.map((optionPersonalChat, index) => (
			<div key={index} onClick={() => setOptionStore(optionPersonalChat)}>
				<MenuItem key={optionPersonalChat.option} onClick={() => openPopUp(optionPersonalChat.option)}>
					{optionPersonalChat.option}
				</MenuItem>
			</div>
		));
	};

	return (
		<Row className="bg-white flex items-center align-middle border-b-2 border-solid" style={{ height: 60 }}>
			<Col xs={4} className="text-center p-0 ml-1">
				<KeyboardBackspaceIcon htmlColor="#575757" onClick={() => goToChatPage()} />
			</Col>
			<Col xs={5} className="text-center justify-center flex">
				{!!userProfileDetails?.profile_photo ? (
					<span
						onClick={() => {
							setDisplayUserDetails(!displayUserDetails);
						}}
					>
						<img
							src={`${photoUrl}/${userProfileDetails?.id}/${userProfileDetails?.profile_photo}`}
							className="chat-list-image-holder"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarPlaceholder;
              }}
						/>
					</span>
				) : (
					<Avatar
						onClick={() => {
							setDisplayUserDetails(!displayUserDetails);
						}}
					>
						{userProfileDetails?.firstname?.charAt(0).toLocaleUpperCase()}
						{userProfileDetails?.lastname?.charAt(0).toLocaleUpperCase() ?? ''}
					</Avatar>
				)}
			</Col>
			{displayUserDetails && (
				<UserDetails
					setDisplayUserDetails={setDisplayUserDetails}
					displayUserDetails={displayUserDetails}
					userProfileDetails={userProfileDetails}
					photoUrl={photoUrl}
				/>
			)}
			<Col xs={12}>
				<Row>
					<Col xs={24} className="truncate capitalize text-base">
						{userProfileDetailsFullName}
					</Col>
				</Row>
			</Col>
			<Col xs={3}>
				{isChatApproved(chatInFocus, loggedInUserDetails?.id) && (
					<IconButton onClick={handleClick} style={{ padding: 0 }}>
						<MoreVertIcon htmlColor="#575757" />
					</IconButton>
				)}
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: 38 * 4.5,
							width: '20ch',
							textAlign: 'center',
						},
					}}
				>
					{renderPersonalOption()}
				</Menu>
			</Col>
			{displayModalConfirmation && (
				<ModalConfirmation
					displayModalConfirmation={displayModalConfirmation}
					setDisplayModalConfirmation={setDisplayModalConfirmation}
					optionStore={optionStore}
					callBackProceedMethodProp={() => optionSelectorChatMessages(optionStore?.option)}
				/>
			)}
		</Row>
	);
}
