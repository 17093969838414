// Axios
import { AxiosResponse } from 'axios';

// Future Lab
import { axiosInstance } from '../axiosInstance';
const authBaseUrl = `${process.env.REACT_APP_CHAT_API_BASE_URL}/api/`;

/**
 * gets all the chat lists from the db
 *
 * @param  {object} config
 * @returns Promise
 */
export function chatListEndPoint(offset?: number, limit?: number): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}chat/list?offset=${offset}&limit=${limit}`);
}

/**
 * gets all the chat lists pending approval from the db
 *
 * @param  {object} config
 * @returns Promise
 */
export function chatPendingListEndPoint(): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}chat/pending/list`);
}

/**
 * creates a new one to one chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function createPrivateGroupChat(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/create-private-chat`, config);
}

/**
 * creates a new one to one chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function createGroupChat(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/create-group-chat`, config);
}

/**
 * fetches data for the chat room
 *
 * @param  {object} config
 * @returns Promise
 */
export function getChatRoomItems(config: object, offset?: number, limit?: number): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/list?offset=${offset}&limit=${limit}`, config);
}

/**
 * updates the seen by of the chat roon by the user
 *
 * @param  {object} config
 * @returns Promise
 */
export function seenChatRoomItems(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/seen-messages`, config);
}

/**
 * store the messages that have been pinned
 *
 * @param  {object} config
 * @returns Promise
 */
export function pinMessage(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/pin-message`, config);
}

/**
 * unpin message that have already been pinned from the chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function unpinMessage(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/unpin-message`, config);
}

/**
 * delete message from the chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function deleteMessages(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/delete-message`, config);
}

/**
 * delete all messages from the chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function deleteAllMessages(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/delete-all-chat-messages`, config);
}

/**
 * get all pinned message for the loggedInUser
 *
 * @param  {object} config
 * @returns Promise
 */
export function allPinnedMessages(): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}chat/message/pinned-messages`, {});
}

/**
 * accepts pending approval for chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function acceptInvitation(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/accept-invitation`, config);
}

/**
 * declines pending approval for chat
 *
 * @param  {object} config
 * @returns Promise
 */
export function declineInvitation(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/decline-invitation`, config);
}

/**
 * leave chat room
 *
 * @param  {object} config
 * @returns Promise
 */
export function leaveChat(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/leave-chat`, config);
}

/**
 * gets the chat details by id
 *
 * @param  {object} config
 * @returns Promise
 */
export function getChatDetailsById(chatId: string): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}chat/details/${chatId}`);
}

/**
 * upload an attachment 
 *
 * @param  {object} config
 * @returns Promise
 */
export function uploadAttachment(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/upload-attachment`, config);
}

/**
 * renew an attachment link 
 *
 * @param  {object} config
 * @returns Promise
 */
export function renewAttachment(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/message/renew/attachment`, config);
}

/**
 *
 * Init Notification
 *
 * @param  {object} config
 * @returns Promise
 */

export function initNotification(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}chat/notification-init`, config);
}
