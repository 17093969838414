// FutureLab
import createReducer from './createReducer';
import { ProgramQuestionInterface } from '../components/feedback/SessionFeedback';
import { FeedbackSessionQuestion, FeedbackSessionQuestionAction } from '../model/index';

// 3rd Party
import _ from 'lodash';

export const programQuestion = createReducer<ProgramQuestionInterface[]>([], {
	[FeedbackSessionQuestion.FEEDBACK_SESSION_QUESTION](
		state: ProgramQuestionInterface[],
		action: FeedbackSessionQuestionAction
	) {
		state = action.payload;
		return state;
	},
});
