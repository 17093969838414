import { useState, useRef, useEffect } from 'react';

type IntersectionProps = {
	root?: null | Element;
	rootMargin?: string;
	threshold?: number;
	callback?: () => void;
};

const useIntersection = (props: IntersectionProps) => {
	const { root = null, rootMargin, threshold = 1.0, callback = () => {} } = props;
	const [intesecting, setIntersecting] = useState<boolean>(false);
	const targetRef = useRef<null | Element | undefined>(null);
	const setRef = (node: any) => {
		targetRef.current = node;
	};

	useEffect(() => {
		if (!targetRef.current) return;
		let isUnmounted = false;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (isUnmounted) return;
				const intersect = entry.isIntersecting;
				setIntersecting(intersect);
				// disconnect after first intersection
				if (intersect) {
					observer.disconnect();
					callback();
				}
			},
			{
				root,
				rootMargin,
				threshold,
			}
		);
		const { current: currentTarget } = targetRef;
		if (currentTarget) {
			observer.observe(currentTarget);
		}
		return () => {
			observer.disconnect();
			isUnmounted = true;
		};
	}, [root, targetRef.current]);

	return [setRef, intesecting];
};

export default useIntersection;
