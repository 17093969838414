// FutureLab
import '../../assets/loader.css';
import animation from '../../assets/loader.json';
import { Loader as MyLoader } from 'rsuite';

export default function Loader() {
	return (
		<div className='loader-wrapper'>
			<MyLoader backdrop vertical size="lg" content="Loading..."/>
		</div>
	);
}
