// FutureLab
import createReducer from './createReducer';
import { PinnedMessageInFocusTypes, PinnedMessageInFocusActions } from '../model/index';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';

export const pinnedMessageInfocus = createReducer<PinnedMessageItem | null>(null, {
	[PinnedMessageInFocusTypes.PINNED_MESSAGE_IN_FOCUS](
		state: PinnedMessageItem | null,
		action: PinnedMessageInFocusActions
	) {
		state = action.payload;
		return state;
	},
});
