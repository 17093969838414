import createReducer from './createReducer';
import { PushNotificationActions, PushNotificationTypes, PushNotificationData } from '../model';

const initialState = {
	fcmToken: '',
	userId: null,
	isRegistered: false,
} as PushNotificationData;

export const pushNotifReducer = createReducer<PushNotificationData>(initialState, {
	[PushNotificationTypes.REGISTER_NOTIFICATION](state: PushNotificationData, action: PushNotificationActions) {
		state = action.payload;
		return state;
	},
	[PushNotificationTypes.UNREGISTER_NOTIFICATION](state: PushNotificationData, action: PushNotificationActions) {
		state = initialState;
		return state;
	},
});
