// React
import React, { useState } from 'react';

// FutureLab
import GroupDetails from '../../popup/groupDetails';
import { isChatApproved } from '../../../utilities/Chat';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import { ChatListDataInterface } from '../../chat/ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../../reducers';
import { UserProfileDetails } from '../../../pages';

// UI
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Col, Row } from 'rsuite';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { OptionStoreItem } from '../../chat/DesktopChatRoomHeader';

interface GroupChatTopNavBarProps {
	chatInFocus: ChatListDataInterface;
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	goToChatPage: Function;
	displayModalConfirmation: boolean;
	setDisplayModalConfirmation: any;
	optionSelectorChatMessages: Function;
	optionStore: OptionStoreItem;
	setOptionStore: any;
	optionsGroupChat: OptionStoreItem[];
}

export default function GroupChatTopNavBar(groupChatTopNavBarProps: GroupChatTopNavBarProps) {
	const {
		chatInFocus,
		loggedInUserDetails,
		userListProfilesDetails,
		goToChatPage,
		displayModalConfirmation,
		setDisplayModalConfirmation,
		optionSelectorChatMessages,
		optionStore,
		setOptionStore,
		optionsGroupChat,
	} = groupChatTopNavBarProps;

	const [displayGroupDetails, setDisplayGroupDetails] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openPopUp = (option: string) => {
		setDisplayModalConfirmation(!displayModalConfirmation);
		handleClose();
	};

	const checkGroupOption = chatInFocus?.chatAdmins?.some(
		(participant) => participant.userId === loggedInUserDetails.id
	)
		? optionsGroupChat
		: [optionsGroupChat[1]];

	const getGroupChatNameFromParticipantNameInitials = (): string => {
		const groupParticipantIds = chatInFocus.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		return userListProfilesFullDetails[0].firstname?.charAt(0);
	};

	const getGroupChatNameInitials = (): string => {
		const nameFromDb = chatInFocus?.name?.trim()?.charAt(0);
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNameInitials();
	};

	const getGroupChatNameFromParticipantNames = (): string => {
		const groupParticipantIds = chatInFocus.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		let finalGroupName = '';
		userListProfilesFullDetails.map(
			(userListProfileFullDetails, index) =>
				(finalGroupName += `${index > 0 ? ',' : ''} ${userListProfileFullDetails.firstname}`)
		);
		return finalGroupName;
	};

	const renderGroupOption = () => {
		return checkGroupOption.map((optionGroupChat, index) => (
			<div key={index} onClick={() => setOptionStore(optionGroupChat)}>
				<MenuItem key={optionGroupChat.option} onClick={() => openPopUp(optionGroupChat.option)}>
					{optionGroupChat.option}
				</MenuItem>
			</div>
		));
	};

	const getGroupChatName = (): string => {
		const nameFromDb = chatInFocus.name;
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNames();
	};

	return (
		<Row className="bg-white flex items-center align-middle border-b-2 border-solid" style={{ height: 60 }}>
			<Col xs={4} className="text-center p-0 ml-1">
				<KeyboardBackspaceIcon htmlColor="#575757" onClick={() => goToChatPage()} />
			</Col>
			<Col xs={5} className="text-center">
				<Avatar
					onClick={() => {
						setDisplayGroupDetails(!displayGroupDetails);
					}}
				>
					{getGroupChatNameInitials().toLocaleUpperCase()}
				</Avatar>
			</Col>
			{displayGroupDetails && (
				<GroupDetails
					setDisplayGroupDetails={setDisplayGroupDetails}
					displayGroupDetails={displayGroupDetails}
					getGroupChatNameInitials={getGroupChatNameInitials}
					getGroupChatName={getGroupChatName}
				/>
			)}
			<Col xs={13}>
				<Row>
					<Col xs={24} className="truncate capitalize text-base">
						{getGroupChatName()}
					</Col>
				</Row>
				<Row>
					<Col xs={24} className="text-xxs italic">
						{chatInFocus.participants.length} members
					</Col>
				</Row>
			</Col>
			<Col xs={3}>
				{isChatApproved(chatInFocus, loggedInUserDetails?.id) && (
					<IconButton onClick={handleClick} style={{ padding: 0 }}>
						<MoreVertIcon htmlColor="#575757" />
					</IconButton>
				)}
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: 38 * 4.5,
							width: '20ch',
							textAlign: 'center',
						},
					}}
				>
					{renderGroupOption()}
				</Menu>
			</Col>
			{displayModalConfirmation && (
				<ModalConfirmation
					displayModalConfirmation={displayModalConfirmation}
					setDisplayModalConfirmation={setDisplayModalConfirmation}
					optionStore={optionStore}
					callBackProceedMethodProp={() => optionSelectorChatMessages(optionStore?.option)}
				/>
			)}
		</Row>
	);
}
