// FutureLab
import { showOverlayInterceptor, hideOverlayInterceptor } from './services/overlayInterceptor';
import { navigateToRoute } from './utilities/NavigateTo';

// 3rd Party
import axios from 'axios';
import Cookies from 'js-cookie';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
	async (config) => {
		if (config && config.headers) {
			config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
		}
		// showOverlayInterceptor(config);
		return config;
	},
	(error) => {
		// hideOverlayInterceptor();
		Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		// hideOverlayInterceptor();
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			navigateToRoute('../login');
		}
		// hideOverlayInterceptor();
		return Promise.reject(error.response);
	}
);

const pwaCookie = process.env.PWA_COOKIE_NAME || 'pwa_staging_session';

export const axiosInstanceRails = axios.create();

axiosInstanceRails.interceptors.request.use(
	async (config) => {
		if (config && config.headers) {
			config.headers.Authorization = `Bearer ${Cookies.get(pwaCookie)}`;
		}
		// showOverlayInterceptor(config);
		return config;
	},
	(error) => {
		// hideOverlayInterceptor();
		Promise.reject(error);
	}
);

axiosInstanceRails.interceptors.response.use(
	(response) => {
		// hideOverlayInterceptor();
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			navigateToRoute('../login');
		}
		// hideOverlayInterceptor();
		return Promise.reject(error.response);
	}
);
