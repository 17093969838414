// FutureLab
import { PendingChatMessagesActions, PendingApprovalChatTypes } from '../model/index';
import { ChatListDataInterface } from '../components/chat/ChatListItemsDesktop';

export function loadPendingApprovalChatDetails(
	chatListPendingApproval: ChatListDataInterface[]
): PendingChatMessagesActions {
	return {
		type: PendingApprovalChatTypes.LOAD_PENDING_APPROVAL_CHAT,
		payload: chatListPendingApproval,
	};
}

export function addPendingApprovalChatDetails(
	chatListPendingApproval: ChatListDataInterface[]
): PendingChatMessagesActions {
	return {
		type: PendingApprovalChatTypes.ADD_PENDING_APPROVAL_CHAT,
		payload: chatListPendingApproval,
	};
}

export function removePendingApprovalChatDetails(
	chatListPendingApproval: ChatListDataInterface[]
): PendingChatMessagesActions {
	return {
		type: PendingApprovalChatTypes.REMOVE_PENDING_APPROVAL_CHAT,
		payload: chatListPendingApproval,
	};
}
