// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { store } from '../ReduxRoot';
import { chatItemInFocus } from '../actions/chatItemInFocusActions';
import { LoggedInUserDetails } from '../reducers/index';
import { UserProfileDetails } from '../pages/AddChatPage';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';
import { pinnedMessageInFocus } from '../actions/pinnedMessageInFocusActions';
import { navigateToRoute } from './NavigateTo';
import { chatList } from '../actions/chatListActions';

// 3rd Party
import _ from 'lodash';

export function navigateToChatRoom(chatRoom: ChatListDataInterface, route: string) {
	store.dispatch(chatItemInFocus(chatRoom));
	navigateToRoute(route);
}

export function openChatRoom(chatRoom: ChatListDataInterface) {
	store.dispatch(pinnedMessageInFocus(null));
	store.dispatch(chatItemInFocus(chatRoom));
}

export function getQueryResults(
	chatListsItems: ChatListDataInterface[],
	loggedInUserDetails: LoggedInUserDetails,
	userListProfilesDetails: UserProfileDetails[],
	filterQuery: string
) {
	return chatListsItems.filter((chatList) => {
		const otherPersonInChatRoom = chatList?.participants?.find(
			(participant) => participant.userId !== loggedInUserDetails.id
		);
		const otherPersonInChatRoomDetails = userListProfilesDetails.find(
			(participant) => participant.id === otherPersonInChatRoom?.userId
		);

		const otherPersonInChatRoomDetailsFullName = `${otherPersonInChatRoomDetails?.firstname} ${otherPersonInChatRoomDetails?.lastname ?? ''}`;

		if (!!chatList.isGroupChat) {
			return (
				(chatList?.name?.toLocaleLowerCase()?.includes(filterQuery.toLocaleLowerCase()) &&
					!!chatList.isGroupChat) ||
				!chatList.name
			);
		} else {
			return otherPersonInChatRoomDetailsFullName
				.toLocaleLowerCase()
				.includes(filterQuery.toLocaleLowerCase()) /* ||
				loggedInUserDetails.email.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase()) */;
		}
	});
}

export function getPinnedMessagesQueryResults(
	pinnedMessages: PinnedMessageItem[],
	userListProfilesDetails: UserProfileDetails[],
	filterQuery: string
) {
	return pinnedMessages.filter((pinnedMessage) => {
		const targetUser = userListProfilesDetails.find(
			(userProfilesDetails) => userProfilesDetails?.id === pinnedMessage?.messageSenderId?.userId
		);

		const targetUserFullName = `${targetUser?.firstname} ${targetUser?.lastname ?? ''}`;

		return (
			pinnedMessage?.chatMessageId?.message?.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase()) ||
			targetUserFullName.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase())
		);
	});
}

export function isChatApproved(chatDetails: ChatListDataInterface, userId: number): boolean {
	return !chatDetails.pendingApprovalParticipants.some(
		(pendingApprovalParticipant) => pendingApprovalParticipant.userId === userId
	);
}

export function getAllParticipantsIds(chatListsdetailsData: any[], chatPendingListEndDetailsData: any[]) {
	const allParticipantsIdsFiltered: number[] = [];

	const processParticipants = (participants: any[]) => {
		participants.forEach((participant: any) => {
			if (allParticipantsIdsFiltered.indexOf(participant.userId) === -1) {
				allParticipantsIdsFiltered.push(participant.userId);
			}
		});
	};

	chatListsdetailsData.forEach((chatListItem) => {
		processParticipants(chatListItem.participants);
	});

	chatPendingListEndDetailsData.forEach((chatListItem) => {
		processParticipants(chatListItem.participants);
	});

	return allParticipantsIdsFiltered;
}

export function updateChatList(
	chatListsItems: ChatListDataInterface[],
	newMessage: any,
	chatInFocus: ChatListDataInterface,
	loggedInUserDetails: LoggedInUserDetails,
	dispatch: any
) {
	if (!_.isEmpty(newMessage)) {
		const currentUserId = loggedInUserDetails.id;
		const newConversation = chatListsItems.map((con) => {
			const loggedInUserParticipantId = con.participants.find(
				(participant) => participant.userId === currentUserId
			)?._id;
			if (con.id === newMessage.chatId) {
				con.latestAttachment = newMessage.attachments;
				con.latestMessage = newMessage.message;
				con.latestMessageDateTime = newMessage.createdAt;
				if (loggedInUserParticipantId !== newMessage.participantId && newMessage.chatId !== chatInFocus?.id) {
					con.unreadCount += 1;
				}
			}
			return con;
		});
		return dispatch(chatList(_.orderBy(newConversation, ['latestMessageDateTime'], ['desc'])));
	}
}
