// React
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Col, FlexboxGrid } from 'rsuite';
import ArowBackIcon from '@rsuite/icons/ArowBack';

// FutureLab
import FeedbackMenu from './FeedbackMenu';
import PreviousSession from './PreviousSessionMenu';
import SessionFeedback from './SessionFeedback';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

export function DesktopFeedback() {
	const [activeItem, setActiveItem] = useState(null);
	const currentFormInFocus = useSelector((state: RootState) => state.feedbackFormInFocus);
	const handleItemClick = (itemId: any) => {
		setActiveItem((prevState) => {
			if (prevState === itemId) {
				return itemId;
			} else {
				return itemId;
			}
		});
	};
	const navigate = useNavigate();

	return (
		<FlexboxGrid>
			{currentFormInFocus > 1 ? (
				<FlexboxGrid.Item colspan={24} className="flex justify-center">
					<FlexboxGrid.Item as={Col} sm={24} md={21} lg={16} xl={12}>
						<SessionFeedback />
					</FlexboxGrid.Item>
				</FlexboxGrid.Item>
			) : (
				<>
					<FlexboxGrid.Item colspan={24} className="flex justify-center">
						<FlexboxGrid.Item
							as={Col}
							sm={24}
							md={21}
							lg={16}
							xl={12}
							className="pb-5 pt-7 border-b border-gray-200"
						>
							<FlexboxGrid.Item
								as={Col}
								colspan={5}
								onClick={() => navigate('..')}
								className="text-center"
							>
								<ArowBackIcon className="w-6 h-6" />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={19} as={Col} className="session-feedback-desktop">
								Submit Session Feedback
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="flex justify-center">
						<FlexboxGrid.Item as={Col} sm={24} md={21} lg={16} xl={12}>
							<FlexboxGrid.Item colspan={10} as={Col} className="px-0">
								<FeedbackMenu activeItem={activeItem} onItemClick={handleItemClick} />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={14} as={Col} className="px-0">
								{activeItem === 'previousSession' && <PreviousSession />}
								{activeItem === 'newSession' && <SessionFeedback />}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</>
			)}
		</FlexboxGrid>
	);
}
