// React
import React from 'react';

// FutureLab
import { ChatListDataInterface } from '../ChatListItemsMobile';
import { returnOneToOneChatUserDetails } from '../../../utilities/UserDetails';
import { LoggedInUserDetails } from '../../../reducers/index';
import { UserProfileDetails } from '../../../pages/AddChatPage';
import avatarPlaceholder from '../../../assets/image/avatar_placeholder.png'

// UI
import { Col, FlexboxGrid } from 'rsuite';
import Avatar from '@mui/material/Avatar';

interface ChatListItemImageProps {
	chatListItem: ChatListDataInterface;
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
}

export function ChatListItemImage(chatListItemImageProps: ChatListItemImageProps) {
	const { chatListItem, loggedInUserDetails, userListProfilesDetails } = chatListItemImageProps;
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;
	const userProfileDetails = returnOneToOneChatUserDetails(
		chatListItem,
		loggedInUserDetails,
		userListProfilesDetails
	);

	const getGroupChatNameFromParticipantNameInitials = (): string => {
		const groupParticipantIds = chatListItem.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		return userListProfilesFullDetails[0]?.firstname?.charAt(0);
	};

	const getGroupChatNameInitials = (): string => {
		const nameFromDb = chatListItem?.name?.trim()?.charAt(0);
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNameInitials();
	};

	return (
		<FlexboxGrid.Item className="p-0" as={Col}>
			{chatListItem.isGroupChat ? (
				<Avatar variant="rounded" className="chat-list-image-holder">
					{getGroupChatNameInitials()?.toLocaleUpperCase()}
				</Avatar>
			) : !!userProfileDetails?.profile_photo ? (
				<img
					src={`${photoUrl}/${userProfileDetails?.id}/${userProfileDetails?.profile_photo}`}
					className="chat-list-image-holder"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = avatarPlaceholder;
          }}
				/>
			) : (
				<Avatar
					variant="rounded"
					src={
						returnOneToOneChatUserDetails(chatListItem, loggedInUserDetails, userListProfilesDetails)
							?.profile_photo
					}
					className="chat-list-image-holder"
				>
					{returnOneToOneChatUserDetails(chatListItem, loggedInUserDetails, userListProfilesDetails)
						?.firstname?.charAt(0)
						.toLocaleUpperCase()}
					{returnOneToOneChatUserDetails(chatListItem, loggedInUserDetails, userListProfilesDetails)
						?.lastname?.charAt(0)
						.toLocaleUpperCase() ?? ''}
				</Avatar>
			)}
		</FlexboxGrid.Item>
	);
}
