//@ts-nocheck
// UI
import { Radio, Input, RadioGroup, Rate, CheckboxGroup, Checkbox } from 'rsuite';

// 3rd Party
import { Control, Controller, FieldValues } from 'react-hook-form';
import { ProgramQuestionInterface } from '../components/feedback/SessionFeedback';

export function answerField(
	question: ProgramQuestionInterface,
	index: number,
	control: Control<FieldValues> | undefined,
	feedback: { question_id: number; text: string | string[] | null; value: number | null } | null | undefined
) {
	return {
		text: (
			<Controller
				defaultValue={feedback ? feedback.text : ''}
				name={`feedback.${index}.text` as `${string}.${number}`}
				control={control}
				render={({ field }) => <Input {...field} as="textarea" rows={4} placeholder="Your Answer" />}
			/>
		),
		options: (
			<Controller
				name={`feedback.${index}.text` as `${string}.${number}`}
				control={control}
				defaultValue={feedback ? feedback.text : ''}
				render={({ field }) => (
					<RadioGroup {...field}>
						{question.options.map((option: string, index: number) => {
							return (
								<Radio key={index} value={option}>
									{option}
								</Radio>
							);
						})}
					</RadioGroup>
				)}
			/>
		),
		stars: (
			<Controller
				name={`feedback.${index}.value` as `${string}.${number}`}
				control={control}
				defaultValue={feedback ? feedback.value : null}
				render={({ field }) => (
					<Rate color="blue" className="text-center" size="lg" {...field} max={question.stars || 5} />
				)}
			/>
		),
		checkboxes: (
			<Controller
				name={`feedback.${index}.text` as `${string}.${number}`}
				control={control}
				defaultValue={feedback ? feedback.text : ''}
				render={({ field }) => (
					<CheckboxGroup {...field}>
						{question.options.map((option: string, index: number) => {
							return (
								<Checkbox key={index} value={option}>
									{option}
								</Checkbox>
							);
						})}
					</CheckboxGroup>
				)}
			/>
		),
	}[question.category as 'text' | 'options' | 'stars' | 'checkboxes'];
}

export function ShowAnswer(question: ProgramQuestionInterface, feedback: any) {
	return {
		text: <Input as="textarea" rows={4} defaultValue={feedback.text} plaintext />,
		options: (
			<RadioGroup defaultValue={feedback.text}>
				{question.options.map((option: string, index: number) => {
					return (
						<Radio key={index} value={option} checked readOnly>
							{option}
						</Radio>
					);
				})}
			</RadioGroup>
		),
		stars: (
			<Rate
				readOnly
				color="blue"
				className="text-center"
				size="lg"
				defaultValue={feedback.value}
				max={question.stars || 5}
			/>
		),
		checkboxes: (
			<CheckboxGroup defaultValue={[feedback.text]}>
				{question.options.map((option: string, index: number) => {
					return (
						<Checkbox key={index} value={option} defaultChecked readOnly>
							{option}
						</Checkbox>
					);
				})}
			</CheckboxGroup>
		),
	}[question.category as 'text' | 'options' | 'stars' | 'checkboxes'];
}

export function ShowSubmittedAnswer(question: any) {
	return {
		text: <Input as="textarea" rows={4} defaultValue={question.text} plaintext />,
		options: <Input as="textarea" rows={4} defaultValue={question.text} plaintext />,
		stars: (
			<Rate
				readOnly
				color="blue"
				className="text-center"
				size="lg"
				defaultValue={question.value}
				max={question.stars || 5}
			/>
		),
		checkboxes: <Input as="textarea" rows={4} defaultValue={question.text} plaintext />,
	}[question.category as 'text' | 'options' | 'stars' | 'checkboxes'];
}
