// React
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ServiceWorkerResigstration from './serviceWorkerRegistration';

// Future Lab
import { ReduxRoot } from './ReduxRoot';
// import * as serviceWorker from './serviceWorker';

const rootEl = document.getElementById('root');
ReactDOM.render(<ReduxRoot />, rootEl);

ServiceWorkerResigstration.register();
