// 3rd Party
import _ from 'lodash';

// FutureLab
import { PendingApprovalChatTypes, PendingChatMessagesActions } from '../model/index';
import createReducer from './createReducer';
import { ChatListDataInterface } from '../components/chat/ChatListItemsDesktop';

export const pendingApprovalChatList = createReducer<ChatListDataInterface[]>([], {
	[PendingApprovalChatTypes.LOAD_PENDING_APPROVAL_CHAT](
		state: ChatListDataInterface[],
		action: PendingChatMessagesActions
	) {
		state = action.payload;
		return state;
	},
	[PendingApprovalChatTypes.ADD_PENDING_APPROVAL_CHAT](
		state: ChatListDataInterface[],
		action: PendingChatMessagesActions
	) {
		const stateBackup = _.cloneDeep(state);
		stateBackup.push(action.payload[0]);
		state = stateBackup;
		return state;
	},
	[PendingApprovalChatTypes.REMOVE_PENDING_APPROVAL_CHAT](
		state: ChatListDataInterface[],
		action: PendingChatMessagesActions
	) {
		const stateBackup = _.cloneDeep(state);
		const stateBackupFiltered = stateBackup.filter((pinnedMessage) => pinnedMessage.id !== action.payload[0].id);
		state = stateBackupFiltered;
		return state;
	},
});
