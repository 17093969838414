// Axios
import { AxiosResponse } from 'axios';

// Future Lab
import { axiosInstance } from '../axiosInstance';
import { loginInput, SignUpInput } from '../model';

const authBaseUrl = `${process.env.REACT_APP_AUTH_API_BASE_URL}/api/`;

/**
 * Calls the login end point using the data from FE login screen and returns a promise
 *
 * @param  {loginInput} config
 * @returns Promise
 */
export function userLoginEndPoint(config: loginInput): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/sign-in`, config);
}

/**
 * The access token sent by BE to FE for validation is only valid for a specific time after which it will expire,
 * to refresh the FE cookie with a valid token needs to be done by the refreshAccessToken
 *
 * @param  {object} config
 * @returns Promise
 */
export function refreshAccessTokenEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/refresh-access-token`, config);
}

/**
 * Relay the message to the other micro services that the user has signed out
 *
 * @param  {object} config
 * @returns Promise
 */
export function signOutEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/sign-out`, config);
}

/**
 * Signs up the user on to the system
 *
 * @param  {SignUpInput} config
 * @returns Promise
 */
export function userSignUpEndPoint(config: SignUpInput): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/sign-up`, config);
}

/**
 * Resets the password
 *
 * @param  {object} config
 * @returns Promise
 */

export function resetPasswordEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/reset-password`, config);
}

/**
 * Updates the user password
 *
 * @param  {object} config
 * @returns Promise
 */
export function updatePasswordEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/update-password`, config);
}

/**
 * Resets the password for the user to send email that sends validator token (valid only for specific time)
 *
 * @param  {object} config
 * @returns Promise
 */
export function forgotPasswordEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/forgot-password`, config);
}

/**
 * Checks the DB whether the user exists in the system on not
 *
 * @param  {object} config
 * @returns Promise
 */
export function doesUserExistEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}auth/is-user-exists`, config);
}

/**
 * Checks the DB whether the user exists in the system on not
 *
 * @param  {object} config
 * @returns Promise
 */
export function userDetails(): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/me`, {});
}

/**
 * Send the details of the logged in user
 *
 * @param  {object} config
 * @returns Promise
 */
export function currentUserDetailsEndPoint(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}auth/current-user`, config);
}

/**
 * Send the details of the logged in user
 *
 * @param  {object} config
 * @returns Promise
 */
export function getUserListsDetails(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}user/user-details-list`, config);
}

/**
 * get program details
 *
 * @param  {object} config
 * @returns Promise
 */
export function getProgramDetails(programId: number | undefined): Promise<AxiosResponse<any>> {
	if (!programId) {
		return Promise.reject();
	}
	return axiosInstance.get(`${authBaseUrl}programs/${programId}`);
}

/**
 * Send the list of users for the
 *
 * @param  {object} config
 * @returns Promise
 */
export function getListOfUser(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}user/list`, config);
}

/**
 * Send the user list
 *
 * @param {object} config
 * @return Promise
 */
export function getUserInfo(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}user/user-details`, config);
}

/**
 * Send the user list
 *
 * @param {object} config
 * @return Promise
 */
export function getGroupChatDetails(config: object): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}user/group-chat-details`, config);
}

/**
 * Calls the login end point using the data from FE login screen and returns a promise
 * Check for existing token in the rails application
 *
 * @param  {object} config
 * @returns Promise
 */
export function getRailsCookies(): Promise<AxiosResponse<any>> {
	return axiosInstance.get(`${authBaseUrl}auth/transfer-auth`, { withCredentials: true });
}

/**
 * Logout and clear all the cookies
 * call auth api to expired the cookies
 *
 * @returns Promise
 */

export function logout(): Promise<AxiosResponse<any>> {
	return axiosInstance.post(`${authBaseUrl}auth/sign-out`, { withCredentials: true });
}
