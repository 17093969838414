// FutureLab
import { FeedbackSessionFormInFocusAction, FeedbackSessionFormInFocus } from '../model/index';
import createReducer from './createReducer';

export const feedbackFormInFocus = createReducer<number | null>(null, {
	[FeedbackSessionFormInFocus.FEEDBACK_SESSION_FORM_IN_FOCUS](
		state: number | null,
		action: FeedbackSessionFormInFocusAction
	) {
		state = action.payload;
		return state;
	},
});
