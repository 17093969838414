// React
import React, { useState, useEffect } from 'react';

// FutureLab
import { ChatListDataInterface } from './ChatListItemsDesktop';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { LoggedInUserDetails } from '../../reducers/index';
import { getPinnedMessagesQueryResults } from '../../utilities/Chat';
import { ChatListPinnedMessages } from './ChatListPinnedMessage';
import { PinnedMessageItem } from './ChatListMenu';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { store } from '../../ReduxRoot';

// UI
import { FlexboxGrid } from 'rsuite';

interface ChatListPinnedMessagesLoopProps {
	userListProfilesDetails: UserProfileDetails[];
	filterQuery: string;
	pinnedMessages: PinnedMessageItem[];
	setPinnedMessages: any;
	chatListsItems: ChatListDataInterface[];
	loggedInUserDetail: LoggedInUserDetails;
}

export function ChatListPinnedMessageLoop(ChatListPinnedMessagesLoopProps: ChatListPinnedMessagesLoopProps) {
	const {
		userListProfilesDetails,
		filterQuery,
		pinnedMessages,
		setPinnedMessages,
		chatListsItems,
		loggedInUserDetail,
	} = ChatListPinnedMessagesLoopProps;
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div>
			{store.getState().pinnedMessages.length > 0 ? (
				<>
					{getPinnedMessagesQueryResults(pinnedMessages, userListProfilesDetails, filterQuery).length > 0 ? (
						<div
							className={
								getWindowSize(windowSize.width) === windowSizeType.XS
									? 'pinned-messages-list-height no-scrollbar overflow-y-auto'
									: 'pinned-messages-list-height no-scrollbar overflow-y-auto'
							}
						>
							{getPinnedMessagesQueryResults(pinnedMessages, userListProfilesDetails, filterQuery).map(
								(pinnedMessage: PinnedMessageItem, index: number) => {
									return (
										<ChatListPinnedMessages
											key={index}
											pinnedMessage={pinnedMessage}
											userListProfilesDetails={userListProfilesDetails}
											chatListsItems={chatListsItems}
											setPinnedMessages={setPinnedMessages}
											loggedInUserDetail={loggedInUserDetail}
										/>
									);
								}
							)}
						</div>
					) : (
						<FlexboxGrid className="px-5 pt-10">
							<FlexboxGrid.Item colspan={24}>
								<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
									No result found.
								</FlexboxGrid.Item>
								<FlexboxGrid.Item className="text-base text-center text-gray-600">
									Please refine your key words and try again
								</FlexboxGrid.Item>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					)}
				</>
			) : (
				<FlexboxGrid className="px-5 pt-10">
					<FlexboxGrid.Item colspan={24}>
						<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
							Pinned message you want
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base text-center text-gray-600">
							You can see all of your pinned message here from all active chatrooms you have!
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</div>
	);
}
