// FutureLab
import { renewAttachment } from '../services/chatService';

// 3rd Party
import axios from 'axios';


const createDownloadLink = (url: string, fileName: string) => {
	const linkElement = document.createElement('a');
	linkElement.href = url;
	linkElement.setAttribute('download', fileName);
	return linkElement;
};

const fetchAttachment = async (url: string) => {
	try {
    const response = await axios.get(url, { responseType: 'blob' });
    return new Blob([response.data]);
  } catch (error) {
    throw new Error(`Failed to fetch Attachment`);
  }
};

export const downloadAttachment = async (attachment: { key: string; fileName: string }) => {
	const downloadLink = createDownloadLink('', attachment.fileName);

	try {
		const attachmentData = await fetchAttachment(attachment.key);
		const attachmentUrl = window.URL.createObjectURL(attachmentData);
		downloadLink.href = attachmentUrl;

		document.body.appendChild(downloadLink);
		downloadLink.click();
	} catch (error) {
		// Handle the error or log it.
		console.error(error);

		// If there's an error, renew the attachment.
		const renewedAttachmentLink = await renewAttachment(attachment);
		const renewedAttachmentData = await fetchAttachment(renewedAttachmentLink.data.data.key);

		const renewedAttachmentUrl = window.URL.createObjectURL(renewedAttachmentData);
		downloadLink.href = renewedAttachmentUrl;

		document.body.appendChild(downloadLink);
		downloadLink.click();
	} finally {
		// Always remove the link from the body.
		document.body.removeChild(downloadLink);
	}
};
