// React
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Futurelab
import { windowSizeType, getWindowSize } from '../../utilities/WindowResize';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

// UI
import { Col, Divider, FlexboxGrid } from 'rsuite';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import PageNextIcon from '@rsuite/icons/PageNext';
import EventDetailIcon from '@rsuite/icons/EventDetail';
import CalendarIcon from '@rsuite/icons/Calendar';

export default function FeedbackMenu(props: any) {
	const { activeItem, onItemClick } = props;
	const currentFormInFocus = useSelector((state: RootState) => state.feedbackFormInFocus);
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const navigate = useNavigate();
	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{getWindowSize(windowSize.width) === windowSizeType.XS ? (
				<FlexboxGrid justify="center">
					<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 border-b border-gray-200">
						<FlexboxGrid.Item colspan={5} as={Col} onClick={() => navigate('..')} className="text-center">
							<ArowBackIcon className="w-6 h-6" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={19} as={Col} className="session-feedback">
							Submit Session Feedback
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pt-6" onClick={() => navigate('../new-session')}>
						<FlexboxGrid.Item className="text-center" colspan={5} as={Col}>
							<EventDetailIcon className="w-6 h-7" color="#428ac9" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={15} as={Col} className="new-session">
							Submit New Session
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-center" colspan={4} as={Col}>
							<PageNextIcon className="w-6 h-7" color="#C4C4C4" />
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={20}>
						<Divider />
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pt-1" onClick={() => navigate('../previous-session')}>
						<FlexboxGrid.Item className="text-center" colspan={5} as={Col}>
							<CalendarIcon className="w-6 h-7" color="#428ac9" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={15} as={Col} className="previous-session">
							Previous Sessions
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-center" colspan={4} as={Col}>
							<PageNextIcon className="w-6 h-7" color="#C4C4C4" />
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			) : (
				<FlexboxGrid justify="center">
					<FlexboxGrid.Item
						colspan={24}
						className={
							activeItem === 'newSession' && currentFormInFocus === 1
								? 'pb-6 pt-5 bg-blue-50 border border-blue-400 rounded-lg'
								: 'pb-6 pt-5'
						}
						onClick={() => onItemClick('newSession')}
					>
						<FlexboxGrid.Item className="text-center" colspan={5} as={Col}>
							<EventDetailIcon className="w-6 h-7" color="#428ac9" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={15} as={Col} className="new-session">
							Submit New Session
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-center" colspan={4} as={Col}>
							<PageNextIcon className="w-6 h-7" color="#C4C4C4" />
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={20}>
						<Divider className="session-divider" />
					</FlexboxGrid.Item>
					<FlexboxGrid.Item
						colspan={24}
						className={
							activeItem === 'previousSession'
								? 'py-6 bg-blue-50 border border-blue-400 rounded-lg'
								: 'py-6'
						}
						onClick={() => onItemClick('previousSession')}
					>
						<FlexboxGrid.Item className="text-center" colspan={5} as={Col}>
							<CalendarIcon className="w-6 h-7" color="#428ac9" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={15} as={Col} className="previous-session">
							Previous Sessions
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-center" colspan={4} as={Col}>
							<PageNextIcon className="w-6 h-7" color="#C4C4C4" />
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</>
	);
}
