// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';
import {
	ProgramQuestionInterface,
	SessionDetailFormInterface,
	SessionFeedbackAnswerInterface,
	SessionFeedbackFormInterface,
} from '../components/feedback/SessionFeedback';

/* 
 Action Types Interfaces
*/

// General Action Interface
interface ActionType<T, P> {
	type: T;
	payload: P;
}

// Logged In User
export enum LoggedInUserActions {
	RESGISTER_USER = 'RESGISTER_USER',
	LOGIN_USER = 'LOGIN_USER',
	LOGOUT_USER = 'LOGOUT_USER',
}

export type UserAction =
	| ActionType<typeof LoggedInUserActions.LOGIN_USER, boolean>
	| ActionType<typeof LoggedInUserActions.LOGOUT_USER, boolean>
	| ActionType<typeof LoggedInUserActions.RESGISTER_USER, boolean>;

// Logged In User Details
export enum LoggedInUserDetails {
	LOGGED_IN_USER_DETAILS = 'LOGGED_IN_USER_DETAILS',
}
export type LoggedInUserDetailsActions = ActionType<typeof LoggedInUserDetails.LOGGED_IN_USER_DETAILS, object>;

// Chat Item in Focus
export enum ChatItemInFocus {
	CHAT_ITEM_IN_FOCUS = 'CHAT_ITEM_IN_FOCUS',
}
export type ChatItemInFocusActions = ActionType<
	typeof ChatItemInFocus.CHAT_ITEM_IN_FOCUS,
	ChatListDataInterface | null
>;

// Chat List
export enum ChatList {
	CHAT_LIST = 'CHAT_LIST',
}
export type ChatListActions = ActionType<typeof ChatList.CHAT_LIST, ChatListDataInterface[] | []>;

// Pinned Message in Focus
export enum PinnedMessageInFocusTypes {
	PINNED_MESSAGE_IN_FOCUS = 'PINNED_MESSAGE_IN_FOCUS',
}
export type PinnedMessageInFocusActions = ActionType<
	typeof PinnedMessageInFocusTypes.PINNED_MESSAGE_IN_FOCUS,
	PinnedMessageItem | null
>;

// User List Profiles
export enum LoadUserProfilesDetailsTypes {
	LOAD_USERS_PROFILES_DETAILS = 'LOAD_USERS_PROFILES_DETAILS',
}

export type LoadUserProfilesDetailsActions = ActionType<
	typeof LoadUserProfilesDetailsTypes.LOAD_USERS_PROFILES_DETAILS,
	[]
>;

// Pending approval chat messages
export enum PendingApprovalChatTypes {
	LOAD_PENDING_APPROVAL_CHAT = 'LOAD_PENDING_APPROVAL_CHAT',
	ADD_PENDING_APPROVAL_CHAT = 'ADD_PENDING_APPROVAL_CHAT',
	REMOVE_PENDING_APPROVAL_CHAT = 'REMOVE_PENDING_APPROVAL_CHAT',
}

export type PendingChatMessagesActions =
	| ActionType<typeof PendingApprovalChatTypes.LOAD_PENDING_APPROVAL_CHAT, ChatListDataInterface[]>
	| ActionType<typeof PendingApprovalChatTypes.ADD_PENDING_APPROVAL_CHAT, ChatListDataInterface[]>
	| ActionType<typeof PendingApprovalChatTypes.REMOVE_PENDING_APPROVAL_CHAT, ChatListDataInterface[]>;

// Pinned Messages
export enum PinnedMessagesTypes {
	LOAD_PIN_MESSAGES = 'LOAD_PIN_MESSAGES',
	ADD_PIN_MESSAGES = 'ADD_PIN_MESSAGES',
	REMOVE_PIN_MESSAGES = 'REMOVE_PIN_MESSAGES',
	REMOVE_ALL_CHAT_ROOM_PINNED_MESSAGES = 'REMOVE_ALL_CHAT_ROOM_PINNED_MESSAGES',
}

export type PinnedMessagesActions =
	| ActionType<typeof PinnedMessagesTypes.LOAD_PIN_MESSAGES, PinnedMessageItem[]>
	| ActionType<typeof PinnedMessagesTypes.ADD_PIN_MESSAGES, PinnedMessageItem[]>
	| ActionType<typeof PinnedMessagesTypes.REMOVE_PIN_MESSAGES, PinnedMessageItem[]>
	| ActionType<typeof PinnedMessagesTypes.REMOVE_ALL_CHAT_ROOM_PINNED_MESSAGES, PinnedMessageItem[]>;

// Feedback Session Form in Focus
export enum FeedbackSessionFormInFocus {
	FEEDBACK_SESSION_FORM_IN_FOCUS = 'FEEDBACK_SESSION_FORM_IN_FOCUS',
}

export type FeedbackSessionFormInFocusAction = ActionType<
	typeof FeedbackSessionFormInFocus.FEEDBACK_SESSION_FORM_IN_FOCUS,
	number | null
>;

// Feedback Session Question
export enum FeedbackSessionQuestion {
	FEEDBACK_SESSION_QUESTION = 'FEEDBACK_SESSION_QUESTION',
}

export type FeedbackSessionQuestionAction = ActionType<
	typeof FeedbackSessionQuestion.FEEDBACK_SESSION_QUESTION,
	ProgramQuestionInterface[]
>;

// Feedback Session Question
export enum FeedbackSessionAnswer {
	UPDATE_SESSION_DETAIL_FORM = 'UPDATE_SESSION_DETAIL_FORM',
	UPDATE_SESSION_FEEDBACK_FORM = 'UPDATE_SESSION_FEEDBACK_FORM',
	RESET_SESSION_FEEDBACK_FORM = 'RESET_SESSION_FEEDBACK_FORM',
}

export type FeedbackSessionAnswerAction =
	| ActionType<typeof FeedbackSessionAnswer.RESET_SESSION_FEEDBACK_FORM, SessionFeedbackAnswerInterface>
	| ActionType<typeof FeedbackSessionAnswer.UPDATE_SESSION_DETAIL_FORM, SessionDetailFormInterface>
	| ActionType<typeof FeedbackSessionAnswer.UPDATE_SESSION_FEEDBACK_FORM, SessionFeedbackFormInterface>;

// Loader related
export enum LoaderActionTypes {
	SHOW_LOADER = 'SHOW_LOADER',
	HIDE_LOADER = 'HIDE_LOADER',
}

export type LoaderActions =
	| ActionType<typeof LoaderActionTypes.SHOW_LOADER, boolean>
	| ActionType<typeof LoaderActionTypes.HIDE_LOADER, boolean>;

export enum PushNotificationTypes {
	REGISTER_NOTIFICATION = 'REGISTER_NOTIFICATION',
	UNREGISTER_NOTIFICATION = 'UNREGISTER_NOTIFICATION',
}

export type PushNotificationActions =
	| ActionType<typeof PushNotificationTypes.REGISTER_NOTIFICATION, PushNotificationData>
	| ActionType<typeof PushNotificationTypes.UNREGISTER_NOTIFICATION, PushNotificationData>;
/* 
  General Interfaces
*/

export interface PushNotificationData {
	fcmToken: string;
	userId: number | null;
	isRegistered: boolean;
}

export interface loginInput {
	password?: string;
	email?: string;
	recaptcha_token?: string | null;
}

export interface SignUpInput {
	email?: string;
	password?: string;
	recaptcha_token?: string | null;
	become_a_mentor?: boolean;
	verifyPassword?: string;
}

export interface ProgramDetailsInterface {
	approved: boolean;
	avatarColour: string;
	banner: string;
	chatSettings: number;
	createdAt: string;
	dashboardAlert: boolean;
	deactivated: boolean;
	description: string;
	endDate: string;
	enterpriseId: number;
	id: number;
	name: string;
	pairingMethod: number;
	photo: string;
	privacySettings: number;
	programType: number;
	removedAt: string;
	reviewSettings: number;
	slug: string;
	startDate: Date;
	supportAdminId: number;
	supportEmail: string;
	target: number;
	targetAudience: string;
	updatedAt: string;
	whatsappNotification: boolean;
	programEnterprises: any;
}
