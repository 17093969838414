// FutureLab
import createReducer from './createReducer';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';
import { PinnedMessagesTypes, PinnedMessagesActions } from '../model/index';

// 3rd Party
import _ from 'lodash';

export const pinnedMessages = createReducer<PinnedMessageItem[]>([], {
	[PinnedMessagesTypes.LOAD_PIN_MESSAGES](state: PinnedMessageItem[], action: PinnedMessagesActions) {
		state = action.payload;
		return state;
	},
	[PinnedMessagesTypes.ADD_PIN_MESSAGES](state: PinnedMessageItem[], action: PinnedMessagesActions) {
		const stateBackup = _.cloneDeep(state);
		stateBackup.push(action.payload[0]);
		state = stateBackup;
		return state;
	},
	[PinnedMessagesTypes.REMOVE_PIN_MESSAGES](state: PinnedMessageItem[], action: PinnedMessagesActions) {
		const stateBackup = _.cloneDeep(state);
		const stateBackupFiltered = stateBackup.filter((pinnedMessage) => pinnedMessage.id !== action.payload[0].id);
		state = stateBackupFiltered;
		return state;
	},
	[PinnedMessagesTypes.REMOVE_ALL_CHAT_ROOM_PINNED_MESSAGES](
		state: PinnedMessageItem[],
		action: PinnedMessagesActions
	) {
		const stateBackup = _.cloneDeep(state);
		const stateBackupFiltered = stateBackup.filter(
			(pinnedMessage) => !action?.payload.some(({ id }) => pinnedMessage.id === id)
		);
		state = stateBackupFiltered;
		return state;
	},
});
