// React
import React from 'react';

// UI
import { FlexboxGrid } from 'rsuite';

// Futurelab
import { ChatListChatRequestChatRoom } from './ChatListChatRequestChatRoom';
import { ChatListChatRequestOption } from './ChatListChatRequestOption';

export function ChatListChatRequestRoom() {
	return (
		<FlexboxGrid>
			<FlexboxGrid.Item colspan={24}>
				<ChatListChatRequestChatRoom />
			</FlexboxGrid.Item>
			<FlexboxGrid.Item colspan={24}>
				<ChatListChatRequestOption />
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
