// React
import React from 'react';

// UI
import { Col, FlexboxGrid } from 'rsuite';

// FutureLab
import { ProgramQuestionInterface, SessionFeedbackAnswerInterface } from './SessionFeedback';
import { ShowSubmittedFeedback, showQuestion } from './form/SessionQuestion';

// 3rd Party
import moment from 'moment';

interface ReviewSessionDetailsProp {
	value: string;
	programQuestion: ProgramQuestionInterface[];
	programAnswer: SessionFeedbackAnswerInterface;
}

export function ReviewSessionDetails(reviewSessionDetailsProp: ReviewSessionDetailsProp) {
	const { value, programQuestion, programAnswer } = reviewSessionDetailsProp;
	const { programme, sessionWith, sessionType, sessionDate, sessionTime, sessionDuration, sessionService } =
		programAnswer.sessionDetailForm;

	return (
		<>
			{value === '1' ? (
				<FlexboxGrid>
					{/* TODO */}
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
							Select programme
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base font-normal text-gray-900">
							{programme?.label}
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
							Who is the mentor / mentee?
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base font-normal text-gray-900">
							{sessionWith?.label}
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
							Where did the session take place?
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base font-normal text-gray-900">
							{sessionType === 0 ? 'Online' : 'Physical'}
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
							When the session happen?
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base font-normal text-gray-900">
							{sessionDate ? moment(sessionDate).format('DD/MM/YYYY') : '-'}
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item as={Col} colspan={12} className="px-0">
							<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
								Session time
							</FlexboxGrid.Item>
							<FlexboxGrid.Item className="text-base font-normal text-gray-900">
								{sessionTime ? moment(sessionTime).format('h:mm a') : '-'}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item as={Col} colspan={12} className="px-0">
							<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
								Session duration
							</FlexboxGrid.Item>
							<FlexboxGrid.Item className="text-base font-normal text-gray-900">
								{sessionDuration?.label}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="pb-6">
						<FlexboxGrid.Item className="text-base font-medium text-gray-900 pb-1">
							Mentor services
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base font-normal text-gray-900">
							{sessionService?.label}
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			) : (
				<>
					{programAnswer.sessionFeedbackForm.submitted
						? ShowSubmittedFeedback(programAnswer)
						: showQuestion(programQuestion, programAnswer)}
				</>
			)}
		</>
	);
}
