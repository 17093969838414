// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { UserProfileDetails } from '../pages/AddChatPage';
import { LoggedInUserDetails } from '../reducers/index';

export function returnOneToOneChatUserDetails(
	chatListItem: ChatListDataInterface,
	loggedInUserDetails: LoggedInUserDetails,
	userListProfilesDetails: UserProfileDetails[]
): UserProfileDetails | undefined {
	let oneToOnechatRoomUser = chatListItem?.participants?.find(
		(participant) => participant?.userId !== loggedInUserDetails?.id
	);
	if (oneToOnechatRoomUser) {
	} else {
		oneToOnechatRoomUser = chatListItem?.participants?.find(
			(participant) => participant?.userId === loggedInUserDetails?.id
		);
	}
	const oneToOnechatRoomUserDetails = userListProfilesDetails?.find(
		(participant) => participant?.id === oneToOnechatRoomUser?.userId
	);

	return oneToOnechatRoomUserDetails;
}
