// React
import React from 'react';

// UI
import { Form } from 'rsuite';

interface InputFieldProps {
	label?: string;
	name: string;
	ref?: any;
	placeholder?: string;
	restProps?: any;
	setControlValue?: string;
	onClickMethod?: () => void;
}

function InputField(props: InputFieldProps) {
	const { label, name, ref, placeholder, setControlValue, onClickMethod, ...restProps } = props;

	return (
		<>
			<Form.ControlLabel>{label}</Form.ControlLabel>
			<Form.Control
				style={{ height: 44 }}
				autoComplete="off"
				name={name}
				placeholder={placeholder}
				ref={ref}
				value={setControlValue}
				{...restProps}
				onClick={onClickMethod}
			/>
			<Form.ErrorMessage />
		</>
	);
}

export default InputField;
