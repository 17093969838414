export const windowSizeType = {
	XS: 'XS',
	S: 'S',
	MD: 'MD',
	LG: 'LG',
	XL: 'XL',
};

export function getWindowSize(size: number): string {
	if (size < 480) {
		return windowSizeType.XS;
	}
	if (size >= 600 && size < 960) {
		return windowSizeType.S;
	}
	if (size >= 960 && size < 1280) {
		return windowSizeType.MD;
	}
	if (size >= 1280 && size < 1920) {
		return windowSizeType.LG;
	}
	return windowSizeType.XL;
}
