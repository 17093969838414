// React
import { useEffect, useState } from 'react';

// FutureLab
import { windowSizeType, getWindowSize } from '../utilities/WindowResize';
import { DesktopFeedback } from '../components/feedback/DesktopFeedback';
import FeedbackMenu from '../components/feedback/FeedbackMenu';

export function FeedbackSessionPage() {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className="w-full">
			{getWindowSize(windowSize.width) === windowSizeType.XS ? <FeedbackMenu /> : <DesktopFeedback />}
		</div>
	);
}
