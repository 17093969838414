// 3rd Party
import _ from 'lodash';

// FutureLab
import { LoadUserProfilesDetailsTypes, LoadUserProfilesDetailsActions } from '../model/index';
import createReducer from './createReducer';

export const userListProfilesDetails = createReducer<any[]>([], {
	[LoadUserProfilesDetailsTypes.LOAD_USERS_PROFILES_DETAILS](state: any[], action: LoadUserProfilesDetailsActions) {
		const stateBackup = _.cloneDeep(state);
		const stateBackupMarged = _.merge(_.keyBy(stateBackup, 'id'), _.keyBy(action.payload, 'id'));
		state = _.values(stateBackupMarged);
		return state;
	},
});
