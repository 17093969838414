// React
import React from 'react';

//UI
import { ButtonToolbar, Button, Modal, FlexboxGrid } from 'rsuite';

interface ModalConfirmationInterface {
	displayModalConfirmation: any;
	setDisplayModalConfirmation: any;
	optionStore: any;
	callBackProceedMethodProp?: (params?: any) => void;
}

export function ModalConfirmation(modalConfirmationProps: ModalConfirmationInterface) {
	const { displayModalConfirmation, setDisplayModalConfirmation, optionStore, callBackProceedMethodProp } =
		modalConfirmationProps;

  const originUrl = localStorage.getItem('originURL');

	const handleClose = () => setDisplayModalConfirmation(false);

	const confirmationDetails = [
		{
			action: 'deleteChat',
			title: 'Delete',
			description: 'Are you sure you want to delete your chat room with this user?',
		},
		{
			action: 'deleteGroupChat',
			title: 'Delete Group Chat',
			description: 'Are you sure you want to delete your group chatroom?',
		},
		{
			action: 'deleteMessage',
			title: 'Delete Message',
			description: 'Are you sure you want to delete this message?',
		},
		{
			action: 'leaveGroup',
			title: 'Leave Group',
			description: 'Are you sure you want to leave this group?',
		},
		{
			action: 'reportGroup',
			title: 'Confirmation',
			description: 'Are you sure you want to submit this report?',
		},
		{
			action: 'rejectInvitation',
			title: 'Reject Invitation',
			description: 'Are you sure you want to reject a chat invitation from this <group/user>?',
		},

		{
			action: 'leavingFL',
			title: 'Leaving Chat App',
			description: `You will be redirected back to ${originUrl ? 'Programme overview' : 'FutureLab dashboard'}. Do you want to proceed?`,
		},
		{
			action: 'deleteAllChat',
			title: 'Delete all chat',
			description: 'Are you sure you want to delete all messages?',
		},
		{
			action: 'reportUser',
			title: 'Report this User',
			description: 'Are you sure you want to submit this report?',
		},
		{
			action: 'leavingPage',
			title: 'Leaving Page',
			description:
				'You are about to leave this page. Any changes you made may not be saved. Are you sure you want to proceed?',
		},
		{
			action: 'submitFeedbackConfirmation',
			title: 'Confirmation',
			description: 'Are you ready to submit your session and feedback?',
		},
	];

	const checkConfirmationDetails = confirmationDetails.find(
		(confirmationDetail) => confirmationDetail.action === optionStore.action
	);

	const callBackProceedMethod = () => {
		if (callBackProceedMethodProp) {
			callBackProceedMethodProp();
		}
	};

	return (
		<Modal backdrop={true} size="xs" open={displayModalConfirmation} onClose={handleClose}>
			<FlexboxGrid.Item className="modal-confirmation-container">
				<FlexboxGrid.Item className="modal-confirmation-title">
					{checkConfirmationDetails?.title}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item className="modal-confirmation-description">
					{checkConfirmationDetails?.description}
				</FlexboxGrid.Item>
			</FlexboxGrid.Item>
			<ButtonToolbar className="modal-confirmation-button-group">
				<Button className="modal-confirmation-reject" appearance="ghost" onClick={handleClose}>
					No
				</Button>
				<Button className="modal-confirmation-accept" appearance="primary" onClick={callBackProceedMethod}>
					Yes
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
