// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { ChatListActions, ChatList } from '../model/index';
import createReducer from './createReducer';

export const chatList = createReducer<ChatListDataInterface | []>([], {
	[ChatList.CHAT_LIST](state: ChatListDataInterface[] | [], action: ChatListActions) {
		state = action.payload;
		return state;
	},
});
