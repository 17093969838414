// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Button, ButtonToolbar, Form, Schema } from 'rsuite';

// Future Lab
import InputField from '../common/InputField';
import { userSignUpEndPoint, userDetails } from '../../services/userService';
import InputPasswordComponent from './InputPassword';
import { loggedInUserDetails } from '../../actions/loggedInUserDetailsActions';
import { SignUpInput } from '../../model';

//Recaptcha
import RecaptchaComponent from '../../components/google/Recaptcha';

// Reducer
import { store } from '../../ReduxRoot';

// 3rd Party
import Cookies from 'js-cookie';

export default function SignupFormComponent() {
	const [formError, setFormError] = useState({});
	const [checkTrigger, setCheckTrigger] = useState<string>('none');
	const [formValues, setFormValues] = useState<SignUpInput>({
		email: '',
		password: '',
		verifyPassword: '',
	});
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
	const pwaCookie = process.env.PWA_COOKIE_NAME || 'pwa_staging_session';
	const navigate = useNavigate();

	const handleSubmit = () => {
		setCheckTrigger('change');
		userSignUpEndPoint(formValues)
			.then((res) => {
				Cookies.set('token', res?.data?.data?.accessToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				Cookies.set(pwaCookie, res?.data?.data?.railsToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				Cookies.set('refreshToken', res?.data?.data?.refreshToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				navigate('/');
			})
			.then(() => {
				userDetails().then((res) => {
					store.dispatch(loggedInUserDetails(res?.data?.data));
				});
			})
			.catch((err) => {
				console.warn('err:', err);
				// this is where you need to put the error some either the
				// server down or user name password combination is incorrect
			});
	};

	const formValue = (data: SignUpInput) => {
		setFormValues(data);
	};

	// const onDisable = (recaptcha_token: SignUpInput) => {
	// 	if (recaptcha_token == '') {
	// 		setDisableSubmit(true);
	// 	} else {
	// 		//upon verification expired, submit button will revert to disabled state
	// 		setDisableSubmit(false);
	// 	}
	// };

	const { StringType } = Schema.Types;
	const validationModel = Schema.Model({
		firstname: StringType().isRequired('This field is required.'),
		lastname: StringType().isRequired('This field is required.'),
		email: StringType().isRequired('This field is required.').isEmail('Please enter a valid email address.'),
		password: StringType()
			.isRequired('This field is required.')
			.minLength(6, 'Your password needs to be at least 6 characters.')
			.containsUppercaseLetter('Your password requires at least 1 uppercase letter.')
			.containsLowercaseLetter('Your password requires at least 1 lowercase letter.')
			.pattern(/.*[*.!@#$%^&(){}\]:;<>,.?\/~_+\-=|].*$/, 'Your password requires at least 1 special character.'),
		verifyPassword: StringType()
			.addRule((value) => {
				if (value !== formValues?.password) {
					return false;
				}
				return true;
			}, 'The two passwords does not match')
			.isRequired('This field is required.'),
	});

	return (
		<div>
			<Form
				fluid
				model={validationModel}
				onSubmit={handleSubmit}
				onChange={formValue}
				onCheck={setFormError}
				formError={formError}
				checkTrigger={checkTrigger as any}
			>
				<Form.Group>
					<InputField name={'firstName'} placeholder={'First Name'} label={'First Name'} />
					<InputField name={'lastName'} placeholder={'Last Name'} label={'Last Name'} />
					<InputField name={'email'} placeholder={'email@example.com'} label={'Email'} />
					<InputPasswordComponent name={'password'} placeholder={'Password'} label={'Password'} showTooltip />
					<InputPasswordComponent
						name={'verifyPassword'}
						placeholder={'Verify password'}
						label={'Verify password'}
					/>
					{/* <RecaptchaComponent onDisable={onDisable} /> */}
					<ButtonToolbar>
						<Button type="submit" appearance="primary" block disabled={disableSubmit}>
							Sign up
						</Button>
					</ButtonToolbar>
				</Form.Group>
			</Form>
		</div>
	);
}
