// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Col, FlexboxGrid } from 'rsuite';
import { Done } from '@material-ui/icons';
import { useSelector } from 'react-redux';

// Futurelab
import { RootState } from '../../reducers';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';

// 3rd Party
import moment from 'moment';

export function ChatListChatRequestChatRoom() {
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const navigate = useNavigate();
	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		if (!chatInFocus) {
			navigate('chat/chat-room');
		}
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const getChatAdmin = (): UserProfileDetails | undefined => {
		return userListProfilesDetails.find((userProfileDetails) => {
			if (!chatInFocus?.chatAdmins || !chatInFocus?.chatAdmins[0]) {
				return null;
			}
			return userProfileDetails.id === chatInFocus?.chatAdmins[0].userId;
		});
	};

	const getLoggedInUserDetails = (): UserProfileDetails | undefined => {
		return userListProfilesDetails.find((userProfileDetails) => {
			return userProfileDetails.id === loggedInUserDetails.id;
		});
	};

	return (
		<FlexboxGrid>
			<FlexboxGrid.Item
				colspan={24}
				className={
					getWindowSize(windowSize.width) === windowSizeType.XS
						? 'chat-request-height no-scrollbar overflow-y-auto bg-gray-50'
						: 'chat-request-height no-scrollbar overflow-y-auto bg-gray-50'
				}
			>
				<FlexboxGrid.Item colspan={24} className="text-center my-5 text-sm text-gray-500">
					{moment(chatInFocus?.createdAt).format('dddd, MMMM Do YYYY')}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item
					as={Col}
					colspan={24}
					xs={14}
					md={14}
					lg={14}
					className="pt-4 pb-1 text-sm text-gray-600"
				>
					<span className="truncate pl-3 capitalize underline font-medium text-sm text-gray-900">
						{getChatAdmin()?.firstname} {getChatAdmin()?.lastname ?? ''}
					</span>{' '}
					(Admin)
				</FlexboxGrid.Item>
				<FlexboxGrid.Item
					as={Col}
					colspan={24}
					xs={14}
					md={14}
					lg={14}
					className="bg-white shadow-sm border-2 border-solid text-gray-900 text-sm rounded-lg px-2.5 py-3 ml-3"
				>
					Hello {getLoggedInUserDetails()?.firstname}. You've been invite to this chatroom. Have a good day!
				</FlexboxGrid.Item>
				<FlexboxGrid.Item as={Col} colspan={20} className="text-xs text-gray-500 ml-3 mb-2.5 mt-1.5">
					{moment(chatInFocus?.createdAt).format('h:mma')}
					<Done className="read-receipt-font-size" />
				</FlexboxGrid.Item>
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
