// React
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// UI
import { Navbar, Nav, Divider, FlexboxGrid } from 'rsuite';
import Popover from '@mui/material/Popover';
import { MdChatBubbleOutline, MdOutlineLogout, MdOutlineAccountCircle } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';

// CSS
import '../../../assets/nav/Navbar.css';

// Futurelab
import CompanyLogo from '../../common/CompanyLogo';
import { windowSizeType, getWindowSize } from '../../../utilities/WindowResize';
import DesktopNavItem from './DesktopNavItem';
import { WindowResizeInterface } from '../../../AppChild';
import { RootState } from '../../../reducers';
import { ProfilePreviewData } from '../../popup/userDetails';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import { getUserInfo } from '../../../services/userService';

// 3rd
import _ from 'lodash';

interface DesktopNavbarProps {
	logout: () => void;
	token: string;
	windowSize: WindowResizeInterface;
}

export default function DesktopNavbar(props: DesktopNavbarProps) {
	const { logout, token, windowSize } = props;
	const [loggedInUserProfileDetails, setLoggedInUserProfileDetails] = useState<ProfilePreviewData>();
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [displayModalConfirmation, setDisplayModalConfirmation] = useState<boolean>(false);
	const open = Boolean(anchorEl);
	const loggedInUserFullName = `${loggedInUserProfileDetails?.firstname} ${loggedInUserProfileDetails?.lastname ?? ''}`;
	const optionStore = { action: 'leavingFL' };

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const loggedInUser = loggedInUserDetails;
		if (!_.isEmpty(loggedInUser) && token.length > 0) {
			getUserInfo({ userId: loggedInUserDetails.id }).then((res) => {
				setLoggedInUserProfileDetails(res?.data?.data);
			});
		}
	}, [loggedInUserDetails]);

	const navigateToOrigin = () => {
		const originUrl = localStorage.getItem('originURL');
		if (originUrl) {
			window.open(`${process.env.REACT_APP_PORTAL_BASE_URL}${originUrl}/overviews`, '_self');
		} else {
			window.open(`${process.env.REACT_APP_B2C_BASE_URL}main/dashboard`, '_self');
		}

		setDisplayModalConfirmation(!displayModalConfirmation);
    localStorage.removeItem('originURL');
	};

	return (
		<Navbar className="desktop-navbar-container">
			<Navbar.Brand className="py-3 flex h-full cursor-pointer" onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}>
				<CompanyLogo />
			</Navbar.Brand>
			{getWindowSize(windowSize.width) !== windowSizeType.XS && token.length > 0 && (
				<Nav justified pullRight>
					<Divider vertical className="my-1.5 h-12" />
					<span onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}>
						<DesktopNavItem icon={<AiOutlineHome color="#fafafa" size={24} />} />
					</span>
					{/* <DesktopNavItem icon={<MdChatBubbleOutline color="#fafafa" size={24} />} routerPath="chat" /> */}
					<span onClick={handleClick}>
						<DesktopNavItem icon={<MdOutlineAccountCircle color="#fafafa" size={24} />} />
					</span>

					<DesktopNavItem icon={<MdOutlineLogout color="#fafafa" size={24} />} method={logout} />
					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<FlexboxGrid.Item className="user-name-desktop">{loggedInUserFullName}</FlexboxGrid.Item>
						<FlexboxGrid.Item className="user-email">{loggedInUserDetails.email}</FlexboxGrid.Item>
						{/* <FlexboxGrid.Item className="account-type">Account type</FlexboxGrid.Item>
						<FlexboxGrid.Item className="user-type">
							{loggedInUserProfileDetails?.user_type === 1 ? 'Mentor' : 'Mentee'}
						</FlexboxGrid.Item> */}
					</Popover>
				</Nav>
			)}
			{displayModalConfirmation && (
				<ModalConfirmation
					displayModalConfirmation={displayModalConfirmation}
					setDisplayModalConfirmation={setDisplayModalConfirmation}
					optionStore={optionStore}
					callBackProceedMethodProp={navigateToOrigin}
				/>
			)}
		</Navbar>
	);
}
