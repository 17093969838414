// React
import { ReactChild, ReactFragment, ReactPortal } from 'react';

// FutureLab
import { ShowAnswer, answerField, ShowSubmittedAnswer } from '../../../utilities/AnswerField';
import { ProgramQuestionInterface, SessionFeedbackAnswerInterface } from '../SessionFeedback';

// UI
import { FlexboxGrid, Form } from 'rsuite';

// 3rd Party
import { Control, Controller, FieldValues } from 'react-hook-form';
import _ from 'lodash';

export function questionField(
	programQuestion: ProgramQuestionInterface[],
	control: Control<FieldValues> | undefined,
	errors: {
		feedback: {
			value: { message: boolean | null | undefined };
			text: { message: boolean | null | undefined };
		}[];
	},
	programAnswer: SessionFeedbackAnswerInterface
) {
	return (
		<>
			{programQuestion.map((question: ProgramQuestionInterface, index: number) => {
				let feedback = null;
				if (!_.isEmpty(programAnswer.sessionFeedbackForm)) {
					feedback = programAnswer.sessionFeedbackForm.feedback.find((a) => a.question_id === question.id);
				}
				return (
					<FlexboxGrid justify="center" key={index}>
						<FlexboxGrid.Item colspan={24}>
							<Form.Group key={question.id} className="feedback-session-form-mobile">
								<Controller
									name={`feedback.${index}.question_id` as `${string}.${number}`}
									control={control}
									defaultValue={question.id}
									render={({ field }) => <Form.Control {...field} className="hidden" />}
								/>
								<Controller
									name={`feedback.${index}.question_category` as `${string}.${number}`}
									control={control}
									defaultValue={question.category}
									render={({ field }) => <Form.Control {...field} className="hidden" />}
								/>
								<Controller
									name={`feedback.${index}.required` as `${string}.${number}`}
									control={control}
									defaultValue={`${question.primary}`}
									render={({ field }) => <Form.Control {...field} className="hidden" />}
								/>
								<Controller
									name={`feedback.${index}.rated_participant_id` as `${string}.${number}`}
									control={control}
									defaultValue={`${programAnswer.sessionDetailForm.ratedParticipantId}`}
									render={({ field }) => <Form.Control {...field} className="hidden" />}
								/>
								<Form.ControlLabel>
									<FlexboxGrid.Item colspan={24}>
										<span className="text-base font-medium text-gray-900">{question.title}</span>
										{question.required && (
											<span className="text-sm leading-4 text-red-500"> *</span>
										)}
										<div className="pb-2 text-gray-600">{question.description}</div>
									</FlexboxGrid.Item>
									<FlexboxGrid.Item colspan={24}>
										{answerField(question, index, control, feedback)}
									</FlexboxGrid.Item>
								</Form.ControlLabel>
								{errors.feedback?.[index]?.text && (
									<p className="not-italic font-normal pt-1 mb-2 text-xs leading-4 text-red-500">
										{errors.feedback?.[index].text.message}
									</p>
								)}
								{errors.feedback?.[index]?.value && (
									<p className="not-italic font-normal pt-1 mb-2 text-xs leading-4 text-red-500">
										{errors.feedback?.[index].value.message}
									</p>
								)}
							</Form.Group>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				);
			})}
		</>
	);
}

export function showQuestion(
	programQuestion: ProgramQuestionInterface[],
	programAnswer: SessionFeedbackAnswerInterface
) {
	return (
		<>
			{programQuestion.map((question: ProgramQuestionInterface, index: number) => {
				const feedback = programAnswer.sessionFeedbackForm.feedback.find((a) => a.question_id === question.id);
				return (
					<FlexboxGrid justify="center" key={index}>
						<FlexboxGrid.Item colspan={24}>
							<Form.Group className="feedback-session-form-mobile">
								<Form.ControlLabel>
									<FlexboxGrid.Item colspan={24}>
										<span className="text-base font-medium text-gray-900">{question.title}</span>
										{question.required && (
											<span className="text-sm leading-4 text-red-500"> *</span>
										)}
										<div className="pb-2 text-gray-600">{question.description}</div>
									</FlexboxGrid.Item>
									<FlexboxGrid.Item colspan={24}>{ShowAnswer(question, feedback)}</FlexboxGrid.Item>
								</Form.ControlLabel>
							</Form.Group>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				);
			})}
		</>
	);
}

export function ShowSubmittedFeedback(programAnswer: any) {
	return (
		<>
			{programAnswer.sessionFeedbackForm.feedback.map((question: any, index: number) => {
				return (
					<FlexboxGrid justify="center" key={index}>
						<FlexboxGrid.Item colspan={24}>
							<Form.Group className="feedback-session-form-mobile">
								<Form.ControlLabel>
									<FlexboxGrid.Item colspan={24}>
										<span className="text-base font-medium text-gray-900">{question.title}</span>
										{question.required && (
											<span className="text-sm leading-4 text-red-500"> *</span>
										)}
										<div className="pb-2 text-gray-600">{question.description}</div>
									</FlexboxGrid.Item>
									<FlexboxGrid.Item colspan={24}>{ShowSubmittedAnswer(question)}</FlexboxGrid.Item>
								</Form.ControlLabel>
							</Form.Group>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				);
			})}
		</>
	);
}
