// React
import React from 'react';
import { useSelector } from 'react-redux';

// Futurelab
import MobileTopNavBar from './MobileTopNavBar';
// import MobileBottomNavBar from './MobileBottomNavBar';
import { RootState } from '../../../reducers/index';

// CSS
import '../../../assets/nav/Navbar.css';

interface MobileNavBarProps {
	logout: () => void;
	token: string;
}

export default function MobileNavBar(props: MobileNavBarProps) {
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const currentFormInFocus = useSelector((state: RootState) => state.feedbackFormInFocus);

	const { logout, token } = props;
	return (
		<>
			<MobileTopNavBar logout={logout} token={token}></MobileTopNavBar>
			{/* {chatInFocus || currentFormInFocus ? '' : <MobileBottomNavBar></MobileBottomNavBar>} */}
		</>
	);
}
