// React
import { useState, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { withRoot } from './withRoot';
import { router } from './Router';
import { useSelector } from 'react-redux';
import PWAPrompt from 'react-ios-pwa-prompt';

// UI
import Alert from '@mui/material/Alert';
import 'rsuite/dist/rsuite.min.css';
import { Offline, Online } from 'react-detect-offline';
import { FlexboxGrid } from 'rsuite';

// Futurelab
import Loader from './components/common/Loader';
import { RootState } from './reducers';
import { SocketProvider } from './hooks/useSocket';

// CSS
import './assets/output.css';
import './assets/main.css';

// 3rd Party
import mixpanel from 'mixpanel-browser';

const config = {
	url: 'https://apiauth.futurelab.my',
	interval: 10000,
	timeout: 10000,
	enabled: true,
};

function App() {
	const isLoading = useSelector((state: RootState) => state.showLoader);
	const [showOnlineMessage, setShowOnlineMessage] = useState<boolean>(false);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);

	mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);
	mixpanel.identify(`${loggedInUserDetails.id}`);

	const onlineStatusChange = (online: boolean) => {
		if (online) {
			setShowOnlineMessage(true);
			setTimeout(() => {
				setShowOnlineMessage(false);
			}, 3000);
		}
	};

	return (
		<SocketProvider>
			<div>
				{isLoading && <Loader/>}
				<div>
					<RouterProvider router={router} />
				</div>
				<Online polling={config}>
					{showOnlineMessage && (
						<FlexboxGrid justify="center">
							<Alert className="internet-connection-status">Internet connection back to normal</Alert>
						</FlexboxGrid>
					)}
				</Online>
				<Offline polling={config} onChange={onlineStatusChange}>
					<FlexboxGrid justify="center">
						<Alert className="internet-connection-status" severity="error">
							You seem to have lost internet connection
						</Alert>
					</FlexboxGrid>
				</Offline>
			</div>
			<PWAPrompt />
		</SocketProvider>
	);
}

export default withRoot(App);
