//React
import React from 'react';

// Futurelab
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../services/userService';

// UI
import { ButtonToolbar, Button, Modal, Row, Col, Tooltip, Whisper } from 'rsuite';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';

// 3rd Party
import moment from 'moment';

export interface ProfilePreviewData {
	id: number;
	email: string;
	firstname: string;
	lastname: string;
	profile_intro: string;
	profile_photo: string;
	created_at: string;
	become_a_mentor: boolean;
	user_skills: string;
	skills: [];
	industries: [];
	hex_id: string;
	user_type: number;
}

function UserDetails({ displayUserDetails, setDisplayUserDetails, userProfileDetails, photoUrl }: any) {
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const [profilePreview, setProfilePreview] = React.useState<ProfilePreviewData>();
	const handleClose = () => setDisplayUserDetails(!displayUserDetails);

	React.useEffect(() => {
		const recipientDetails = chatInFocus?.participants.find(
			(participant) => participant.userId !== loggedInUserDetails.id
		);
		const recipientUserId = recipientDetails?.userId;
		if (profilePreview === undefined) {
			getUserInfo({ userId: recipientUserId }).then((res) => {
				setProfilePreview(res?.data?.data);
			});
		}
		if (profilePreview !== undefined) {
			setProfilePreview(undefined);
		}
	}, [chatInFocus]);

	const userSkills = profilePreview?.skills.map(({ name }, index) => {
		if (index <= 1) {
			return name + (index < profilePreview?.skills?.length - 1 ? ', ' : '');
		}
	});

	const userSkillsInToolTips = profilePreview?.skills.map(({ name }, index) => {
		if (index >= 2) {
			return name + (index < profilePreview?.skills?.length - 1 ? ', ' : '');
		}
	});

	const tooltip = (
		<Tooltip className="skill-tooltip">
			<ul>
				<li>{userSkillsInToolTips}</li>
			</ul>
		</Tooltip>
	);

	const goToUserProfile = () => {
		window.open(
			`${process.env.REACT_APP_B2C_BASE_URL}mentor_profile/${profilePreview?.firstname}/${profilePreview?.hex_id}`,
			'_blank'
		);
	};

	return (
		<Modal backdrop={true} size="xs" open={displayUserDetails} onClose={handleClose}>
			<Row>
				<Col xs={8} lg={8}>
					{!!userProfileDetails?.profile_photo ? (
						<img
							className="user-picture"
							src={`${photoUrl}/${userProfileDetails?.id}/${userProfileDetails?.profile_photo}`}
						/>
					) : (
						<img className="user-picture" src="../../../default-profile-pic.png" />
					)}
				</Col>
				<Col xs={16} lg={16} className="username">
					<span>
						{`${profilePreview?.firstname} ${profilePreview?.lastname ?? ''}`}
						<StarRoundedIcon className="mentor-star-icon" fontSize="small" />
						5.0
					</span>
					<Row className="user-profession-container">
						<Col>
							<BusinessCenterRoundedIcon color="disabled" fontSize="small" />
						</Col>
						<Col xs={20} className="user-profession">
							<span>{profilePreview?.profile_intro}</span>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="user-description">
				<Row>
					<Col xs={3} md={2} lg={2} className="text-center">
						<VerifiedUserRoundedIcon color="disabled" fontSize="small" />
					</Col>
					<Col xs={20} lg={20}>
						<span className="user-mentoring-since">
							{profilePreview?.user_type === 1
								? `Mentor since ${moment(profilePreview?.created_at).format('YYYY')} (${moment(
										profilePreview.created_at
								  ).fromNow()})`
								: `Mentee`}
						</span>
					</Col>
				</Row>
				<Row className="user-skill">
					<Col xs={3} md={2} lg={2} className="text-center">
						<PsychologyRoundedIcon color="disabled" />
					</Col>
					<Col xs={20} lg={20}>
						<span>{userSkills}</span>
						<Whisper
							placement="autoVerticalStart"
							controlId="control-id-click"
							trigger="hover"
							speaker={tooltip}
						>
							<span className="skill-tooltip-counter">
								{userSkills?.length ? `+${userSkills.length - 2}` : `Skills have not been added.`}
							</span>
						</Whisper>
					</Col>
				</Row>
			</Row>
			{profilePreview?.user_type === 1 ? (
				<Row>
					<ButtonToolbar className="chat-popup-button-group">
						<Button appearance="ghost" onClick={goToUserProfile}>
							See Profile
						</Button>
						<Button appearance="primary" onClick={goToUserProfile}>
							Book a Session
						</Button>
					</ButtonToolbar>
				</Row>
			) : (
				''
			)}
		</Modal>
	);
}

export default UserDetails;
