// UI
import Alert from '@mui/material/Alert';
import { Col, FlexboxGrid } from 'rsuite';

interface ScrollToBottomProps {
	scrollToBottomNewMessage: boolean;
	scrollToBottomTrigger: () => void;
}
export function ScrollToBottom(scrollToBottomProps: ScrollToBottomProps) {
	const { scrollToBottomNewMessage, scrollToBottomTrigger } = scrollToBottomProps;
	return (
		<>
			{scrollToBottomNewMessage ? (
				<FlexboxGrid.Item
					as={Col}
					mdOffset={5}
					lgOffset={7}
					xlOffset={8}
					md={14}
					lg={10}
					xl={8}
					className="scroll-to-bottom-new-message-alert"
					onClick={scrollToBottomTrigger}
				>
					<Alert className="new-message-alert">new message has been received</Alert>
				</FlexboxGrid.Item>
			) : (
				''
			)}
		</>
	);
}
