// React
import React from 'react';

// UI
import { Form, InputGroup, Tooltip, Whisper } from 'rsuite';
import { VisibilityOffRounded, VisibilityOutlined, Info } from '@material-ui/icons';

export default function InputPasswordComponent(props: any) {
	const { label, name, placeholder, showTooltip } = props;
	const [showPassword, setShowPassword] = React.useState(false);
	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	const tooltip = (
		<Tooltip>
			<ul className="password-requirement-tooltip">
				<li>Minimum of 6 characters</li>
				<li>1 Uppercase character</li>
				<li>1 Lowercase character</li>
				<li>1 Symbol</li>
			</ul>
		</Tooltip>
	);

	return (
		<div className="mb-4">
			{showTooltip ? (
				<>
					<Form.ControlLabel className="password-and-icon">{label}</Form.ControlLabel>
					<Whisper placement="top" trigger="hover" speaker={tooltip}>
						<Info className="password-info-icon" />
					</Whisper>
				</>
			) : (
				<Form.ControlLabel className="font-medium">{label}</Form.ControlLabel>
			)}
			<InputGroup inside>
				<Form.Control
					name={name}
					placeholder={placeholder}
					type={showPassword ? 'text' : 'password'}
					autoComplete="off"
					checkTrigger="none"
				/>
				<InputGroup.Button onClick={togglePassword}>
					{showPassword ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffRounded fontSize="small" />}
				</InputGroup.Button>
			</InputGroup>
		</div>
	);
}
