// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Button, ButtonToolbar, FlexboxGrid, Form, Message, toaster } from 'rsuite';
import SearchBar from 'material-ui-search-bar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// FutureLab
import { createGroupChat } from '../services/chatService';
import { chatItemInFocus } from '../actions/chatItemInFocusActions';
import { UserProfileDetails } from './AddChatPage';
import { getListOfUser } from '../services/userService';
import InputField from '../components/common/InputField';

// Reducer
import { store } from '../ReduxRoot';

// 3rd Party
import _ from 'lodash';

interface CreateGroupChatForm {
	chatGroupName?: string;
	groupChatDescription?: string;
}

export function AddGroupChatPage() {
	const [userList, setUserList] = useState<UserProfileDetails[]>([]);
	const [selectedUserList, setSelectedUserList] = useState<UserProfileDetails[]>([]);
	const [filterQuery, setFilterQuery] = useState('');
	const navigate = useNavigate();
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;

	const [formValues, setFormValues] = useState<CreateGroupChatForm>({
		chatGroupName: '',
		groupChatDescription: '',
	});

	const filterObject = {
		filer: {
			firstName: '',
			lastName: '',
			email: '',
		},
	};

	useEffect(() => {
		getListOfUser(filterObject)
			.then((res) => {
				setUserList(res.data.data);
			})
			.catch((err) => {
				console.warn('Error:', err);
			});
	}, []);

	const getGeryResults = () => {
		return userList.filter((user) => user.email.includes(filterQuery));
	};

	const formValue = (data: CreateGroupChatForm) => {
		setFormValues(data);
	};

	const addUserToGroupUsersList = (userDetails: UserProfileDetails): void => {
		const selectedUserListClone = _.cloneDeep(selectedUserList);
		if (selectedUserListClone.find((selectedUser) => selectedUser.id === userDetails.id)) {
			const filteredList = selectedUserListClone.filter((selectedUser) => selectedUser.id !== userDetails.id);
			setSelectedUserList(filteredList);
		} else {
			selectedUserListClone.push(userDetails);
			setSelectedUserList(selectedUserListClone);
		}
	};

	const submitForm = () => {
		createGroupChat({
			groupChatName: formValues.chatGroupName,
			groupChatDescription: formValues.groupChatDescription,
			recipientUserIds: selectedUserList.map((ele) => ele.id),
		})
			.then((res) => {
				store.dispatch(chatItemInFocus(res?.data?.data));
				navigate('../chat/chat-room');
			})
			.catch((err) => {
				toaster.push(
					<Message type="error" showIcon>
						Chat Already Exists
					</Message>,
					{ placement: 'bottomCenter' }
				);
			});
	};

	return (
		<div className="add-group-chat">
			<Form onChange={formValue}>
				<Form.Group>
					<InputField name={'chatGroupName'} placeholder={'Chat Group Name'} />
				</Form.Group>
				<Form.Group>
					<InputField name={'groupChatDescription'} placeholder={'Chat Group Description'} />
				</Form.Group>
			</Form>
			<ButtonToolbar>
				<Button type="submit" appearance="primary" block onClick={submitForm}>
					Create Group
				</Button>
			</ButtonToolbar>
			<div className="search-chat-bar">
				<SearchBar
					placeholder="Search"
					value={''}
					onChange={(newValue) => setFilterQuery(newValue)}
					onCancelSearch={() => setFilterQuery('')}
				/>
			</div>
			<div className="search-chat-bar">
				<Stack direction="row" spacing={1}>
					{selectedUserList.length > 0
						? selectedUserList.map((selectedUser, index) => {
								return <Chip key={index} label={selectedUser.email} />;
						  })
						: ''}
				</Stack>
			</div>
			<div className="vertical-scroll-enabled-group-chat">
				{getGeryResults().map((user, index) => {
					return (
						<div key={index} className="user-list-row" onClick={() => addUserToGroupUsersList(user)}>
							<FlexboxGrid>
								<FlexboxGrid.Item colspan={3}>
									<div className="chat-list-photo-holder">
										<img
											src={`${photoUrl}/${user.id}/${user.profile_photo}`}
											className="chat-list-image-holder"
										/>
									</div>
								</FlexboxGrid.Item>
								<FlexboxGrid.Item colspan={21} className="add-chatlist-one-to-one-title">
									<div>{user.email}</div>
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</div>
					);
				})}
			</div>
		</div>
	);
}
