// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { windowSizeType, getWindowSize } from '../../../utilities/WindowResize';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackSessionFormInFocus } from '../../../actions/feedbackFormInFocusAction';
import { RootState } from '../../../reducers';
import { questionField } from './SessionQuestion';
import { feedbackSessionQuestion } from '../../../actions/feedbackQuestionAction';
import { resetSessionFeedbackForm } from '../../../actions/feedbackAnswerAction';

// UI
import { Button, ButtonToolbar, Col, FlexboxGrid, Form } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

// 3rd Party
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// TODO: fix schema
const sessionFeedbackAnswerSchema = yup.object({
	feedback: yup.array().of(
		yup.object({
			question_id: yup.number(),
			question_category: yup.string(),
			required: yup.bool(),
			text: yup.mixed().when(['required', 'question_category'], {
				is: (required: boolean, category: string) => required && category !== 'stars',
				then: yup.lazy((value) =>
					Array.isArray(value)
						? yup.array().of(yup.string().trim()).required('This field is required')
						: yup.string().trim().required('This field is required')
				),
				otherwise: yup.mixed().nullable().default(null),
			}),
			value: yup.number().when(['required', 'question_category'], {
				is: (required: boolean, category: string) => required && category === 'stars',
				then: yup.number().typeError('This field is required'),
				otherwise: yup.number().nullable().default(null),
			}),
		})
	),
});

export default function SessionFeedbackForm(props: any) {
	const { onSubmit } = props;
	const programQuestion = useSelector((state: RootState) => state.programQuestion);
	const programAnswer = useSelector((state: RootState) => state.programAnswer);
	const dispatch = useDispatch();
	const [displayModalConfirmation, setDisplayModalConfirmation] = useState<boolean>(false);
	const optionStore = { action: 'leavingPage' };
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(sessionFeedbackAnswerSchema),
	});
  const navigate = useNavigate()
  
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const onError = (errors: any) => {
		console.log('errors: ', errors);
	};

	const routeToFeedbackSessionPage = () => {
		dispatch(feedbackSessionFormInFocus(null));
		dispatch(feedbackSessionQuestion([]));
		dispatch(resetSessionFeedbackForm());
    navigate('../feedback-session')
	};

	return (
		<>
			{getWindowSize(windowSize.width) === windowSizeType.XS ? (
				<FlexboxGrid justify="center">
					<FlexboxGrid.Item colspan={22} className="pb-5 pt-6 border-b border-gray-200">
						<FlexboxGrid.Item colspan={19} as={Col} className="session-detail">
							Feedback
						</FlexboxGrid.Item>
						<FlexboxGrid.Item
							colspan={5}
							as={Col}
							className="text-right"
							onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}
						>
							<CloseIcon className="w-6 h-6" />
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="feedback-session-room-mobile">
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={21}>
								{questionField(programQuestion, control, errors, programAnswer)}
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={21} className="py-6">
								<ButtonToolbar>
									<Button block appearance="primary" onClick={handleSubmit(onSubmit, onError)}>
										Continue
									</Button>
								</ButtonToolbar>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</FlexboxGrid.Item>
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={routeToFeedbackSessionPage}
						/>
					)}
				</FlexboxGrid>
			) : (
				<FlexboxGrid justify="center">
					<FlexboxGrid.Item colspan={24}>
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 border-b border-gray-200">
								<FlexboxGrid.Item colspan={16} as={Col} className="session-detail-desktop">
									Feedback
								</FlexboxGrid.Item>
								<FlexboxGrid.Item colspan={6} as={Col}>
									<ButtonToolbar>
										<Button block appearance="primary" onClick={handleSubmit(onSubmit, onError)}>
											Continue
										</Button>
									</ButtonToolbar>
								</FlexboxGrid.Item>
								<FlexboxGrid.Item
									colspan={2}
									as={Col}
									className="text-right"
									onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}
								>
									<CloseIcon className="w-6 h-9" />
								</FlexboxGrid.Item>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={24} className="feedback-session-form-desktop">
								{questionField(programQuestion, control, errors, programAnswer)}
							</FlexboxGrid.Item>
							{displayModalConfirmation && (
								<ModalConfirmation
									displayModalConfirmation={displayModalConfirmation}
									setDisplayModalConfirmation={setDisplayModalConfirmation}
									optionStore={optionStore}
									callBackProceedMethodProp={routeToFeedbackSessionPage}
								/>
							)}
						</FlexboxGrid>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</>
	);
}
