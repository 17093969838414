// // React
import { NavigateFunction } from 'react-router-dom';

let navigate: (arg0: any) => void;

export function initializeNavigation(navigateFn: NavigateFunction) {
	navigate = navigateFn;
}

export function navigateToRoute(routePath: any) {
	if (navigate) {
		navigate(routePath);
	}
}
