// React
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// FutureLab
import { getWindowSize, windowSizeType } from '../utilities/WindowResize';
import { getChatDetailsById } from '../services/chatService';
import { store } from '../ReduxRoot';
import { chatItemInFocus } from '../actions/chatItemInFocusActions';
import { navigateToRoute } from '../utilities/NavigateTo';
import { loggedInUserDetails } from '../actions/loggedInUserDetailsActions';
import { getUserListsDetails, userDetails } from '../services/userService';
import { userListDetails } from '../actions/userListDetailsActions';
import { ChatParticipantsInterface } from '../components/chat/ChatListItemsDesktop';

// 3rd Party
import _ from 'lodash';

export function ChatRedirectPage() {
	const { chatId } = useParams();
	const windowSize = {
		width: window.innerWidth,
		height: window.innerHeight,
	};

	useEffect(() => {
		const fetchData = async () => {
			// Fetch logged-in user details
			const userDetailsResponse = await userDetails();
			store.dispatch(loggedInUserDetails(userDetailsResponse.data.data));

			if (chatId) {
				// Fetch chat details
				const chatResponse = await getChatDetailsById(chatId);
				store.dispatch(chatItemInFocus(chatResponse.data?.data));
			}

			// Extract the user IDs of chat participants
			const chatParticipantsUserId = store
				.getState()
				.chatInFocus.participants.map((participant: ChatParticipantsInterface) => participant.userId);

			if (_.isEmpty(store.getState().userListProfilesDetails)) {
				const userListResponse = await getUserListsDetails({ userIds: chatParticipantsUserId });
				store.dispatch(userListDetails(userListResponse.data?.data));
			}

			if (getWindowSize(windowSize.width) === windowSizeType.XS) {
				navigateToRoute('chat/chat-room');
			} else {
				navigateToRoute('chat');
			}
		};

		fetchData();
	}, []);

	return <div></div>;
}
