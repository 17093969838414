import React from 'react';
import WorkInProgressLogo from '../components/common/WorkInProgressLogo';

export function ProfilePage() {
	return (
		<div>
			<WorkInProgressLogo />
		</div>
	);
}
