// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { resetPasswordEndPoint } from '../services/userService';

// UI
import { Container, FlexboxGrid, Form, ButtonToolbar, Button, Message, Schema, toaster } from 'rsuite';
import InputField from '../components/common/InputField';
import Logo from '../components/common/Logo';

//Recaptcha
import RecaptchaComponent from '../components/google/Recaptcha';

interface emailInput {
	email?: string;
	recaptcha_token?: string | null;
}

export function ResetPasswordPage() {
	const [formError, setFormError] = useState({});
	const [formValues, setformValues] = useState<emailInput>({
		email: '',
	});
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
	const navigate = useNavigate();
	const formEmailValue = (data: emailInput) => {
		setformValues(data);
	};

	const onDisable = (recaptcha_token: emailInput) => {
		if (recaptcha_token == '') {
			setDisableSubmit(true);
		} else {
			//upon verification expired, submit button will revert to disabled state
			setDisableSubmit(false);
		}
	};

	const handleSubmit = () => {
		// Save email value to return in UpdatePasswordPage
		sessionStorage.setItem('userEmail', formValues.email || '');
		resetPasswordEndPoint(formValues)
			.then(() => {
				navigate('../update-password');
			})
			.catch((err: any) => {
				console.error('err:', err);
				toaster.push(
					<Message type="error" showIcon>
						Registration failed
					</Message>,
					{ placement: 'bottomCenter' }
				);
			});
	};

	const { StringType } = Schema.Types;
	const model = Schema.Model({
		email: StringType().isRequired('This field is required.').isEmail('Please enter a valid email.'),
	});

	return (
		<Container className="w-full max-w-sm">
			<FlexboxGrid justify="center" align="middle">
				<FlexboxGrid.Item colspan={24}>
					<Logo className="px-6 mb-4" />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={24} className="main-wrapper">
					<h3 className="text-black text-xl font-medium mb-4">Reset Password</h3>
					<Message className="mb-4">
						Enter your user account's verified email address and we will send you a password reset code.
					</Message>
					<Form
						fluid
						model={model}
						onSubmit={handleSubmit}
						onChange={formEmailValue}
						onCheck={setFormError}
						formError={formError}
						checkTrigger={'none'}
					>
						<Form.Group>
							<InputField name={'email'} placeholder={'example@email.com'} label={'Your Email'} />
						</Form.Group>
						{/* <RecaptchaComponent onDisable={onDisable} /> */}
						<ButtonToolbar>
							<Button appearance="primary" type="submit" block disabled={disableSubmit}>
								Send me the code
							</Button>
						</ButtonToolbar>
					</Form>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</Container>
	);
}
