// FutureLab
import { PinnedMessageInFocusTypes, PinnedMessageInFocusActions } from '../model/index';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';

export function pinnedMessageInFocus(pinnedMessageInFocus: PinnedMessageItem | null): PinnedMessageInFocusActions {
	return {
		type: PinnedMessageInFocusTypes.PINNED_MESSAGE_IN_FOCUS,
		payload: pinnedMessageInFocus,
	};
}
