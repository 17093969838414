// React
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { windowSizeType, getWindowSize } from '../../utilities/WindowResize';
import { ReviewSessionDetails } from './ReviewSessionDetails';
import { feedbackSessionQuestion } from '../../actions/feedbackQuestionAction';
import { feedbackSessionFormInFocus } from '../../actions/feedbackFormInFocusAction';
import { ProgramQuestionInterface, SessionFeedbackAnswerInterface } from './SessionFeedback';
import { LoggedInUserDetails } from '../../reducers';
import { resetSessionFeedbackForm } from '../../actions/feedbackAnswerAction';
import { submitOfflineFeedback, submitSessionFeedback } from '../../services/railsService';

// UI
import { Button, ButtonToolbar, Col, FlexboxGrid } from 'rsuite';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// 3rd Party
import moment from 'moment';
import { omit } from 'lodash';
import mixpanel from 'mixpanel-browser';

interface SessionSummaryProps {
	programQuestion: ProgramQuestionInterface[];
	programAnswer: SessionFeedbackAnswerInterface;
	loggedInUserDetail: LoggedInUserDetails;
}

export default function SessionSummary(sessionSummaryProps: SessionSummaryProps) {
	const { programQuestion, programAnswer, loggedInUserDetail } = sessionSummaryProps;
	const { sessionDetailForm, sessionFeedbackForm } = programAnswer;
	const { sessionWith, sessionService } = sessionDetailForm;
	const { mentee, mentor, group_id } = sessionWith.value;

	const formType = mentee.id !== loggedInUserDetail.id ? 'mentee' : 'mentor';

	const [value, setValue] = useState('1');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleTabChange = () => {
		if (value === '1') {
			setValue('2');
		}
		if (value === '2') {
			const formData = new FormData();
			if (sessionDetailForm.bookingId) {
				const feedbackFormAnswer = sessionFeedbackForm.feedback.map((ele) =>
					omit(ele, ['required', 'question_category'])
				);
				formData.append('booking_reviews', JSON.stringify(feedbackFormAnswer));
				submitSessionFeedback(sessionDetailForm.bookingId, formData);

				mixpanel.track('Submitted Pending Session', {
					'User Id': loggedInUserDetail.id,
					'Review Form Type': formType,
					'Booking Id': sessionDetailForm.bookingId,
					'Program Name': sessionDetailForm.programme.label,
					'Program Id': sessionDetailForm.programme.value,
					'Session With': sessionDetailForm.sessionWith.label,
					'Group Id': sessionDetailForm.sessionWith.value.group_id,
					'Service Provided': sessionDetailForm.sessionService.label,
					'Session Type': sessionDetailForm.sessionType === 0 ? 'Online' : 'Physical',
				});
			} else {
				const feedbackFormAnswer = sessionFeedbackForm.feedback.map((ele) =>
					omit(ele, ['required', 'question_category', 'rated_participant_id'])
				);
				formData.append('program_id', `${sessionDetailForm.programme.value}`);
				formData.append('time', `${moment(sessionDetailForm.sessionTime).format('HHmm')}`);
				formData.append('date', `${moment(sessionDetailForm.sessionDate).format('DD/MM/YYYY')}`);
				formData.append('mentee_id', `${mentee.id}`);
				formData.append('mentor_id', `${mentor.id}`);
				formData.append('session_method', `${sessionDetailForm.sessionType}`);
				formData.append('group_id', `${group_id}`);
				formData.append('review_form_for', formType);
				formData.append('service_id', `${sessionService.value}`);
				formData.append('feedback', JSON.stringify(feedbackFormAnswer));
				submitOfflineFeedback(formData);

				mixpanel.track('Submitted New Offline Session', {
					'User Id': loggedInUserDetail.id,
					'Review Form Type': formType,
					'Program Name': sessionDetailForm.programme.label,
					'Program Id': sessionDetailForm.programme.value,
					'Session With': sessionDetailForm.sessionWith.label,
					'Group Id': sessionDetailForm.sessionWith.value.group_id,
					'Service Provided': sessionDetailForm.sessionService.label,
					'Session Type': sessionDetailForm.sessionType === 0 ? 'Online' : 'Physical',
				});
			}

			setIsSubmitted(true);
		}
	};

	const routeToHomePage = () => {
		dispatch(feedbackSessionFormInFocus(null));
		dispatch(feedbackSessionQuestion([]));
		dispatch(resetSessionFeedbackForm());
		navigate('..');
	};

	const routeToPreviousSessionPage = () => {
		dispatch(feedbackSessionFormInFocus(null));
		dispatch(feedbackSessionQuestion([]));
		dispatch(resetSessionFeedbackForm());
		navigate('../previous-session');
	};

	const handleEdit = () => {
		// TODO: get the session detail form input to persist
		// if (value === '1') {
		// 	dispatch(feedbackSessionFormInFocus(1));
		// }
		if (value === '2') {
			dispatch(feedbackSessionFormInFocus(2));
			mixpanel.track('Edit Session Feedback Form');
		}
	};

	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<FlexboxGrid justify="center">
			{isSubmitted ? (
				<FlexboxGrid justify="center" className="pt-16">
					<FlexboxGrid.Item colspan={18}>
						<FlexboxGrid.Item colspan={24} className="justify-center flex">
							<img src='src="../../../feedback-submitted.png' className="h-56 w-56" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="text-center py-3">
							<span className="text-xl text-gray-900">Congratulations!</span>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="text-center py-2 flex justify-center">
							<span className="text-base text-gray-700">
								Your form has been submitted. Thank you for your feedback and ratings
							</span>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="justify-center flex pt-4">
							<Button className="px-24" appearance="primary" onClick={routeToHomePage}>
								OK
							</Button>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			) : (
				<>
					{getWindowSize(windowSize.width) === windowSizeType.XS ? (
						<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 pl-4 border-b border-gray-200">
							<FlexboxGrid.Item colspan={19} as={Col} className="session-feedback">
								Summary
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					) : (
						<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 pl-4 border-b border-gray-200">
							<FlexboxGrid.Item colspan={12} as={Col} className="session-feedback">
								Summary
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={12} as={Col} className="text-right">
								{programAnswer.sessionFeedbackForm.submitted ? (
									<FlexboxGrid>
										<FlexboxGrid.Item colspan={24}>
											{value === '2' ? (
												<Button block appearance="primary" onClick={routeToPreviousSessionPage}>
													Back
												</Button>
											) : (
												<Button block appearance="primary" onClick={handleTabChange}>
													Next
												</Button>
											)}
										</FlexboxGrid.Item>
									</FlexboxGrid>
								) : (
									<ButtonToolbar>
										{value === '2' ? (
											<Button appearance="ghost" onClick={handleEdit}>
												Edit
											</Button>
										) : (
											''
										)}
										<Button appearance="primary" onClick={handleTabChange}>
											{value === '2' ? 'Submit' : 'Next'}
										</Button>
									</ButtonToolbar>
								)}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
					)}
					<FlexboxGrid.Item colspan={24}>
						<TabContext value={value}>
							<Box sx={{ borderColor: 'divider' }}>
								<TabList variant="fullWidth" className="pt-2">
									<Tab label="Session Details" value="1" className="required-session" />
									<Tab label="Feedback" value="2" className="submitted-session" />
								</TabList>
							</Box>
							<TabPanel
								value="1"
								className={
									getWindowSize(windowSize.width) === windowSizeType.XS
										? 'session-detail-summary'
										: 'session-detail-summary-desktop'
								}
							>
								<ReviewSessionDetails
									programQuestion={programQuestion}
									programAnswer={programAnswer}
									value={value}
								/>
								{getWindowSize(windowSize.width) === windowSizeType.XS ? (
									<FlexboxGrid>
										<FlexboxGrid.Item colspan={24}>
											<Button block appearance="primary" onClick={handleTabChange}>
												Next
											</Button>
										</FlexboxGrid.Item>
									</FlexboxGrid>
								) : (
									''
								)}
							</TabPanel>
							<TabPanel
								value="2"
								className={
									getWindowSize(windowSize.width) === windowSizeType.XS
										? 'session-detail-summary'
										: 'session-detail-summary-desktop'
								}
							>
								<ReviewSessionDetails
									programQuestion={programQuestion}
									programAnswer={programAnswer}
									value={value}
								/>
								{getWindowSize(windowSize.width) === windowSizeType.XS ? (
									<>
										{programAnswer.sessionFeedbackForm.submitted ? (
											<FlexboxGrid>
												<FlexboxGrid.Item colspan={24}>
													<Button
														block
														appearance="primary"
														onClick={routeToPreviousSessionPage}
													>
														Back
													</Button>
												</FlexboxGrid.Item>
											</FlexboxGrid>
										) : (
											<FlexboxGrid>
												<FlexboxGrid.Item colspan={24}>
													<FlexboxGrid.Item colspan={12} as={Col}>
														<Button block appearance="ghost" onClick={handleEdit}>
															Edit
														</Button>
													</FlexboxGrid.Item>
													<FlexboxGrid.Item colspan={12} as={Col}>
														<Button block appearance="primary" onClick={handleTabChange}>
															Submit
														</Button>
													</FlexboxGrid.Item>
												</FlexboxGrid.Item>
											</FlexboxGrid>
										)}
									</>
								) : (
									''
								)}
							</TabPanel>
						</TabContext>
					</FlexboxGrid.Item>
				</>
			)}
		</FlexboxGrid>
	);
}
