import React, { useState } from 'react';

// FutureLab
import { userDetails, userLoginEndPoint } from '../../services/userService';
import { loginInput } from '../../model';
import { loggedInUserDetails } from '../../actions/loggedInUserDetailsActions';

// UI
import { Form, Button, FlexboxGrid, Schema, Checkbox, ButtonToolbar, toaster, Message } from 'rsuite';
import InputPasswordComponent from '../../components/auth/InputPassword';
import InputField from '../../components/common/InputField';

//Recaptcha
import RecaptchaComponent from '../../components/google/Recaptcha';

// 3rd Party
import Cookies from 'js-cookie';

// Reducer
import { store } from '../../ReduxRoot';
import { useNavigate } from 'react-router-dom';

declare module 'react' {
	// extends React's HTMLAttributes
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		type?: string;
		autoComplete?: string;
		size?: string;
	}
}

export default function LoginFormComponent() {
	const [checkTrigger, setCheckTrigger] = useState<string>('none');
	const [formError, setFormError] = useState({});
	const [formValues, setFormValues] = useState<loginInput>({
		email: '',
		password: '',
	});
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
	const pwaCookie = process.env.PWA_COOKIE_NAME || 'pwa_staging_session';
	const navigate = useNavigate();

	const forgotPasswordURL = `${process.env.REACT_APP_STAGING_BASE_URL}users/password/new`;
	const navigateToForgotPasswordPage = () => {
		window.open(`${forgotPasswordURL}`, '_blank');
	};

	let storedPathName = localStorage.getItem('redirectTo');

	const handleSubmit = () => {
		setCheckTrigger('change');
		userLoginEndPoint(formValues)
			.then((res) => {
				Cookies.set('token', res?.data?.data?.accessToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				Cookies.set(pwaCookie, res?.data?.data?.railsToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				Cookies.set('refreshToken', res?.data?.data?.refreshToken, {
					domain: process.env.REACT_APP_DOMAIN_NAME,
					expires: 365,
				});
				if (!storedPathName || storedPathName === '/login') {
					storedPathName = '/';
				}
				navigate(storedPathName);
				localStorage.removeItem('redirectTo');
			})
			.then(() => {
				userDetails().then((res) => {
					store.dispatch(loggedInUserDetails(res?.data?.data));
				});
			})
			.catch((err) => {
				toaster.push(
					<Message type="error" showIcon>
						Invalid credentials
					</Message>,
					{ placement: 'bottomCenter' }
				);
			});
	};

	const formValue = (data: loginInput) => {
		setFormValues(data);
	};

	// const onDisable = (recaptcha_token: loginInput) => {
	// 	if (recaptcha_token == '') {
	// 		setDisableSubmit(true);
	// 	} else {
	// 		//upon verification expired, submit button will revert to disabled state
	// 		setDisableSubmit(false);
	// 	}
	// };

	const { StringType } = Schema.Types;
	const model = Schema.Model({
		password: StringType().isRequired('This field is required.'),
		email: StringType().isRequired('This field is required.'),
	});

	return (
		<div>
			<Form
				fluid
				model={model}
				onSubmit={handleSubmit}
				onChange={formValue}
				checkTrigger={checkTrigger as any}
				onCheck={setFormError}
				formError={formError}
			>
				<Form.Group>
					<FlexboxGrid>
						<FlexboxGrid.Item colspan={24} style={{ paddingBottom: 16 }}>
							<span className="sign-in-email-and-password-text">Email</span>
							<InputField name={'email'} placeholder={'email@example.com'} />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24}>
							<span className="sign-in-email-and-password-text">Password</span>
							<InputPasswordComponent name={'password'} placeholder={'Password'} />
						</FlexboxGrid.Item>
					</FlexboxGrid>
					<FlexboxGrid justify="space-between" className="remember-me-and-forgot-password-container">
						<Checkbox className="sign-in-label-text">Remember Me</Checkbox>
						<ButtonToolbar>
							<Button
								className="sign-in-label-text"
								appearance="link"
								onClick={navigateToForgotPasswordPage /* history.push(router.resetPassword().$) */}
							>
								Forgot password
							</Button>
						</ButtonToolbar>
					</FlexboxGrid>
					{/* <RecaptchaComponent onDisable={onDisable} /> */}
					<Button appearance="primary" block type="submit" disabled={disableSubmit}>
						Sign In
					</Button>
				</Form.Group>
			</Form>
		</div>
	);
}
