// React
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Futurelab
import { SessionInterface, SessionsDetailsInterface } from './PreviousSessionMenu';
import { LoggedInUserDetails } from '../../reducers';
import { UserProfileDetails } from '../../pages';
import { programQuestions } from '../../services/railsService';
import { feedbackSessionQuestion } from '../../actions/feedbackQuestionAction';
import { feedbackSessionFormInFocus } from '../../actions/feedbackFormInFocusAction';
import { useDispatch } from 'react-redux';
import { updateSessionDetailForm } from '../../actions/feedbackAnswerAction';

// UI
import { Button, ButtonToolbar, Col, FlexboxGrid } from 'rsuite';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import EventIcon from '@mui/icons-material/Event';
import DvrIcon from '@mui/icons-material/Dvr';

// 3rd Party
import moment from 'moment';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

interface RequiredSessionProps {
	sessionInfo: SessionInterface;
	loggedInUserDetail: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
}

export default function RequiredSession(RequiredSessionProps: RequiredSessionProps) {
	const { sessionInfo, loggedInUserDetail, userListProfilesDetails } = RequiredSessionProps;
	const { unrated } = sessionInfo;
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sortedUnratedSession = _.orderBy(unrated, [(session) => new Date(session.booking_time)], ['desc']);

	const routeToSessionFeedbackForm = async (session: SessionsDetailsInterface, userRole: string) => {
		const ratedUser =
			session.group_details.mentee.id !== loggedInUserDetail.id
				? `${session.group_details.mentee.full_name}`
				: `${session.group_details.mentor.full_name}`;

		const startTime = moment(session.booking_start_time, 'h.mm A');
		const endTime = moment(session.booking_end_time, 'h.mm A');

		const duration = moment.duration(endTime.diff(startTime));

		const sessionMethod = session.session_method === 'Video Call' ? 0 : 1;

		const ratedParticipant = session.booking_participants.find(
			(participant) => participant.user_id !== loggedInUserDetail.id
		);

		const sessionDetails = {
			bookingId: session.id,
			ratedParticipantId: ratedParticipant?.id,
			programme: { label: session.program.label, value: session.program.value },
			sessionWith: { label: ratedUser, value: session.group_details },
			sessionType: sessionMethod,
			sessionDate: new Date(session.booking_time),
			sessionTime: new Date(session.booking_time),
			sessionDuration: { label: duration.humanize(), value: duration.asMinutes() },
			sessionService: { label: session.service.label, value: session.service.value },
		};

		dispatch(updateSessionDetailForm(sessionDetails));
		const programQuestionsResponse = await programQuestions(session.program.value, userRole);
		dispatch(feedbackSessionQuestion(programQuestionsResponse.data));
		await dispatch(feedbackSessionFormInFocus(2));
		navigate('../new-session');

		mixpanel.track('Clicked Give Feedback - Pending Session', {
			'Booking Id': session.id,
			'Program Name': session.program.label,
			'Program Id': session.program.value,
			'Service Provided': session.service.label,
			'Session Date': session.booking_time,
			'Session With': ratedUser,
			'Session Type': session.session_method,
		});
	};

	return (
		<div>
			{sortedUnratedSession.map((session, index) => {
				const getPendingRatingUserId =
					session.mentee_id === loggedInUserDetail.id ? session.mentor_id : session.mentee_id;
				const userRole = session.mentee_id !== loggedInUserDetail.id ? 'mentee' : 'mentor';

				const userProfileDetails = userListProfilesDetails.find((ele) => ele.id === getPendingRatingUserId);
				return (
					<FlexboxGrid key={index} className="mb-8 p-3 rounded-lg shadow-md border">
						<FlexboxGrid.Item colspan={24} className="pb-2">
							<FlexboxGrid.Item as={Col} xs={8} className="mb-3 pl-0 flex justify-center">
								<img
									src={`${photoUrl}/${userProfileDetails?.id}/${userProfileDetails?.profile_photo}`}
									className="rounded-xl w-20 h-20"
								/>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item as={Col} xs={16}>
								<FlexboxGrid.Item className="feedback-username">{`${userProfileDetails?.firstname} ${userProfileDetails?.lastname ?? ''}`}</FlexboxGrid.Item>
								<FlexboxGrid.Item colspan={24}>
									<FlexboxGrid.Item as={Col}>
										<WorkOutlineIcon sx={{ color: '#979797' }} />
									</FlexboxGrid.Item>
									<FlexboxGrid.Item as={Col} xs={18} className="feedback-description">
										{`${userProfileDetails?.userInfos.jobTitle} at ${userProfileDetails?.userInfos.company}`}
									</FlexboxGrid.Item>
								</FlexboxGrid.Item>
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="pb-2">
							<FlexboxGrid.Item as={Col} xs={4} className="flex justify-center">
								<EventIcon sx={{ color: '#979797' }} />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item as={Col} xs={20} className="session-date">
								{session?.booking_date}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="pb-3">
							<FlexboxGrid.Item as={Col} xs={4} className="flex justify-center">
								<DvrIcon sx={{ color: '#979797' }} />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item as={Col} xs={20} className="session-program-name">
								{session.program?.label}
							</FlexboxGrid.Item>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={24} className="border-t pt-4 pb-2">
							<ButtonToolbar>
								<Button
									block
									appearance="primary"
									onClick={() => routeToSessionFeedbackForm(session, userRole)}
								>
									Give Feedback
								</Button>
							</ButtonToolbar>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				);
			})}
		</div>
	);
}
