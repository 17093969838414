// React
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { windowSizeType, getWindowSize } from '../../../utilities/WindowResize';
import { feedbackSessionFormInFocus } from '../../../actions/feedbackFormInFocusAction';
import { RootState } from '../../../reducers';

// UI
import { FlexboxGrid, Form, InputPicker, Radio, Col, ButtonToolbar, Button } from 'rsuite';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// 3rd Party
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const sessionDetailSchema = yup.object({
	bookingId: yup.number(),
	ratedParticipantId: yup.number(),
	programme: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.number(),
		})
		.nullable()
		.required('This field is required'),
	sessionWith: yup
		.object()
		.shape({
			value: yup.object().shape({
				group_id: yup.number(),
				mentee: yup.object().shape({
					id: yup.number(),
					full_name: yup.string(),
					profile_photo: yup.string().nullable(),
					score: yup.number().nullable(),
				}),
				mentor: yup.object().shape({
					id: yup.number(),
					full_name: yup.string(),
					profile_photo: yup.string().nullable(),
					score: yup.number().nullable(),
				}),
			}),
		})
		.nullable()
		.required('This field is required'),
	sessionType: yup.number().nullable().required('This field is required'),
	sessionDate: yup.date().nullable().required('This field is required'),
	sessionTime: yup.date().nullable().required('This field is required'),
	sessionDuration: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.number(),
		})
		.nullable()
		.required('This field is required'),
	sessionService: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.number(),
		})
		.nullable()
		.required('This field is required'),
});

export default function SessionDetailForm(props: any) {
	const { programData, groupData, handleProgrammeChange, serviceData, sessionDurationData, onSubmit } = props;
	const programAnswer = useSelector((state: RootState) => state.programAnswer);
	const dispatch = useDispatch();
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(sessionDetailSchema),
	});
	const navigate = useNavigate();
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const onError = (errors: any) => {
		console.log('errors: ', errors);
	};

	const routeToSessionMenu = () => {
		dispatch(feedbackSessionFormInFocus(null));
		navigate('../feedback-session');
	};

	// TODO : destructure this function
	const renderSessionDetails = () => {
		return (
			<FlexboxGrid justify="center">
				<FlexboxGrid.Item colspan={21} className="pb-7 pt-6">
					<FlexboxGrid.Item className="select-programme">Select Programme</FlexboxGrid.Item>
					<Controller
						name="programme"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<Autocomplete
								{...field}
								options={programData ? programData : []}
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option) => option.label === option.label}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										fullWidth
										label="Please select programme"
									/>
								)}
								onChange={(_, data) => {
									handleProgrammeChange(data.value);
									field.onChange(data);
								}}
							/>
						)}
					/>
					{errors.programme && <p className="session-details-text-error">{errors.programme.message}</p>}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={21} className="pb-7">
					<FlexboxGrid.Item className="session-with">Who is the mentor / mentee?</FlexboxGrid.Item>
					<Controller
						name="sessionWith"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<Autocomplete
								{...field}
								options={groupData ? groupData : []}
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option) => option.label === option.label}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" fullWidth label="Please select" />
								)}
								onChange={(_, data) => {
									field.onChange(data);
								}}
							/>
						)}
					/>
					{errors.sessionWith && <p className="session-details-text-error">{errors.sessionWith.message}</p>}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={21} className="pb-5">
					<FlexboxGrid.Item className="session-place">Where did the session take place?</FlexboxGrid.Item>
					<Controller
						name="sessionType"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<FlexboxGrid className="radio-button-session-type">
								<Radio {...field} name="sessionType" value={0} checked={field.value === 0}>
									Online
								</Radio>
								<Radio {...field} name="sessionType" value={1} checked={field.value === 1}>
									Physical
								</Radio>
							</FlexboxGrid>
						)}
					/>
					{errors.sessionType && <p className="session-details-text-error">{errors.sessionType.message}</p>}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={21} className="pb-7">
					<FlexboxGrid.Item className="session-date-input">When the session happen?</FlexboxGrid.Item>
					<Controller
						name="sessionDate"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<MobileDatePicker
									{...field}
									value={field.value}
									onChange={(date) => field.onChange(date)}
									label="Select session date"
									format="DD/MM/YYYY"
									className="w-full"
								/>
							</LocalizationProvider>
						)}
					/>
					{errors.sessionDate && <p className="session-details-text-error">{errors.sessionDate.message}</p>}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={21} className="pb-7">
					<FlexboxGrid.Item colspan={12} as={Col} className="pl-0">
						<FlexboxGrid.Item className="select-programme">Session Time</FlexboxGrid.Item>
						<Controller
							name="sessionTime"
							control={control}
							defaultValue={null}
							render={({ field }) => (
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<MobileTimePicker
										{...field}
										value={field.value}
										onChange={(date) => field.onChange(date)}
										label="Select session time"
										className="w-full"
									/>
								</LocalizationProvider>
							)}
						/>
						{errors.sessionTime && (
							<p className="session-details-text-error">{errors.sessionTime.message}</p>
						)}
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={12} as={Col} className="pr-0">
						<FlexboxGrid.Item className="select-programme">Session Duration</FlexboxGrid.Item>
						<Controller
							name="sessionDuration"
							control={control}
							defaultValue={null}
							render={({ field }) => (
								<Autocomplete
									{...field}
									options={sessionDurationData ? sessionDurationData : []}
									getOptionLabel={(option) => option.label}
									getOptionSelected={(option) => option.label === option.label}
									renderInput={(params) => (
										<TextField {...params} variant="outlined" fullWidth label="Please select" />
									)}
									onChange={(_, data) => {
										field.onChange(data);
									}}
								/>
							)}
						/>
						{errors.sessionDuration && (
							<p className="session-details-text-error">{errors.sessionDuration.message}</p>
						)}
					</FlexboxGrid.Item>
				</FlexboxGrid.Item>

				<FlexboxGrid.Item colspan={21} className="pb-7">
					<FlexboxGrid.Item className="select-programme">Mentor Services</FlexboxGrid.Item>
					<Controller
						name="sessionService"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<Autocomplete
								{...field}
								options={serviceData ? serviceData : []}
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option) => option.label === option.label}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" fullWidth label="Please select" />
								)}
								onChange={(_, data) => {
									field.onChange(data);
								}}
							/>
						)}
					/>
					{errors.sessionService && (
						<p className="session-details-text-error">{errors.sessionService.message}</p>
					)}
				</FlexboxGrid.Item>
			</FlexboxGrid>
		);
	};

	return (
		<FlexboxGrid>
			{getWindowSize(windowSize.width) === windowSizeType.XS ? (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 border-b border-gray-200">
						<FlexboxGrid.Item colspan={5} as={Col} onClick={routeToSessionMenu} className="text-center">
							<ArowBackIcon className="w-6 h-6" />
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={19} as={Col} className="session-detail">
							Session Details
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="feedback-session-room-mobile">
						{renderSessionDetails()}
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={21} className="pb-5">
								<ButtonToolbar>
									<Button block appearance="primary" onClick={handleSubmit(onSubmit, onError)}>
										Continue
									</Button>
								</ButtonToolbar>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			) : (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="pb-5 pt-4 border-b border-gray-200">
						<FlexboxGrid.Item colspan={16} as={Col} className="session-detail-desktop">
							Session Details
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={8} as={Col}>
							<ButtonToolbar>
								<Button block appearance="primary" onClick={handleSubmit(onSubmit, onError)}>
									Continue
								</Button>
							</ButtonToolbar>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="feedback-session-room-desktop">
						{renderSessionDetails()}
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</FlexboxGrid>
	);
}
