// React
import React from 'react';

// UI
import { Button, ButtonToolbar, IconButton } from 'rsuite';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// 3rd Party
import _ from 'lodash';

declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		size?: string;
	}
}

export default function SignWithLinkedInButtonComponent() {
	// set up the path to the linkedin auth page
	const linkedInAuth = () => {
		const linkedInAuthUrl = `${process.env.REACT_APP_LINKEDIN_AUTH_URL}`;

		const generateRandomToken = () => {
			return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		};

		const options = {
			redirect_uri: process.env.REACT_APP_LINKEDIN_AUTH_REDIRECT_URI as string,
			client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID as string,
			state: generateRandomToken(),
			response_type: 'code',
			scope: process.env.REACT_APP_LINKEDIN_SCOPE as string,
		};

		const qs = new URLSearchParams(options);

		return `${linkedInAuthUrl}?${qs.toString()}`;
	};

	return (
		<ButtonToolbar>
			<IconButton
				icon={<LinkedInIcon />}
				circle
				className="linkedin-sign-in-button"
				appearance="link"
				size="lg"
				href={linkedInAuth()}
			/>
		</ButtonToolbar>
	);
}
