// React
import { useState } from 'react';
import { useSelector } from 'react-redux';

// UI
import { Form, FlexboxGrid, Col, IconButton, Uploader, toaster, Message } from 'rsuite';
import SendIcon from '@mui/icons-material/Send';
import AttachmentIcon from '@mui/icons-material/Attachment';

// FutureLab
import InputField from '../common/InputField';
import { useSocket } from '../../hooks/useSocket';
import { LoggedInUserDetails, RootState } from '../../reducers';
import { uploadAttachment } from '../../services/chatService';
import { FileAttachmentInterface } from './ChatRoom';

// 3rd Party
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { UserProfileDetails } from '../../pages';
import { store } from '../../ReduxRoot';

interface SendChatForm {
	chatMessage?: string;
}

interface SendChatMessageProps {
	updateSeenMessages: () => void;
	files: any;
	setFiles: any;
	loggedInUserDetails: LoggedInUserDetails;
}

export function SendChatMessage(props: SendChatMessageProps) {
	const { updateSeenMessages, files, setFiles, loggedInUserDetails } = props;
	const socket = useSocket();
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const [formValues, setFormValues] = useState<SendChatForm>({
		chatMessage: '',
	});

	const chatSenderUserId = chatInFocus.participants.find(
		(participant) => participant.userId === loggedInUserDetails.id
	);

	const chatSender: UserProfileDetails = store
		.getState()
		.userListProfilesDetails.find(
			(participant: UserProfileDetails) => participant?.id === chatSenderUserId?.userId
		);

	const chatSenderFullName = `${chatSender?.firstname} ${chatSender?.lastname ?? ''}`;

	const formValue = (data: SendChatForm) => {
		setFormValues(data);
	};

	const handleSubmit = () => {
		mixpanel.track('Chat Message Send', {
			'Message Send': formValues.chatMessage,
			Attachment: !_.isEmpty(files),
			'Sender Name': chatSenderFullName,
			'Sender Id': chatSender.id,
			'Chat Room Id': chatInFocus.id,
			'Is Group': `${chatInFocus.isGroupChat}`,
			'Group Name': chatInFocus.name,
		});
		const formData = new FormData();

		files.forEach((file: any) => {
			formData.append('attachment', file.blobFile);
		});

		if (_.isEmpty(files)) {
			socket.emit('SEND_MESSAGE', { chatId: chatInFocus.id, message: formValues.chatMessage });
			setFormValues({ chatMessage: '' });
		} else if (!_.isEmpty(files)) {
			uploadAttachment(formData)
				.then((res) => {
					let newChatMessage = formValues.chatMessage;

					res.data.data.map((info: any) => {
						if (newChatMessage !== '') {
							let chatMessageObject = {
								chatId: chatInFocus.id,
								message: newChatMessage,
								attachment: {
									key: info.signedUrl,
									fileName: info.fileName,
									mimeType: info.fileMimeType,
								},
							};
							socket.emit('SEND_MESSAGE', chatMessageObject);
							newChatMessage = '';
						} else {
							let chatMessageObject = {
								chatId: chatInFocus.id,
								attachment: {
									key: info.signedUrl,
									fileName: info.fileName,
									mimeType: info.fileMimeType,
								},
							};
							socket.emit('SEND_MESSAGE', chatMessageObject);
						}
					});
					setFormValues({ chatMessage: '' });
					setFiles([]);
				})
				.catch((error) => {
					if (error.data.message === 'LIMIT_FILE_SIZE') {
						return toaster.push(
							<Message type="error" showIcon>
								Upload size cannot exceed 5 mb
							</Message>,
							{ placement: 'bottomCenter' }
						);
					} else if (error.data.message === 'LIMIT_FILE_COUNT') {
						return toaster.push(
							<Message type="error" showIcon>
								Attachment cannot exceed 4
							</Message>,
							{ placement: 'bottomCenter' }
						);
					}
					console.log('error: ', error);
				});
		}
	};

	const handleFileUpload = (files: any) => {
		setFiles(files);
	};

	return (
		<>
			<FlexboxGrid.Item colspan={24} as={Col} xs={16} md={18} lg={19}>
				<Form fluid onChange={formValue} onSubmit={handleSubmit}>
					<InputField
						onClickMethod={updateSeenMessages}
						name={'chatMessage'}
						placeholder={'Type something'}
						setControlValue={formValues.chatMessage}
					></InputField>
				</Form>
			</FlexboxGrid.Item>
			<FlexboxGrid.Item as={Col} xs={4} md={3} lg={2}>
				<Uploader
					autoUpload={false}
					fileList={files}
					action=""
					onChange={handleFileUpload}
					fileListVisible={false}
					multiple
					className="attachment-icon-button-message-room"
				>
					<IconButton icon={<AttachmentIcon />} style={{ width: '100%' }} />
				</Uploader>
			</FlexboxGrid.Item>
			<FlexboxGrid.Item as={Col} xs={4} md={3} lg={3}>
				<IconButton
					icon={<SendIcon className="send-message-icon-message-room" />}
					as={Col}
					xs={24}
					md={24}
					lg={24}
					onClick={handleSubmit}
					appearance="primary"
				/>
			</FlexboxGrid.Item>
		</>
	);
}
