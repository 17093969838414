// Axios
import { AxiosResponse } from 'axios';

// Future Lab
import { axiosInstanceRails } from '../axiosInstance';

const railsAuthBaseUrl = `${process.env.REACT_APP_STAGING_BASE_URL}api/`;

/**
 * get user info
 *
 * @param  {object} config
 * @returns Promise
 */
export function userDetailsEndPoint(userId: number): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(`${railsAuthBaseUrl}v1/users/${userId}`);
}

/**
 * get list of services for booking
 *
 * @param  {object} config
 * @returns Promise
 */
export function listOfServices(): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(`${railsAuthBaseUrl}v1/services`);
}

/**
 * get list of groups in a programs
 *
 * @param  {object} config
 * @returns Promise
 */
export function listOfGroupInPrograms(programId: number): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(`${railsAuthBaseUrl}portal/v1/mentorship_ratings/main?program_id=${programId}`);
}

/**
 * get program questions
 *
 * @param  {object} config
 * @returns Promise
 */
export function programQuestions(programId: number, userType: string): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(
		`${railsAuthBaseUrl}portal/v1/program/review_questions?program_id=${programId}&user_type=${userType}`
	);
}

/**
 * submit session feedback
 *
 * @param  {FormData} formData
 * @returns Promise
 */
export function submitOfflineFeedback(formData: FormData): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.post(`${railsAuthBaseUrl}portal/v1/feedback_management/offline_submit`, formData);
}

/**
 * get unrated and rated session (only for programs)
 *
 * @param  {object} config
 * @returns Promise
 */
export function getProgramSession(userId: number): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(`${railsAuthBaseUrl}portal/v1/participant_bookings?user_id=${userId}`);
}

/**
 * submit feedback for unrated session (only for programs)
 *
 * @param  {FormData} formData
 * @returns Promise
 */
export function submitSessionFeedback(bookingId: number, formData: FormData): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.post(`${railsAuthBaseUrl}v1/bookings/${bookingId}/reviews`, formData);
}

/**
 * submit feedback for unrated session (only for programs)
 *
 * @param  {object} config
 * @returns Promise
 */
export function getSessionFeedbackAnswer(bookingId: number): Promise<AxiosResponse<any>> {
	return axiosInstanceRails.get(
		`${railsAuthBaseUrl}portal/v1/mentorship_ratings/booking_reviews?booking_id=${bookingId}`
	);
}
