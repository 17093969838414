// Future Lab
import { LoggedInUserActions, UserAction } from '../model/index';
import createReducer from './createReducer';

export const userLoggedIn = createReducer<boolean>(false, {
	[LoggedInUserActions.LOGIN_USER](state: boolean, action: UserAction) {
		return (state = action.payload);
	},
	[LoggedInUserActions.RESGISTER_USER](state: boolean, action: UserAction) {
		return (state = action.payload);
	},
});
