// React
import React from 'react';
import { useSelector } from 'react-redux';

// UI
import { FlexboxGrid } from 'rsuite';

// FutureLab
import { ChatListItemImage } from './chatList/ChatListItemImage';
import { ChatListItemNameAndTime } from './chatList/ChatListItemNameAndTime';
import { ChatListItemUnreadCount } from './chatList/ChatListItemUnreadCount';

// Reducer
import { RootState } from '../../reducers';
import { navigateToChatRoom } from '../../utilities/Chat';

export interface ChatListDataInterface {
	id: number;
	_id?: number;
	isGroupChat: boolean;
	description?: string;
	chatAdmins?: ChatParticipantsInterface[];
	programId?: number;
	latestAttachment: string[];
	latestMessage: string;
	latestMessageDateTime: string;
	name: string;
	unreadCount: number;
	participants: ChatParticipantsInterface[];
	pendingApprovalParticipants: ChatParticipantsInterface[];
	createdAt?: Date;
}

export interface ChatListItemsInterface {
	chatListItem: ChatListDataInterface;
	chatListParticipants?: any[];
	targetRef: any;
}

export interface ChatParticipantsInterface {
	_id: number;
	userId: number;
	isAdmin: boolean;
}

export function ChatListItemsMobile({ chatListItem, targetRef }: ChatListItemsInterface) {
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);

	return (
		<div className="px-3.5 py-4 border-b border-solid">
			<FlexboxGrid ref={targetRef} onClick={() => navigateToChatRoom(chatListItem, '../chat/chat-room')}>
				<FlexboxGrid.Item colspan={24}>
					{/* image */}
					<ChatListItemImage
						chatListItem={chatListItem}
						loggedInUserDetails={loggedInUserDetails}
						userListProfilesDetails={userListProfilesDetails}
					/>
					{/* content */}
					<ChatListItemNameAndTime
						chatListItem={chatListItem}
						loggedInUserDetails={loggedInUserDetails}
						userListProfilesDetails={userListProfilesDetails}
					/>
					<ChatListItemUnreadCount chatListItem={chatListItem} />
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</div>
	);
}
