import { initializeApp } from 'firebase/app';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyCmw2eqxLgAiCuWB2dxkOkvZESzWhw0vAU',
	authDomain: 'futurelab-messaging-app.firebaseapp.com',
	projectId: 'futurelab-messaging-app',
	storageBucket: 'futurelab-messaging-app.appspot.com',
	messagingSenderId: '314357848790',
	appId: '1:314357848790:web:87a9c3def10b03e71fc777',
	measurementId: 'G-MHXK94NCJ9',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const messaging = getMessaging(firebase);

export const getFCMToken = async (setToken: any) => {
	try {
		const currentToken = await getToken(messaging, {
			vapidKey: 'BJjZFPewjK7erDBtcwoDJP-9f06qmdQ3VWMtAJuCeAwOYRCnXDs5SWY-58Wpg1yKh8dfHK4lRdcb_5Ca9eYiCHY',
		});
		if (currentToken) {
			Notification.requestPermission().then((permission) => {
				if (permission === 'granted') {
					// TODO: add the token to the backend
					setToken(currentToken);
				}
			});
		} else {
			// Show permission request UI
			console.log('No registration token available. Request permission to generate one.');
			// ...
		}
	} catch (err: any) {
		console.log('An error occurred while retrieving token. ', err);
	}
};

export const messageListener = () => {
	return new Promise((resolve) => {
		onMessage(messaging, (payload: any) => {
			//check if user is on the page
			// if (document.visibilityState === 'hidden') {
			// 	//show notification
			// 	const notificationOptions = {
			// 		body: payload.data.body,
			// 		icon: './favicon.ico',
			// 		vibration: [200, 100, 200, 100, 200, 100, 200],
			// 		data: {
			// 			url: payload.data.link,
			// 		},
			// 	};
			// 	const notification = new Notification(payload.data.title, notificationOptions);
			// 	notification.onclick = (e) => {
			// 		e.preventDefault();
			// 		notification.close();
			// 	};
			// 	//show notification
			// 	if (Notification.permission === 'granted') {
			// 		navigator.serviceWorker.getRegistration().then((reg) => {
			// 			reg?.showNotification(payload.data.title, notificationOptions);
			// 		});
			// 	}
			// }
			if (navigator.vibrate) {
				navigator.vibrate([200, 100]);
			}
			resolve(payload);
		});
	});
};

export default firebase;
