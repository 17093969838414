// FutureLab
import { ChatListDataInterface } from '../ChatListItemsMobile';

// UI
import { FlexboxGrid, Col } from 'rsuite';
import Badge from '@mui/material/Badge';
import AttachmentIcon from '@rsuite/icons/Attachment';
import _ from 'lodash';

interface ChatListItemUnreadCountProps {
	chatListItem: ChatListDataInterface;
}

export function ChatListItemUnreadCount(chatListItemUnreadCountProps: ChatListItemUnreadCountProps) {
	const { chatListItem } = chatListItemUnreadCountProps;

	return (
		<FlexboxGrid.Item colspan={24}>
			<FlexboxGrid>
				<FlexboxGrid.Item
					className="truncate pl-3 text-sm text-gray-500"
					colspan={21}
					as={Col}
				>
					{!_.isEmpty(chatListItem?.latestAttachment?.[0]) && chatListItem.latestMessage !== '' ? (
						<>
							<AttachmentIcon className="mb-0.5" />
							<span className="pl-1.5">{chatListItem?.latestMessage}</span>
						</>
					) : (
						<>
							{!_.isEmpty(chatListItem?.latestAttachment?.[0]) ? (
								<>
									<AttachmentIcon className="mb-0.5" />
									<span className="pl-1.5">Attachment</span>
								</>
							) : (
								<>{chatListItem?.latestMessage}</>
							)}
						</>
					)}
				</FlexboxGrid.Item>
				{chatListItem.unreadCount > 0 ? (
					<FlexboxGrid.Item colspan={2} className="text-right">
						<Badge badgeContent={chatListItem.unreadCount} color="error" variant="standard"></Badge>
					</FlexboxGrid.Item>
				) : (
					''
				)}
			</FlexboxGrid>
		</FlexboxGrid.Item>
	);
}
