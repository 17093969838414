// Future Lab
import { LoaderActionTypes, LoaderActions } from '../model/index';

export function showLoaderAction(): LoaderActions {
	return {
		type: LoaderActionTypes.SHOW_LOADER,
		payload: true,
	};
}

export function hideLoaderAction(): LoaderActions {
	return {
		type: LoaderActionTypes.HIDE_LOADER,
		payload: false,
	};
}
