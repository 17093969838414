// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { FlexboxGrid, toaster, Message } from 'rsuite';
import { Group } from '@material-ui/icons';
import SearchBar from 'material-ui-search-bar';

// FutureLab
import { getListOfUser } from '../services/userService';
import { createPrivateGroupChat } from '../services/chatService';
import { chatItemInFocus } from '../actions/chatItemInFocusActions';

// Reducer
import { store } from '../ReduxRoot';

export interface UserProfileDetails {
	id: number;
	email: string;
	profile_photo: string;
	firstname: string;
	lastname: string;
	userRoles: UserRolesInterface[];
	userInfos: UserInfosInterface;
}

export interface UserRolesInterface {
	active: boolean;
	createdAt: Date;
	deactivatedAt: Date;
	description: string;
	id: number;
	name: string;
	programId: number;
	updatedAt: Date;
}

export interface UserInfosInterface {
	userId: number;
	id: number;
	jobTitle: string;
	company: string;
	gender: number;
	birthday: Date;
}

export function AddChatPage() {
	const [userList, setUserList] = useState<UserProfileDetails[]>([]);
	const [filterQuery, setFilterQuery] = useState('');
	const navigate = useNavigate();
	const filterObject = {
		filer: {
			firstName: '',
			lastName: '',
			email: '',
		},
	};

	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;

	const getGeryResults = () => {
		return userList.filter((user) => user.email.includes(filterQuery));
	};

	const createPrivateGroupChatAndRoute = (userId: number): void => {
		createPrivateGroupChat({ recipientUserId: userId })
			.then((res) => {
				store.dispatch(chatItemInFocus(res?.data?.data));
			})
			.then(() => {
				navigate('../chat/chat-room');
			})
			.catch((err) => {
				toaster.push(
					<Message type="error" showIcon>
						Chat Already Exists
					</Message>,
					{ placement: 'bottomCenter' }
				);
			});
	};

	useEffect(() => {
		getListOfUser(filterObject)
			.then((res) => {
				setUserList(res.data.data);
			})
			.catch((err) => {
				console.warn('Error:', err);
			});
	}, []);

	return (
		<div className="add-group-chat">
			<FlexboxGrid className="new-group-user-button" onClick={() => navigate('../chat/add-group-chat')}>
				<FlexboxGrid.Item colspan={3}>
					<div className="chat-list-photo-holder">
						<Group className="chat-list-image-holder group-chat-list-image-holder"></Group>
					</div>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={21} className="add-chatlist-one-to-one-title">
					New Group
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<div className="search-chat-bar">
				<SearchBar placeholder="Search" value={''} onChange={(newValue) => setFilterQuery(newValue)} />
			</div>
			<div className="vertical-scroll-enabled-chat-page">
				{getGeryResults().map((user, index) => {
					return (
						<div
							key={index}
							className="user-list-row"
							onClick={() => createPrivateGroupChatAndRoute(user.id)}
						>
							<FlexboxGrid>
								<FlexboxGrid.Item colspan={3}>
									<div className="chat-list-photo-holder">
										<img
											src={`${photoUrl}/${user.id}/${user.profile_photo}`}
											className="chat-list-image-holder"
										/>
									</div>
								</FlexboxGrid.Item>
								<FlexboxGrid.Item colspan={21} className="add-chatlist-one-to-one-title">
									<div>{user.email}</div>
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</div>
					);
				})}
			</div>
		</div>
	);
}
