// React
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { Divider, Container, FlexboxGrid, Button, ButtonToolbar } from 'rsuite';

// Future Lab
import { getRailsCookies } from '../services/userService';
import Logo from '../components/common/Logo';
import LoginFormComponent from '../components/auth/LoginForm';
import SignWithGoogleButtonComponent from '../components/auth/SignWithGoogleButton';
import SignWithLinkedInButtonComponent from '../components/auth/SignWithLinkedInButton';
import SignWithFacebookButtonComponent from '../components/auth/SignWithFacebookButton';

// 3rd party
import Cookies from 'js-cookie';

export function LoginPage() {
	const signUpURL = `${process.env.REACT_APP_MENTEE_SIGN_UP_BASE_URL}mentees/sign_up`;
	const navigateToSignUpPage = () => {
		window.open(`${signUpURL}`, '_blank');
	};
	const pwaCookie = process.env.PWA_COOKIE_NAME || 'pwa_staging_session';
	const navigate = useNavigate();

	let storedPathName = localStorage.getItem('redirectTo');

	useEffect(() => {
		if (!Cookies.get('token')) {
			getRailsCookies()
				.then((res) => {
					Cookies.set('token', res.data?.data?.accessToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
					Cookies.set(pwaCookie, res.data?.data?.railsToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
					Cookies.set('refreshToken', res.data?.data?.refreshToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
					if (!storedPathName || storedPathName === '/login') {
						storedPathName = '/';
					}
					navigate(storedPathName);
					localStorage.removeItem('redirectTo');
				})
				.catch((e) => {});
		}
	}, []);

	return (
		<Container className="w-full max-w-sm">
			<FlexboxGrid justify="center" align="middle">
				<FlexboxGrid.Item colspan={20}>
					<Logo />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={24} className="sign-in-form">
					<h3 className="log-in-text">Log in</h3>
					{/* enable once the signups are enabled on the new application */}
					<LoginFormComponent />
					<FlexboxGrid.Item colspan={24} className="alternate-sign-in-option">
						<Divider>Or sign in with</Divider>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item className="social-login-button-container">
						<SignWithLinkedInButtonComponent />
						<SignWithGoogleButtonComponent />
						<SignWithFacebookButtonComponent />
					</FlexboxGrid.Item>
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<FlexboxGrid.Item colspan={24} className="account-creation-text">
				<span>Don’t have an account?</span>
				{/* <ButtonToolbar> */}
				<Button appearance="link" onClick={navigateToSignUpPage /* history.push(router.signup().$) */}>
					Create an account.
				</Button>
				{/* </ButtonToolbar> */}
			</FlexboxGrid.Item>
		</Container>
	);
}
