// React
import { useNavigate } from 'react-router-dom';

//UI
import { Nav } from 'rsuite';

interface DesktopNavItemType {
	icon?: JSX.Element;
	method?: Function;
	routerPath?: string;
}

export default function DesktopNavItem(props: DesktopNavItemType) {
	const { routerPath, icon, method } = props;
	const navigate = useNavigate();

	const onClickMethods = () => {
		if (!!method) {
			method();
			return;
		}
		if (routerPath !== undefined) {
			navigate(routerPath);
		}
	};

	return <Nav.Item className="h-full" icon={icon} onClick={onClickMethods} />;
}
