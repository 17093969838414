// React
import { useNavigate } from 'react-router-dom';

// Images
import Logo from '../../assets/image/fl_logo_main.svg';

export default function CompanyLogo() {
	const navigate = useNavigate();
	return <img src={Logo} alt="mobile-logo" /* onClick={() => navigate('..')}  *//>;
}
