// React
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { windowSizeType, getWindowSize } from '../../utilities/WindowResize';
import RequiredSession from './RequiredSession';
import SubmittedSession from './SubmittedSession';
import { getProgramSession } from '../../services/railsService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getUserListsDetails } from '../../services/userService';
import { userListDetails } from '../../actions/userListDetailsActions';

// UI
import { Col, FlexboxGrid } from 'rsuite';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArowBackIcon from '@rsuite/icons/ArowBack';

export interface FeedbackSessionInterface {
	userName: string;
	profession: string;
	company: string;
	programName: string;
	sessionDate: string;
}

export interface SessionInterface {
	unrated: SessionsDetailsInterface[];
	rated: SessionsDetailsInterface[];
}

export interface SessionsDetailsInterface {
	booking_date: string;
	booking_end_time: string;
	booking_participants: { id: number; full_name: string; user_id: number }[];
	booking_start_time: string;
	booking_time: string;
	mentee_id: number;
	mentor_id: number;
	group_details: {
		group_id: number;
		mentee: { full_name: string; id: number };
		mentor: { full_name: string; id: number };
	};
	id: number;
	program: { label: string; value: number };
	service: { label: string; value: number };
	session_method: string;
}

export default function PreviousSession() {
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const loggedInUserDetail = useSelector((state: RootState) => state.loggedInUserDetails);
	const [value, setValue] = useState('1');
	const [sessionInfo, setSessionInfo] = useState<SessionInterface>({ unrated: [], rated: [] });
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	async function fetchData() {
		const userId = loggedInUserDetail.id;
		const res = await getProgramSession(userId);
		if (res?.data) {
			setSessionInfo(res.data);
			const filteredIds: number[] = [];
			res.data.unrated?.forEach(({ mentor_id, mentee_id }: SessionsDetailsInterface) => {
				filteredIds.push(mentor_id !== userId ? mentor_id : mentee_id);
			});
			res.data.rated?.forEach(({ mentor_id, mentee_id }: SessionsDetailsInterface) => {
				filteredIds.push(mentor_id !== userId ? mentor_id : mentee_id);
			});
			const userListDetailsData = (await getUserListsDetails({ userIds: filteredIds })).data?.data;
			dispatch(userListDetails(userListDetailsData));
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<FlexboxGrid>
			{getWindowSize(windowSize.width) === windowSizeType.XS ? (
				<FlexboxGrid.Item colspan={24} className="pb-5 pt-6 border-b border-gray-200">
					<FlexboxGrid.Item
						colspan={5}
						as={Col}
						onClick={() => navigate('../feedback-session')}
						className="text-center"
					>
						<ArowBackIcon className="w-6 h-6" />
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={19} as={Col} className="session-feedback">
						Previous Session
					</FlexboxGrid.Item>
				</FlexboxGrid.Item>
			) : (
				''
			)}
			<FlexboxGrid.Item colspan={24}>
				<TabContext value={value}>
					<Box sx={{ borderColor: 'divider' }}>
						<TabList onChange={handleChange} className="pt-2">
							<Tab label="Required" value="1" className="required-session" />
							<Tab label="Submitted" value="2" className="submitted-session" />
						</TabList>
					</Box>
					<TabPanel
						value="1"
						className={
							getWindowSize(windowSize.width) === windowSizeType.XS
								? 'required-session-tab'
								: 'required-session-desktop-tab'
						}
					>
						<RequiredSession
							sessionInfo={sessionInfo}
							loggedInUserDetail={loggedInUserDetail}
							userListProfilesDetails={userListProfilesDetails}
						/>
					</TabPanel>
					<TabPanel
						value="2"
						className={
							getWindowSize(windowSize.width) === windowSizeType.XS
								? 'required-session-tab'
								: 'required-session-desktop-tab'
						}
					>
						<SubmittedSession
							sessionInfo={sessionInfo}
							loggedInUserDetail={loggedInUserDetail}
							userListProfilesDetails={userListProfilesDetails}
						/>
					</TabPanel>
				</TabContext>
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
