// Redux
import { combineReducers } from 'redux';

// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { UserProfileDetails } from '../pages/AddChatPage';
import { PinnedMessageInterface } from '../components/chat/ChatRoom';
import { ProgramQuestionInterface, SessionFeedbackAnswerInterface } from '../components/feedback/SessionFeedback';

// FutureLab reducers
import * as userReducer from './userLoginReducer';
import * as loaderReducer from './loaderReducer';
import * as userListProfilesDetailsReducer from './userListProfilesDetailsReducer';
import * as chatItemInFocusReducer from './chatItemInFocusReducer';
import * as loggedInUserDetailsReducer from './loggedInUserDetailsReducer';
import * as pinnedMessages from './pinnedMessagesReducer';
import * as pendingApprovalChat from './pendingApprovalReducer';
import * as pinnedMessageInfocus from './pinnedChatMessageInFocus';
import * as pushNotifReducer from './pushNotificationReducer';
import * as feedbackFormInFocus from './feedbackFormInFocusReducer';
import * as programQuestion from './feedbackQuestionReducer';
import * as programAnswer from './feedbackAnswerReducer';
import * as chatList from './chatListReducer';

export interface RootState {
	userLoggedIn: any;
	showLoader: boolean;
	userListProfilesDetails: UserProfileDetails[];
	loggedInUserDetails: LoggedInUserDetails;
	chatInFocus: ChatListDataInterface;
	pendingApprovalChatList: ChatListDataInterface[];
	chatList: ChatListDataInterface[];
	pinnedMessageInfocus: PinnedMessageInterface;
	feedbackFormInFocus: number;
	programQuestion: ProgramQuestionInterface[];
	programAnswer: SessionFeedbackAnswerInterface;
}

export interface LoggedInUserDetails {
	id: number;
	email: string;
	exp: number;
	iat: number;
}

export default () =>
	combineReducers({
		...userReducer,
		...loaderReducer,
		...userListProfilesDetailsReducer,
		...chatItemInFocusReducer,
		...chatList,
		...loggedInUserDetailsReducer,
		...pinnedMessages,
		...pendingApprovalChat,
		...pinnedMessageInfocus,
		...pushNotifReducer,
		...feedbackFormInFocus,
		...programQuestion,
		...programAnswer,
	});
