// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { windowSizeType, getWindowSize } from '../utilities/WindowResize';

// UI
import { Button, FlexboxGrid } from 'rsuite';

export function NotFoundPage() {
	const navigate = useNavigate();
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		// TODO find the fix for the memory leak for the the unmounted component load issue on the react dom side
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const checkWindowSize = (primary: string, alternative: string) => {
		return getWindowSize(windowSize.width) === windowSizeType.XS ? primary : alternative;
	};

	return (
		<FlexboxGrid className="error-page-container">
			<div className="planet-mars">
				<img src="https://futurelab.global/images/mars.svg" />
			</div>
			<FlexboxGrid.Item className="justify-center text-center">
				<div className="mt-32">
					<span className={checkWindowSize('error-code-mobile', 'error-code')}>
						4
						<img
							src="https://futurelab.global/images/earth.svg"
							alt="Earth"
							className={checkWindowSize('planet-earth-mobile', 'planet-earth')}
						/>
						4
					</span>
				</div>
				<div
					className={checkWindowSize(
						'text-white text-xl font-medium',
						'text-white text-2xl font-semibold tracking-widest'
					)}
				>
					PAGE NOT FOUND
				</div>
				<Button onClick={() => navigate('/')} appearance="link" className="mt-6 text-lg">
					Go to Home Page
				</Button>
			</FlexboxGrid.Item>
			<div className="flying-astronaut">
				<img src="https://futurelab.global/images/flying_astronaut.svg" alt="Flying Astronaut" />
			</div>
		</FlexboxGrid>
	);
}
