import React, { useContext, FC } from 'react';
import io, { Socket } from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_CHAT_API_BASE_URL}`, {
	transports: ['websocket'],
});

const SocketContext = React.createContext<Socket>(socket);

export const useSocket = () => {
	return useContext(SocketContext);
};

interface Props {
	// any props that come into the component
}

export const SocketProvider: FC<Props> = ({ children }) => {
	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
