// React
import React from 'react';

// UI
import { Button, ButtonToolbar, IconButton } from 'rsuite';

declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		size?: string;
	}
}

export default function SignWithGoogleButtonComponent() {
	// set up the path to the google auth page
	const googleAuth = () => {
		const googleAuthUrl = `${process.env.REACT_APP_GOOGLE_AUTH_URL}`;

		const options = {
			redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI as string,
			client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
			access_type: 'offline',
			response_type: 'code',
			prompt: 'consent',
			scope: [
				'https://www.googleapis.com/auth/userinfo.email',
				'https://www.googleapis.com/auth/userinfo.profile',
			].join(' '),
		};

		const qs = new URLSearchParams(options);

		return `${googleAuthUrl}?${qs.toString()}`;
	};

	return (
		<ButtonToolbar>
			<a href={googleAuth()}>
				<Button className="google-sign-in-button">
					<img src="image/google_g_icon.svg" />
				</Button>
			</a>
		</ButtonToolbar>
	);
}
