// FutureLab
import { returnOneToOneChatUserDetails } from '../../../utilities/UserDetails';
import { ChatListDataInterface } from '../ChatListItemsMobile';
import { LoggedInUserDetails } from '../../../reducers/index';
import { UserProfileDetails } from '../../../pages/AddChatPage';

// UI
import { FlexboxGrid, Col } from 'rsuite';

// 3rd Part
import moment from 'moment';

interface ChatListItemNameAndTimeProps {
	chatListItem: ChatListDataInterface;
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
}

export function ChatListItemNameAndTime(chatListItemNameProps: ChatListItemNameAndTimeProps) {
	const { chatListItem, loggedInUserDetails, userListProfilesDetails } = chatListItemNameProps;

	const returnFormattedTime = (time: string) => {
		return moment(time).format(moment(time).isSame(moment(), 'day') ? 'h:mma' : 'h:mma, D/M');
	};

	const getGroupChatNameFromParticipantNames = (): string => {
		const groupParticipantIds = chatListItem.participants.map((participant) => participant.userId);
		const userListProfilesFullDetails = userListProfilesDetails.filter((userListProfileDetails) =>
			groupParticipantIds.includes(userListProfileDetails.id)
		);
		let finalGroupName = '';
		userListProfilesFullDetails.map(
			(userListProfileFullDetails, index) =>
				(finalGroupName += `${index > 0 ? ',' : ''} ${userListProfileFullDetails.firstname}`)
		);
		return finalGroupName;
	};

	const getGroupChatName = (chatListItem: ChatListDataInterface): string => {
		const nameFromDb = chatListItem.name;
		return nameFromDb ? nameFromDb : getGroupChatNameFromParticipantNames();
	};

	return (
		<FlexboxGrid>
			<FlexboxGrid.Item
				colspan={18}
				as={Col}
				className="capitalize truncate text-base text-gray-900 pl-3 pb-1 font-medium"
			>
				{chatListItem.isGroupChat
					? getGroupChatName(chatListItem)
					: `${
							returnOneToOneChatUserDetails(chatListItem, loggedInUserDetails, userListProfilesDetails)
								?.firstname
					  } ${
							returnOneToOneChatUserDetails(chatListItem, loggedInUserDetails, userListProfilesDetails)
								?.lastname ?? ''
					  }`}
			</FlexboxGrid.Item>
			<FlexboxGrid.Item colspan={6} className="text-gray-600 text-xxs text-right">
				{returnFormattedTime(chatListItem?.latestMessageDateTime)}
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
