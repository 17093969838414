//React
import React, { useState } from 'react';

// Futurelab
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { getProgramDetails, getUserListsDetails } from '../../services/userService';
import { ProgramDetailsInterface } from '../../model/exportTypeUtil';
import { UserProfileDetails } from '../../pages/AddChatPage';
import avatarPlaceholder from '../../assets/image/avatar_placeholder.png'

// UI
import { Modal, Row, Col } from 'rsuite';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import EastIcon from '@mui/icons-material/East';
import Avatar from '@mui/material/Avatar';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';

interface GroupDetailsItemPopup {
	displayGroupDetails: any;
	setDisplayGroupDetails: any;
	getGroupChatNameInitials: any;
	getGroupChatName: any;
}

function GroupDetails(GroupDetailsItemPopup: GroupDetailsItemPopup) {
	const { displayGroupDetails, setDisplayGroupDetails, getGroupChatNameInitials, getGroupChatName } =
		GroupDetailsItemPopup;
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const handleClose = () => setDisplayGroupDetails(!displayGroupDetails);
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;
	const [programDetails, setProgramDetails] = useState<ProgramDetailsInterface | null>(null);
	const [chatListParticipantsDetails, setChatListParticipantsDetails] = useState<UserProfileDetails[]>([]);

	React.useEffect(() => {
		const fetchGroupChatModalData = async () => {
			try {
				const usersDetails = await getUserListsDetails({ userIds: listOfrecipientDetails });
				const programDetails = await getProgramDetails(chatInFocus.programId);
				setProgramDetails(programDetails.data.data);
				setChatListParticipantsDetails(usersDetails.data.data);
			} catch (error) {
				console.error(error);
			}
		};

		const listOfrecipientDetails = chatInFocus?.participants
			// commenting out this line so that we can get the details of the logged in user as well
			// .filter((participant) => participant.userId !== loggedInUserDetails.id)
			.map(({ userId }) => {
				return userId;
			});
		fetchGroupChatModalData();
	}, [chatInFocus]);

	const isAdmin = (chatListParticipantDetails: UserProfileDetails): boolean | undefined => {
		const targetUser = chatInFocus?.participants.find(
			(participant) => participant.userId === chatListParticipantDetails.id
		);
		return targetUser?.isAdmin;
	};

	const returnUserRoleInGroupChat = (chatListParticipantDetails: UserProfileDetails): string => {
		const userRoleTarget = chatListParticipantDetails.userRoles.find(
			(userRole) => userRole.programId === programDetails?.id
		);
		return userRoleTarget?.description || '';
	};

	const goToProgram = () => {
		if (programDetails?.programEnterprises?.slug && programDetails?.slug) {
			window.open(
				`${process.env.REACT_APP_PORTAL_BASE_URL}${programDetails?.programEnterprises?.slug}/${programDetails?.slug}`,
				'_blank'
			);
		}
	};

	const displayAdminMembersList = () => {
		const chatAdmins = chatListParticipantsDetails.filter((chatListParticipantDetails) =>
			isAdmin(chatListParticipantDetails)
		);
		return renderMembersList(chatAdmins);
	};

	const displayNonAdminMembersList = () => {
		const chatNonAdmins = chatListParticipantsDetails.filter(
			(chatListParticipantDetails) => !isAdmin(chatListParticipantDetails)
		);
		return renderMembersList(chatNonAdmins);
	};

	const renderMembersList = (chatListParticipantsDetailsArranged: UserProfileDetails[]) => {
		return chatListParticipantsDetailsArranged.map((chatListParticipantDetails) => {
			return (
				<Col xs={24} lg={24} key={chatListParticipantDetails.id.toString()}>
					<List style={{ padding: 0 }}>
						<ListItem style={{ padding: 0, marginBottom: 12 }}>
							<Col xs={5} md={4} lg={4}>
								{!!chatListParticipantDetails?.profile_photo ? (
									<img
										src={`${photoUrl}/${chatListParticipantDetails?.id}/${chatListParticipantDetails?.profile_photo}`}
										className="chat-list-image-holder"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = avatarPlaceholder;
                    }}
									/>
								) : (
									<Avatar sx={{ width: 46, height: 46 }} variant="rounded">
										{chatListParticipantDetails.firstname.trim()?.charAt(0)?.toLocaleUpperCase()}
										{chatListParticipantDetails.lastname.trim()?.charAt(0)?.toLocaleUpperCase() ?? ''}
									</Avatar>
								)}
							</Col>
							<Col xs={19} md={20} lg={20}>
								<Row style={{ marginBottom: 6 }}>
									<Col xs={24} md={24} lg={24} className="group-users-name">
										{chatListParticipantDetails.firstname} {chatListParticipantDetails.lastname ?? ''}{' '}
										{isAdmin(chatListParticipantDetails) ? (
											<span className="group-admin-status">
												{' '}
												{returnUserRoleInGroupChat(chatListParticipantDetails)}
											</span>
										) : (
											''
										)}
									</Col>
								</Row>
								<Row>
									<Col>
										<BusinessCenterRoundedIcon color="disabled" fontSize="small" />
									</Col>
									<Col style={{ paddingTop: 2 }}>
										{chatListParticipantDetails?.userInfos?.jobTitle}
									</Col>
								</Row>
							</Col>
						</ListItem>
					</List>
				</Col>
			);
		});
	};

	return (
		<Modal backdrop={true} size="xs" open={displayGroupDetails} onClose={handleClose}>
			<div>
				<Col
					xs={24}
					lg={24}
					style={{
						backgroundColor: '#4E9E57',
						color: '#FFFFFF',
						fontSize: 70,
						textAlign: 'center',
						borderTopLeftRadius: 12,
						borderTopRightRadius: 12,
						marginBottom: 22,
					}}
				>
					{getGroupChatNameInitials().toLocaleUpperCase()}
				</Col>
			</div>
			<Row style={{ padding: 20 }}>
				<Col xs={24} lg={24} className="group-chat-name">
					{getGroupChatName()}
				</Col>
				<Col xs={24} lg={24} className="group-chat-description">
					{chatInFocus.description}
				</Col>
			</Row>

			{programDetails?.programEnterprises?.slug && programDetails?.slug ? (
				<div className="programme-type-container">
					<Row>
						<Col xs={10} lg={10} className="programme-label">
							Programme
						</Col>
						<Col xs={14} lg={14} className="go-to-programme-link" onClick={goToProgram}>
							Go to programme
							<EastIcon fontSize="small" style={{ paddingLeft: 4 }} />
						</Col>
					</Row>
					<p className="programme-name">{programDetails?.name}</p>
					{programDetails?.programType ? <p className="programme-type-label">Programme Type</p> : ''}
					<p className="programme-type">{programDetails?.programType}</p>
				</div>
			) : (
				''
			)}
			<Row className="group-members-container">
				<Col xs={24} lg={24} className="group-members-and-count">
					Members ({chatListParticipantsDetails.length})
				</Col>
				{displayAdminMembersList()}
				{displayNonAdminMembersList()}
			</Row>
		</Modal>
	);
}

export default GroupDetails;
