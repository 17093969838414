import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { updatePasswordEndPoint } from '../services/userService';

// UI
import { Button, ButtonToolbar, Container, FlexboxGrid, Form, Message, Schema, toaster } from 'rsuite';
import InputPasswordComponent from '../components/auth/InputPassword';
import InputField from '../components/common/InputField';
import Logo from '../components/common/Logo';

type newPasswordInput = {
	code?: string;
	password?: string;
	verifyPassword?: string;
};

export function UpdatePasswordPage() {
	const [formError, setFormError] = useState({});
	const [formValues, setformValues] = useState<newPasswordInput>({
		code: '',
		password: '',
		verifyPassword: '',
	});
	const navigate = useNavigate();
	const formNewPasswordValue = (data: newPasswordInput) => {
		setformValues(data);
	};

	const handleSubmit = () => {
		updatePasswordEndPoint(formValues)
			.then((res) => {
				navigate('../login');
			})
			.catch((err) => {
				console.error('err:', err);
				// TODO: Set error msg
				toaster.push(
					<Message type="error" showIcon>
						Update password failed
					</Message>,
					{ placement: 'bottomCenter' }
				);
			});
	};

	// Retrieve email value from sessionStorage
	const emailStorage = sessionStorage.getItem('userEmail');

	const { StringType } = Schema.Types;
	const model = Schema.Model({
		code: StringType().isRequired('This field is required.').rangeLength(8, 8, 'This is an invalid code.'),
		password: StringType()
			.isRequired('This field is required.')
			.minLength(6, 'Your password needs to be at least 6 characters.')
			.containsUppercaseLetter('Your password requires at least 1 uppercase letter.')
			.containsLowercaseLetter('Your password requires at least 1 lowercase letter.')
			.pattern(/.*[*.!@#$%^&(){}\]:;<>,.?\/~_+\-=|].*$/, 'Your password requires at least 1 special character.'),
		verifyPassword: StringType()
			.addRule((value) => {
				if (value !== formValues?.password) {
					return false;
				}
				return true;
			}, 'The two passwords does not match')
			.isRequired('This field is required.'),
	});

	return (
		<Container className="w-full max-w-sm">
			<FlexboxGrid justify="center" align="middle">
				<FlexboxGrid.Item colspan={24}>
					<Logo className="px-6 mb-4" />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={24} className="main-wrapper">
					<h3 className="text-black text-xl font-medium mb-4">Reset Password</h3>
					<Message className="mb-4">
						A password reset code has been sent to <b>{emailStorage}</b>. Please check the email and enter
						the 6-digit code below.
					</Message>
					<Form
						fluid
						model={model}
						onSubmit={handleSubmit}
						onChange={formNewPasswordValue}
						onCheck={setFormError}
						formError={formError}
						checkTrigger={'none'}
					>
						<Form.Group>
							<InputField name={'code'} placeholder={'6-digit code'} label={'Enter the code'} />
							<InputPasswordComponent
								name={'password'}
								placeholder={'New Password'}
								label={'New Password'}
								showTooltip
							/>
							<InputPasswordComponent
								name={'verifyPassword'}
								placeholder={'Verify password'}
								label={'Verify password'}
							/>
						</Form.Group>
						<ButtonToolbar>
							<Button appearance="primary" type="submit" block>
								Update Password
							</Button>
						</ButtonToolbar>
					</Form>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</Container>
	);
}
