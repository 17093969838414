// React
import React from 'react';

// FutureLab
import { ChatListDataInterface, ChatParticipantsInterface } from '../ChatListItemsMobile';
import { ChatRoomItemsInterface } from '../ChatRoom';
import { pinMessage, deleteMessages, unpinMessage } from '../../../services/chatService';
import { addPinnedChatMessage, removePinnedChatMessage } from '../../../actions/pinnedMessagesActions';
import { store } from '../../../ReduxRoot';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import { UserProfileDetails } from '../../../pages';
import { downloadAttachment } from '../../../utilities/DownloadAttachment';

// UI
import { Col, FlexboxGrid } from 'rsuite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadIcon from '@rsuite/icons/FileDownload';

// 3rd Party
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

interface ChatRoomMessageProps {
	loggedInUserParticipantId: ChatParticipantsInterface | undefined;
	chatItem: ChatRoomItemsInterface;
	setChatRoomItems: any;
	chatRoomItems: any;
	chatInFocus: ChatListDataInterface;
}

const renderChatMessage = (chatItem: ChatRoomItemsInterface) => {
	return (
		<>
			{_.isEmpty(chatItem.attachments) || chatItem.attachments?.[0] === null ? (
				<>{chatItem.message}</>
			) : (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="pb-1.5">
						{chatItem.message}
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="chat-attachment-border">
						{/* <FlexboxGrid.Item as={Col} xs={3} md={3} lg={2}>
              <MoreVertIcon />
            </FlexboxGrid.Item> */}
						<FlexboxGrid.Item
							as={Col}
							xs={19}
							md={19}
							lg={20}
							className="min-h-10 flex items-center break-all"
						>
							<span className="attachment-name">{chatItem.attachments?.[0].fileName}</span>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item as={Col} xs={5} md={5} lg={4} className="text-right">
							<IconButton onClick={() => downloadAttachment(chatItem.attachments?.[0])}>
								<FileDownloadIcon />
							</IconButton>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</>
	);
};

export function ChatRoomMessage(chatRoomMessageProps: ChatRoomMessageProps) {
	const { loggedInUserParticipantId, chatItem, setChatRoomItems, chatRoomItems, chatInFocus } = chatRoomMessageProps;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
	const [displayModalConfirmation, setDisplayModalConfirmation] = React.useState<boolean>(false);
	const [optionStore, setOptionStore] = React.useState({});
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const chatSenderUserId = chatInFocus.participants.find((participant) => participant._id === chatItem.participantId);

	const chatSenderName: UserProfileDetails = store
		.getState()
		.userListProfilesDetails.find(
			(participant: UserProfileDetails) => participant?.id === chatSenderUserId?.userId
		);

	const chatSenderFullName = `${chatSenderName?.firstname} ${chatSenderName?.lastname ?? ''}`;

	const chatMessagesOption = [
		{ option: 'Pin Message', action: 'noConfirmationRequired' },
		{ option: 'Unpin Message', action: 'noConfirmationRequired' },
		{ option: 'Delete Message', action: 'deleteMessage' },
	];

	const checkPinnedStatus = chatItem?.pinned?.length > 0 ? chatMessagesOption[1] : chatMessagesOption[0];

	const checkMessagesOption =
		loggedInUserParticipantId?._id === chatItem?.participantId ||
		loggedInUserParticipantId?._id === chatItem?.participant
			? [checkPinnedStatus, chatMessagesOption[2]]
			: [checkPinnedStatus];

	const optionSelectorChatMessages = (option: string) => {
		if (option === chatMessagesOption[0].option) {
			pinMessage({
				chatId: chatItem.chatId.toLocaleString(),
				messageId: chatItem.id.toLocaleString(),
			}).then((res) => {
				store.dispatch(addPinnedChatMessage([res.data.data.pinMessages]));
				const updatedChatRoomItems = chatRoomItems.map((chatRoomItem: ChatRoomItemsInterface) => {
					if (chatRoomItem.id == res.data.data.pinMessages.chatMessageId.id) {
						const updatedChatRoomMessage = chatRoomItem;
						updatedChatRoomMessage.pinned = [res.data.data.pinMessages];
						return updatedChatRoomMessage;
					}
					return chatRoomItem;
				});
				handleClose();
				setChatRoomItems(updatedChatRoomItems);
			});
			mixpanel.track('Message Pinned', {
				'Chat Room Id': chatInFocus.id,
				'Is Group': chatInFocus.isGroupChat,
				'Group Name': chatInFocus.name,
				'Pinned Message': chatItem.message,
				Attachment: !_.isEmpty(chatItem.attachments),
			});
		}

		if (option === chatMessagesOption[1].option) {
			const pinnedMessagesId = chatItem.pinned.find((pinnedMessagesId: any) => pinnedMessagesId);
			unpinMessage({
				chatId: chatItem.chatId.toLocaleString(),
				pinnedMessagesId: pinnedMessagesId,
			}).then((res) => {
				store.dispatch(removePinnedChatMessage([res.data.data]));
				const updatedChatRoomItems = chatRoomItems.map((chatRoomItem: ChatRoomItemsInterface) => {
					if (chatRoomItem.id == res.data.data.chatMessageId) {
						const updatedChatRoomMessage = chatRoomItem;
						updatedChatRoomMessage.pinned = [];
						return updatedChatRoomMessage;
					}
					return chatRoomItem;
				});
				handleClose();
				setChatRoomItems(updatedChatRoomItems);
			});
		}

		if (option === chatMessagesOption[2].option) {
			// will be used in the confirmation modal popup
			deleteMessageCallBack(chatItem);
		}
	};

	const deleteMessageCallBack = (chatItem: ChatRoomItemsInterface) => {
		deleteMessages({
			chatId: chatItem.chatId.toLocaleString(),
			messageId: chatItem.id.toLocaleString(),
		}).then((res) => {
			if (res.data.data.status) {
				const parseDeleteMessage = JSON.parse(res.config.data);
				const filterNonDeletedMessage = chatRoomItems.filter(
					(chatItem: { id: number | string; chatId: number | string }) =>
						chatItem.chatId === parseDeleteMessage.chatId && chatItem.id !== parseDeleteMessage?.messageId
				);
				setChatRoomItems(filterNonDeletedMessage);
				setDisplayModalConfirmation(false);
			}
		});
	};

	const openPopUp = (option: string, action: string) => {
		if (action !== 'noConfirmationRequired') {
			setDisplayModalConfirmation(!displayModalConfirmation);
			handleClose();
			// optionSelectorChatMessages(option);
		}

		if (action === 'noConfirmationRequired') {
			optionSelectorChatMessages(option);
		}
	};

	const renderMessageOption = () => {
		return checkMessagesOption.map((chatMessageOption, index) => (
			<div key={index} onClick={() => setOptionStore(chatMessageOption)}>
				<MenuItem
					key={chatMessageOption?.option}
					onClick={() => openPopUp(chatMessageOption?.option, chatMessageOption?.action)}
				>
					{chatMessageOption?.option}
				</MenuItem>
			</div>
		));
	};

	const ITEM_HEIGHT = 38;

	return (
		<div>
			{loggedInUserParticipantId?._id === chatItem?.participantId ||
			loggedInUserParticipantId?._id === chatItem?.participant ? (
				<FlexboxGrid>
					<FlexboxGrid.Item
						as={Col}
						colspan={24}
						xs={19}
						md={14}
						lg={14}
						xsOffset={3}
						mdOffset={8}
						lgOffset={9}
						className="bg-blue-50 shadow-sm rounded-lg border-2 text-gray-900 border-solid text-sm px-2.5 py-3"
					>
						{renderChatMessage(chatItem)}
					</FlexboxGrid.Item>
					{/* <Col xs={1} md={1} lg={1}> */}
					<IconButton onClick={handleClick} style={{ padding: 0 }}>
						<MoreVertIcon htmlColor="#575757" />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: '20ch',
								textAlign: 'center',
							},
						}}
					>
						{renderMessageOption()}
					</Menu>
					{/* </Col> */}
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={() => deleteMessageCallBack(chatItem)}
						/>
					)}
				</FlexboxGrid>
			) : (
				<FlexboxGrid>
					<FlexboxGrid.Item
						as={Col}
						colspan={24}
						xs={10}
						md={10}
						lg={10}
						className="truncate text-xs text-gray-900 ml-3 font-medium"
					>
						{chatInFocus.isGroupChat ? chatSenderFullName : ''}
					</FlexboxGrid.Item>
					<FlexboxGrid.Item
						as={Col}
						colspan={24}
						xs={19}
						md={14}
						lg={14}
						className="bg-white shadow-sm border-2 border-solid text-gray-900 text-sm rounded-lg px-2.5 py-3 ml-3"
					>
						{renderChatMessage(chatItem)}
					</FlexboxGrid.Item>
					{/* <Col xs={1} md={1} lg={1}> */}
					<div>
						<IconButton onClick={handleClick} style={{ padding: 0 }}>
							<MoreVertIcon htmlColor="#575757" />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							PaperProps={{
								style: {
									maxHeight: ITEM_HEIGHT * 4.5,
									width: '20ch',
									textAlign: 'center',
								},
							}}
						>
							{renderMessageOption()}
						</Menu>
					</div>
					{/* </Col> */}
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={deleteMessageCallBack}
						/>
					)}
				</FlexboxGrid>
			)}
		</div>
	);
}
