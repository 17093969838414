import { FeedbackSessionFormInFocus, FeedbackSessionFormInFocusAction } from '../model/index';

export function feedbackSessionFormInFocus(
	feedbackSessionFormInFocus: number | null
): FeedbackSessionFormInFocusAction {
	return {
		type: FeedbackSessionFormInFocus.FEEDBACK_SESSION_FORM_IN_FOCUS,
		payload: feedbackSessionFormInFocus,
	};
}
