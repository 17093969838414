// React
import { useSelector } from 'react-redux';

// UI
import { FlexboxGrid } from 'rsuite';

// FutureLab
import { ChatListMenu } from './ChatListMenu';
import { ChatRoomPage } from '../../pages/ChatRoomPage';
import { DesktopChatRoomHeader } from './DesktopChatRoomHeader';
import { RootState } from '../../reducers/index';
import { ChatListChatRequestRoom } from './ChatListChatRequestRoom';
import { isChatApproved } from '../../utilities/Chat';

interface ChatPageProps {
	isMobile: boolean
}

export function DesktopChat(chatPageProp: ChatPageProps) {
  const {isMobile} = chatPageProp
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const isLoading = useSelector((state: RootState) => state.showLoader);

	return (
		<FlexboxGrid className={isLoading ? 'hidden' : 'flex'}>
			<FlexboxGrid.Item colspan={isMobile ? 24 : 8} className="border-r border-solid">
				<ChatListMenu />
			</FlexboxGrid.Item>
			{!isMobile && (
				<FlexboxGrid.Item colspan={16}>
					{chatInFocus && loggedInUserDetails ? (
						<FlexboxGrid>
							<FlexboxGrid.Item colspan={24}>
								<DesktopChatRoomHeader />
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={24}>
								{isChatApproved(chatInFocus, loggedInUserDetails?.id) ? (
									<ChatRoomPage />
								) : (
									<ChatListChatRequestRoom />
								)}
							</FlexboxGrid.Item>
						</FlexboxGrid>
					) : (
						<FlexboxGrid className="flex h-screen items-center justify-center bg-gray-50 pb-24">
							<img className="h-60 w-60" src="../../../chat-room-empty-state.png" />
						</FlexboxGrid>
					)}
				</FlexboxGrid.Item>
			)}
		</FlexboxGrid>
	);
}
