// React
import React, { useEffect, useState } from 'react';

// FutureLab
import CompanyLogo from '../../common/CompanyLogo';
import { returnOneToOneChatUserDetails } from '../../../utilities/UserDetails';
import UserDetails, { ProfilePreviewData } from '../../popup/userDetails';
import { getUserInfo } from '../../../services/userService';
import { ModalConfirmation } from '../../popup/modalConfirmation';
import MobileChatTopNavBar from './MobileChatTopNavBar';

// UI
import { FlexboxGrid, Drawer } from 'rsuite';
import LogoutIcon from '@mui/icons-material/Logout';
import { MdMenu, MdOutlineLogout } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

// 3rd Party
import _ from 'lodash';
import { Box, Divider, IconButton } from '@mui/material';

interface MobileTopNavBarProps {
	logout: () => void;
	token: string;
}

export default function MobileTopNavBar(props: MobileTopNavBarProps) {
	const { logout, token } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const [loggedInUserProfileDetails, setLoggedInUserProfileDetails] = useState<ProfilePreviewData>();
  const [displayModalConfirmation, setDisplayModalConfirmation] = useState<boolean>(false);
	const photoUrl = `${process.env.REACT_APP_PROFILE_PHOTOS_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_BASE_URL}${process.env.REACT_APP_PROFILE_PHOTOS_S3_DIRECTORY_URL}`;
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const userProfileDetails = returnOneToOneChatUserDetails(chatInFocus, loggedInUserDetails, userListProfilesDetails);
	const loggedInUserFullName = `${loggedInUserProfileDetails?.firstname} ${loggedInUserProfileDetails?.lastname ?? ''}`;
  const optionStore = { action: 'leavingFL' };

	useEffect(() => {
		const loggedInUser = loggedInUserDetails;
		if (!_.isEmpty(loggedInUser) && token.length > 0) {
			getUserInfo({ userId: loggedInUserDetails.id }).then((res) => {
				setLoggedInUserProfileDetails(res?.data?.data);
			});
		}
	}, [loggedInUserDetails]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

  const navigateToOrigin = () => {
		const originUrl = localStorage.getItem('originURL');
		if (originUrl) {
			window.open(`${process.env.REACT_APP_PORTAL_BASE_URL}${originUrl}/overviews`, '_self');
		} else {
			window.open(`${process.env.REACT_APP_B2C_BASE_URL}main/dashboard`, '_self');
		}

		setDisplayModalConfirmation(!displayModalConfirmation);
    localStorage.removeItem('originURL');
	};

	return (
		<div style={{ fontFamily: 'DM Sans' }}>
			{!!chatInFocus?.id ? (
				<MobileChatTopNavBar
					chatInFocus={chatInFocus}
					loggedInUserDetails={loggedInUserDetails}
					userListProfilesDetails={userListProfilesDetails}
					photoUrl={photoUrl}
					userProfileDetails={userProfileDetails}
				/>
			) : (
				<Box className="flex fixed w-full mobile-navbar-top-container">
					<section className="py-3.5 pl-3 flex h-full" onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}>
						<CompanyLogo />
					</section>
					{token.length > 0 && (
						<section className="ml-auto flex">
							<IconButton size="large" onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}>
								<AiOutlineHome color="#dedede" size={24} />
							</IconButton>
							<IconButton size="large" onClick={handleClick}>
								<MdMenu color="#dedede" size={24} />
							</IconButton>
							<Drawer open={open} size="xs" onClose={handleClose}>
								<FlexboxGrid.Item className="user-name">{loggedInUserFullName}</FlexboxGrid.Item>
								<FlexboxGrid.Item className="user-email">{loggedInUserDetails.email}</FlexboxGrid.Item>
								{/* <FlexboxGrid.Item className="account-type"> Account type</FlexboxGrid.Item>
								<FlexboxGrid.Item className="user-type">
									{loggedInUserProfileDetails?.user_type === 1 ? 'Mentor' : 'Mentee'}
								</FlexboxGrid.Item> */}
								<FlexboxGrid.Item>
									<Divider />
								</FlexboxGrid.Item>
								<IconButton
									className="logout-button"
									onClick={() => {
										handleClose();
										logout();
									}}
								>
									<MdOutlineLogout size={24} className="mr-2" />
									Logout
								</IconButton>
							</Drawer>
						</section>
					)}
				</Box>
			)}
      {displayModalConfirmation && (
				<ModalConfirmation
					displayModalConfirmation={displayModalConfirmation}
					setDisplayModalConfirmation={setDisplayModalConfirmation}
					optionStore={optionStore}
					callBackProceedMethodProp={navigateToOrigin}
				/>
			)}
		</div>
	);
}
