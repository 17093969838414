// React
import React from 'react';

// UI
import { Button, ButtonToolbar, IconButton } from 'rsuite';
import FacebookIcon from '@mui/icons-material/Facebook';

declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		size?: string;
	}
}

export default function SignWithFacebookButtonComponent() {
	// set up the path to the facebook auth page
	const facebookAuth = () => {
		const facebookAuthUrl = `${process.env.REACT_APP_FACEBOOK_AUTH_URL}`;

		const generateRandomToken = (): string => {
			return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		};

		const options = {
			client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID as string,
			redirect_uri: process.env.REACT_APP_FACEBOOK_AUTH_REDIRECT_URI as string,
			response_type: 'code',
			scope: 'email',
			state: generateRandomToken(),
		};

		const qs = new URLSearchParams(options);

		return `${facebookAuthUrl}?${qs.toString()}`;
	};

	return (
		<ButtonToolbar>
			<IconButton
				icon={<FacebookIcon />}
				circle
				className="facebook-sign-in-button"
				appearance="link"
				size="lg"
				href={facebookAuth()}
			/>
		</ButtonToolbar>
	);
}
