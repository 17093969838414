// FutureLab
import { PinnedMessagesTypes, PinnedMessagesActions } from '../model/index';
import { PinnedMessageItem } from '../components/chat/ChatListMenu';

export function loadPinnedChatMessages(pinnedChatMessages: PinnedMessageItem[]): PinnedMessagesActions {
	return {
		type: PinnedMessagesTypes.LOAD_PIN_MESSAGES,
		payload: pinnedChatMessages,
	};
}

export function addPinnedChatMessage(pinnedChatMesages: PinnedMessageItem[]): PinnedMessagesActions {
	return {
		type: PinnedMessagesTypes.ADD_PIN_MESSAGES,
		payload: pinnedChatMesages,
	};
}

export function removePinnedChatMessage(pinnedChatMesages: PinnedMessageItem[]): PinnedMessagesActions {
	return {
		type: PinnedMessagesTypes.REMOVE_PIN_MESSAGES,
		payload: pinnedChatMesages,
	};
}

export function removeAllChatRoomPinnedMessages(pinnedChatMesages: PinnedMessageItem[]): PinnedMessagesActions {
	return {
		type: PinnedMessagesTypes.REMOVE_ALL_CHAT_ROOM_PINNED_MESSAGES,
		payload: pinnedChatMesages,
	};
}
