// React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Futurelab
import { getUserListsDetails, userDetails } from '../../services/userService';
import { chatListEndPoint, allPinnedMessages, chatPendingListEndPoint } from '../../services/chatService';
import { ChatListPinnedMessageLoop } from './ChatListPinnedMessageLoop';
import { ChatListChatRequestLoop } from './ChatListChatRequestLoop';
import { ChatListDataInterface, ChatParticipantsInterface } from './ChatListItemsMobile';
import { ChatList } from './ChatList';
import { RootState } from '../../reducers';
import { userListDetails } from '../../actions/userListDetailsActions';
import { loggedInUserDetails } from '../../actions/loggedInUserDetailsActions';
import { loadPinnedChatMessages } from '../../actions/pinnedMessagesActions';
import { store } from '../../ReduxRoot';
import { ChatRoomItemsInterface } from './ChatRoom';
import { loadPendingApprovalChatDetails } from '../../actions/pendingApprovalActions';
import { hideLoaderAction, showLoaderAction } from '../../actions/loaderActions';
import { chatList } from '../../actions/chatListActions';
import { getAllParticipantsIds } from '../../utilities/Chat';

// UI
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Badge from '@mui/material/Badge';
import SearchBar from 'material-ui-search-bar';

// 3rd party
import _ from 'lodash';

export interface PinnedMessageItem {
	chatId: ChatListDataInterface;
	chatMessageId: ChatRoomItemsInterface;
	id: number;
	messageSenderId: ChatParticipantsInterface;
	participantId: ChatParticipantsInterface;
}

export function ChatListMenu() {
	// redux store setup
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const loggedInUserDetail = useSelector((state: RootState) => state.loggedInUserDetails);
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const isLoading = useSelector((state: RootState) => state.showLoader);
	const chatListsItems = useSelector((state: RootState) => state.chatList);

	// useState initilizations
	const [value, setValue] = useState('1');
	const [pendingChatListsItems, setPendingChatListsItems] = useState<ChatListDataInterface[]>([]);
	const [filterQuery, setFilterQuery] = useState('');
	const [pinnedMessages, setPinnedMessages] = useState<PinnedMessageItem[]>([]);
	const [oldChatsList, setOldChatsList] = useState<any>([]);
	const [reachedEndOfChatList, setReachedEndOfChatList] = useState(false);
	const [callingApi, setCallingApi] = useState(false);
	const [apiLimit, setApiLimit] = useState(25);
	const [apiOffset, setApiOffset] = useState(0);
	const dispatch = useDispatch();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		const fetchDataAsync = async () => {
			try {
				// Fetch data from BE
				if (_.isEmpty(chatListsItems) || _.isEmpty(userListProfilesDetails)) {
					dispatch(showLoaderAction());
					await fetchData();
				}
        
				// Fetch user details data
				if (_.isEmpty(loggedInUserDetail)) {
					const userDetailsResponse = await userDetails();
					dispatch(loggedInUserDetails(userDetailsResponse.data.data));
				}

				// Fetch pinned messages component data
				const pinnedMessagesResponse = await allPinnedMessages();
				dispatch(loadPinnedChatMessages(pinnedMessagesResponse.data.data));
			} catch (error) {
				console.warn('Error:', error);
			} finally {
				dispatch(hideLoaderAction());
			}
		};

		fetchDataAsync();
	}, []);

	const fetchData = async () => {
		try {
			const chatListsdetails = await chatListEndPoint(apiOffset, apiLimit);
			const chatPendingListEndDetails = await chatPendingListEndPoint();

			const chatListsdetailsData = chatListsdetails?.data?.data;
			const chatPendingListEndDetailsData: ChatListDataInterface[] = chatPendingListEndDetails?.data?.data;
			// Extracted common logic for filtering participant IDs
			const allParticipantsIdsFiltered = getAllParticipantsIds(
				chatListsdetailsData,
				chatPendingListEndDetailsData
			);

			dispatch(loadPendingApprovalChatDetails(chatPendingListEndDetailsData));

			setPendingChatListsItems(chatPendingListEndDetailsData);
			dispatch(chatList(chatListsdetailsData));

			const userListDetailList = await getUserListsDetails({ userIds: allParticipantsIdsFiltered });
			const userListDetailsData = userListDetailList?.data?.data;
			dispatch(userListDetails(userListDetailsData));
		} catch (error) {
			console.warn('Error:', error);
		}
	};

	useEffect(() => {
		if (!reachedEndOfChatList && callingApi) {
			setApiOffset(apiOffset + 25);
		}
	}, [callingApi]);

	useEffect(() => {
		if (apiOffset > 0) {
			chatListEndPoint(apiOffset, apiLimit)
				.then((res) => {
					if (res?.data?.data?.length === 0) {
						setReachedEndOfChatList(true);
						setApiOffset(0);
					}
					setOldChatsList(res?.data?.data);
					const allParticipantsIdsFiltered: number[] = [];
					res?.data?.data.forEach((chatListItem: ChatListDataInterface) => {
						chatListItem.participants.forEach((participant) => {
							if (allParticipantsIdsFiltered.indexOf(participant.userId) === -1) {
								allParticipantsIdsFiltered.push(participant.userId);
							}
						});
					});
					getUserListsDetails({ userIds: allParticipantsIdsFiltered }).then((res) => {
						const userListDetailsData = res.data?.data;
						dispatch(userListDetails(userListDetailsData));
					});

					setCallingApi(false);
				})
				.catch((err) => {
					console.warn('Error:', err);
				});
		}
	}, [apiOffset]);

	useEffect(() => {
		if (!_.isEmpty(oldChatsList)) {
			dispatch(chatList(chatListsItems.concat(oldChatsList)));
			setTimeout(() => {
				// TODO seperate out the method and call the static method to keep the code dry
				const targetToScrolTo = document?.getElementsByClassName(oldChatsList[oldChatsList.length - 1]?.id);
				if (targetToScrolTo.length > 0) {
					targetToScrolTo[0].scrollIntoView();
				}
			}, 100);
		}
	}, [oldChatsList]);

	useEffect(() => {
		const newConversation = chatListsItems.map((con) => {
			if (con.id === chatInFocus?.id) {
				con.unreadCount = 0;
			}
			return con;
		});
		dispatch(chatList(newConversation));
	}, [chatInFocus]);

	useEffect(() => {
		setPinnedMessages(store.getState().pinnedMessages);
	}, [store.getState().pinnedMessages]);

	const callbackScroll = () => {
		if (!callingApi && !reachedEndOfChatList) {
			setCallingApi(true);
		}
	};

	const newMessageExists = (): boolean => {
		return chatListsItems.some((chatListsItem) => chatListsItem.unreadCount > 0);
	};
	const newChatRequestExists = (): boolean => {
		return store.getState().pendingApprovalChatList.length > 0;
	};

	return (
		<div className={isLoading ? 'hidden' : 'block'}>
			<div className="p-3">
				<SearchBar
					style={{ height: 40 }}
					placeholder="Search messages or chat"
					value={''}
					onChange={(newValue) => setFilterQuery(newValue)}
					onCancelSearch={() => setFilterQuery('')}
				/>
			</div>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList variant="fullWidth" onChange={handleChange}>
						<Tab
							label={
								<Badge
									className="text-base capitalize"
									variant="dot"
									color="error"
									invisible={!newMessageExists()}
								>
									Messages
								</Badge>
							}
							value="1"
							style={{ fontFamily: 'DM Sans' }}
						/>
						<Tab
							label={
								<Badge
									className="text-base capitalize"
									variant="dot"
									color="error"
									invisible={!newChatRequestExists()}
								>
									Request
								</Badge>
							}
							value="2"
							style={{ fontFamily: 'DM Sans' }}
						/>
						<Tab
							label={<Badge className="text-base capitalize">Pinned</Badge>}
							value="3"
							style={{ fontFamily: 'DM Sans' }}
						/>
					</TabList>
				</Box>
				<TabPanel value="1" sx={{ padding: 0 }}>
					<ChatList
						userListProfilesDetails={userListProfilesDetails}
						loggedInUserDetail={loggedInUserDetail}
						chatListsItems={chatListsItems}
						filterQuery={filterQuery}
						setFilterQuery={setFilterQuery}
						callbackScroll={callbackScroll}
					/>
				</TabPanel>
				<TabPanel value="2" sx={{ padding: 0 }}>
					<ChatListChatRequestLoop
						pendingChatListsItems={pendingChatListsItems}
						filterQuery={filterQuery}
						userListProfilesDetails={userListProfilesDetails}
						loggedInUserDetail={loggedInUserDetail}
					/>
				</TabPanel>
				<TabPanel value="3" sx={{ padding: 0 }}>
					<ChatListPinnedMessageLoop
						userListProfilesDetails={userListProfilesDetails}
						filterQuery={filterQuery}
						pinnedMessages={pinnedMessages}
						chatListsItems={chatListsItems}
						setPinnedMessages={setPinnedMessages}
						loggedInUserDetail={loggedInUserDetail}
					/>
				</TabPanel>
			</TabContext>
		</div>
	);
}
