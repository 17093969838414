// React
import React, { CSSProperties, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { ModalConfirmation } from '../popup/modalConfirmation';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getUserListsDetails, userDetails } from '../../services/userService';
import { userListDetails } from '../../actions/userListDetailsActions';
import { loggedInUserDetails } from '../../actions/loggedInUserDetailsActions';

// UI
import { FlexboxGrid, Message, ButtonToolbar, Button, Divider, Col } from 'rsuite';

// 3rd Party
import _ from 'lodash';
import { store } from '../../ReduxRoot';

export interface StylesDictionary {
	[Key: string]: CSSProperties;
}

export default function WorkInProgressLogo() {
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const loggedInUserDetail = useSelector((state: RootState) => state.loggedInUserDetails);
	const dispatch = useDispatch();
	const [displayModalConfirmation, setDisplayModalConfirmation] = React.useState<boolean>(false);
	const optionStore = { action: 'leavingFL' };
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const navigate = useNavigate();
	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		if (_.isEmpty(loggedInUserDetail)) {
			userDetails().then((res) => {
				dispatch(loggedInUserDetails(res.data.data));
			});
		}
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const navigateToDashBoard = () => {
		window.open(`${process.env.REACT_APP_B2C_BASE_URL}`, '_blank');
		setDisplayModalConfirmation(!displayModalConfirmation);
	};

	const navigateToMentorDirectory = () => {
		window.open(`https://futurelab.my/discover/mentors`, '_blank');
		// setDisplayModalConfirmation(!displayModalConfirmation);
	};

	useEffect(() => {
		const loggedInUserId = loggedInUserDetail.id;
		if (_.isEmpty(store.getState().userListProfilesDetails)) {
			getUserListsDetails({ userIds: [loggedInUserId] }).then((res) => {
				dispatch(userListDetails(res.data?.data));
			});
		}
	}, [loggedInUserDetail]);

	const userInfo = userListProfilesDetails.find(
		(userProfileDetails) => userProfileDetails.id === loggedInUserDetail.id
	);

	return (
		<FlexboxGrid justify="center" align="middle">
			<FlexboxGrid.Item
				colspan={24}
				className={
					getWindowSize(windowSize.width) === windowSizeType.XS
						? 'homepage-container'
						: 'homepage-container-desktop'
				}
			>
				<FlexboxGrid justify="center" align="middle">
					<FlexboxGrid.Item colspan={22} className="flex justify-center pb-3">
						<img
							src={
								getWindowSize(windowSize.width) === windowSizeType.XS
									? '../../../dashboard-banner.png'
									: '../../../dashboard-banner-desktop.png'
							}
							className="rounded-xl flex center"
						/>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24}>
						<FlexboxGrid justify="center" align="middle">
							{_.isEmpty(userInfo?.userRoles) ? (
								<>
									<FlexboxGrid.Item
										as={Col}
										xs={22}
										md={11}
										lg={11}
										xl={8}
										xxl={6}
										className="submit-session-feedback-button"
										onClick={() => navigateToMentorDirectory()}
									>
										<span className="table-cell align-middle w-24 pl-2 pr-3">
											<img
												src="../../../find-mentor.png"
												className="inline px-4 py-4 bg-blue-100 bg-opacity-30 rounded-2xl"
											/>
										</span>
										<span className="table-cell align-middle">Find A Mentor</span>
									</FlexboxGrid.Item>
								</>
							) : (
								<>
									<FlexboxGrid.Item
										onClick={() => navigate('feedback-session')}
										as={Col}
										xs={22}
										md={11}
										lg={11}
										xl={8}
										xxl={6}
										className="submit-session-feedback-button"
									>
										<span className="table-cell align-middle w-24 pl-2 pr-3">
											<img
												src="../../../feedback-session.png"
												className="inline px-4 py-4 bg-blue-100 bg-opacity-30 rounded-2xl"
											/>
										</span>
										<span className="table-cell align-middle">Submit Session Feedback</span>
									</FlexboxGrid.Item>
								</>
							)}
							<FlexboxGrid.Item
								onClick={() => navigate('chat')}
								as={Col}
								xs={22}
								md={11}
								lg={11}
								xl={8}
								xxl={6}
								className="chat-button"
							>
								<span className="table-cell align-middle w-24 pl-2 pr-3">
									<img
										src="../../../chat.png"
										className="inline px-4 py-4 bg-blue-100 bg-opacity-30 rounded-2xl"
									/>
								</span>
								<span className="table-cell align-middle">Chat</span>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item
						onClick={() => setDisplayModalConfirmation(!displayModalConfirmation)}
						as={Col}
						xs={22}
						md={11}
						lg={11}
						xl={8}
						xxl={6}
						className="go-to-fl-button"
					>
						<span className="table-cell align-middle w-24 pl-2 pr-3">
							<img
								src="../../../fl-dashboard.png"
								className="inline px-4 py-4 bg-blue-100 bg-opacity-30 rounded-2xl"
							/>
						</span>
						<span className="table-cell align-middle">Go to FL Dashboard</span>
					</FlexboxGrid.Item>
					{displayModalConfirmation && (
						<ModalConfirmation
							displayModalConfirmation={displayModalConfirmation}
							setDisplayModalConfirmation={setDisplayModalConfirmation}
							optionStore={optionStore}
							callBackProceedMethodProp={navigateToDashBoard}
						/>
					)}
				</FlexboxGrid>
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
