// React
import * as localforage from 'localforage';

// Redux
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// Future Lab
import rootReducer from './reducers/index';

// configure all the reducer configs here
const persistConfig: PersistConfig<any> = {
	key: 'root',
	version: 1,
	storage: localforage,
	blacklist: [
		'showLoader',
		'chatList',
		'userListProfilesDetails',
		'userLoggedIn',
		'loggedInUserDetails',
		'chatInFocus',
		'pinnedMessages',
		'pinnedMessageInfocus',
		'feedbackFormInFocus',
		'programQuestion',
		'programAnswer',
	],
};

const logger = (createLogger as any)();
const dev = process.env.NODE_ENV === 'development';
let middleware = dev ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

if (dev) {
	middleware = composeWithDevTools(middleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer());

export default () => {
	const store = createStore(persistedReducer, {}, middleware) as any;
	const persistor = persistStore(store);
	return { store, persistor };
};
