// FutureLab
import { ChatListDataInterface } from './ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../reducers/index';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { getQueryResults } from '../../utilities/Chat';
import { ChatListItemsMobile } from './ChatListItemsMobile';

interface ChatListItemsMobileLoopProps {
	chatListsItems: ChatListDataInterface[];
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	filterQuery: string;
	targetRef: any;
}

export function ChatListItemsMobileLoop(chatListItemsMobileLoop: ChatListItemsMobileLoopProps) {
	const { chatListsItems, loggedInUserDetails, userListProfilesDetails, filterQuery, targetRef } =
		chatListItemsMobileLoop;
	const results = getQueryResults(chatListsItems, loggedInUserDetails, userListProfilesDetails, filterQuery);
	const target = results.length - 5 || 0;
	return (
		<>
			{results.map((chatListItem: ChatListDataInterface, index: number) => {
				return (
					<ChatListItemsMobile
						targetRef={index === target ? targetRef : null}
						key={index}
						chatListItem={chatListItem}
					></ChatListItemsMobile>
				);
			})}
		</>
	);
}
