// UI
import { DoneAll, Done } from '@material-ui/icons';

// 3rd Party
import moment from 'moment';
import { FlexboxGrid, Col } from 'rsuite';

// FutureLab
import { ChatListDataInterface, ChatParticipantsInterface } from '../ChatListItemsMobile';
import { ChatRoomItemsInterface } from '../ChatRoom';

interface ChatRoomMessageDetailsProps {
	loggedInUserParticipantId: ChatParticipantsInterface | undefined;
	chatItem: ChatRoomItemsInterface;
	chatInFocus: ChatListDataInterface;
}

export function ChatRoomMessageDetails(chatRoomMessageDetailsProps: ChatRoomMessageDetailsProps) {
	const { loggedInUserParticipantId, chatItem, chatInFocus } = chatRoomMessageDetailsProps;

	const returnFormattedTime = (time: string) => {
		return moment(time).format('h:mma');
	};

	return (
		<FlexboxGrid>
			<FlexboxGrid.Item colspan={24}>
				{loggedInUserParticipantId?._id === chatItem?.participantId ||
				loggedInUserParticipantId?._id === chatItem?.participant ? (
					<FlexboxGrid.Item
						className="text-right text-xs text-gray-500 mb-2.5 mt-1.5"
						colspan={23}
						as={Col}
						xs={6}
						md={4}
						lg={3}
						xsOffset={16}
						mdOffset={18}
						lgOffset={20}
					>
						{returnFormattedTime(chatItem.createdAt)}
						{chatItem?.seenBy?.length >= chatInFocus?.participants.length ? (
							<DoneAll className="read-receipt-font-size read-receipt-color" />
						) : (
							<Done className="read-receipt-font-size" />
						)}
					</FlexboxGrid.Item>
				) : (
					<FlexboxGrid.Item className="text-gray-500 text-xs ml-3 mb-2.5 mt-1.5" colspan={23} as={Col}>
						{returnFormattedTime(chatItem.createdAt)}
						{chatItem?.seenBy?.length >= chatInFocus?.participants.length ? (
							<DoneAll className="read-receipt-font-size read-receipt-color" />
						) : (
							<Done className="read-receipt-font-size" />
						)}
					</FlexboxGrid.Item>
				)}
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);
}
