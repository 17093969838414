import React from 'react';

// UI
import Logo from '../components/common/Logo';
import { Container, Divider, FlexboxGrid } from 'rsuite';
import SignupFormComponent from '../components/auth/SignupForm';
import SignWithGoogleButtonComponent from '../components/auth/SignWithGoogleButton';

export function SignupPage() {
	return (
		<Container className="w-full max-w-sm">
			<FlexboxGrid justify="center" align="middle">
				<FlexboxGrid.Item colspan={24}>
					<Logo className="px-6 mb-4" />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={24} className="main-wrapper">
					<h3 className="text-black text-xl font-medium mb-4">Sign up as a mentee</h3>
					<SignupFormComponent />
					<Divider>Or sign up with</Divider>
					{/* <SignWithGoogleButtonComponent /> */}
					<div className="mt-8">
						<p className="text-xs text-center">
							By signing up, you agree to FutureLab's Terms of Service and acknowledge the Privacy Policy.
						</p>
					</div>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</Container>
	);
}
