// React
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// FutureLab
import DesktopNavbar from './components/nav/desktop/DesktopNavbar';
import MobileNavBar from './components/nav/mobile/MobileNavbar';
import { getWindowSize, windowSizeType } from './utilities/WindowResize';
import { refreshAccessTokenEndPoint, logout as signOut } from './services/userService';
import { initNotification } from './services/chatService';
import useTrackPageView from './utilities/MixpanelTrackers';
import { initializeNavigation } from './utilities/NavigateTo';

// 3rd Party
import Cookies from 'js-cookie';
import queryString from 'query-string';

// added firebase
import './firebase';
import { messageListener, getFCMToken } from './firebase';

const pwaCookie = process.env.PWA_COOKIE_NAME || 'pwa_staging_session';

export interface WindowResizeInterface {
	width: number;
	height: number;
}

export default function AppChild() {
	const intervalConst = useRef<NodeJS.Timeout>();
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const navigate = useNavigate();
	const location = useLocation();
	const [token, setToken] = useState('');
	const [FCMToken, setFCMToken] = useState('');
	const hasInit = useRef(false);

	getFCMToken(setFCMToken);

	messageListener().then((message: any) => {
		console.log(message?.data);
	});

	// to allow the useLocation to be called by wrapping in the parent route
	useTrackPageView();

	// to export the useNavigate function so functional non component are able to route
	initializeNavigation(navigate);

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		if (!hasInit.current && token && FCMToken) {
			initNotification({
				token: FCMToken,
			})
				.then(() => (hasInit.current = true))
				.catch((e) => console.log(e));
		}
	}, [token, FCMToken]);

	useEffect(() => {
		const token = Cookies.get('token') || '';
    const queryParams = queryString.parse(location.search)
    const originURL : any = queryParams.originURL;
		if (location.key === 'default' && !!!token) {

			// Store the redirectTo in localStorage
			localStorage.setItem('redirectTo', location.pathname);
		}

    if (location.key === 'default' && location.search) {
      localStorage.setItem('originURL', originURL);
		}

		if (!!!token) {
			navigate('../login');
		}

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const token = Cookies.get('token') || '';
		// if the token is found then need to reset the refresh interval again, specially for login it was cause the page to change
		if (!!token && !!!intervalConst) {
			setRefreshInterval();
		}
		setToken(!!token ? token : '');
		return () => intervalConst.current && clearInterval(intervalConst.current);
	}, [navigate]);

	const setRefreshInterval = () => {
		const intervalEffect = setInterval(() => {
			refreshAccessTokenEndPoint({ refreshToken: Cookies.get('refreshToken') })
				.then((res) => {
					//set cookie expire in 1 year
					Cookies.set('token', res?.data?.data?.accessToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
					Cookies.set(pwaCookie, res?.data?.data?.railsToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
					Cookies.set('refreshToken', res?.data?.data?.refreshToken, {
						domain: process.env.REACT_APP_DOMAIN_NAME,
						expires: 365,
					});
				})
				.catch((err) => {
					console.error('Error:', err);
				});
		}, 300000); // set the refresh access token to 5 mins by default (60 * 5 = 300,000)
		intervalConst.current = intervalEffect;
	};

	const logout = async () => {
		const { status } = await signOut();
		Cookies.remove('token', {
			domain: process.env.REACT_APP_DOMAIN_NAME,
		});
		Cookies.remove('refreshToken', {
			domain: process.env.REACT_APP_DOMAIN_NAME,
		});
		Cookies.remove(pwaCookie, {
			domain: process.env.REACT_APP_DOMAIN_NAME,
		});
		navigate('../login');
		if (intervalConst.current) {
			clearInterval(intervalConst.current);
			intervalConst.current = undefined;
		}
	};

	return (
		<>
			{/* Render mobile or desktop view based on the screen size */}
			{getWindowSize(windowSize.width) === windowSizeType.XS ? (
				<>
					<MobileNavBar logout={logout} token={token} />
					<div className="landing-page-body">
						<Outlet />
					</div>
				</>
			) : (
				<>
					<DesktopNavbar logout={logout} token={token} windowSize={windowSize} />
					<div className="landing-page-body">
						<Outlet />
					</div>
				</>
			)}
		</>
	);
}
