// FutureLab
import {
	SessionDetailFormInterface,
	SessionFeedbackAnswerInterface,
	SessionFeedbackFormInterface,
} from '../components/feedback/SessionFeedback';
import { FeedbackSessionAnswer, FeedbackSessionAnswerAction } from '../model/index';

export function updateSessionDetailForm(sessionDetailForm: SessionDetailFormInterface): FeedbackSessionAnswerAction {
	return {
		type: FeedbackSessionAnswer.UPDATE_SESSION_DETAIL_FORM,
		payload: sessionDetailForm,
	};
}

export function updateSessionFeedbackForm(
	sessionFeedbackForm: SessionFeedbackFormInterface
): FeedbackSessionAnswerAction {
	return {
		type: FeedbackSessionAnswer.UPDATE_SESSION_FEEDBACK_FORM,
		payload: sessionFeedbackForm,
	};
}

export function resetSessionFeedbackForm() {
	return {
		type: FeedbackSessionAnswer.RESET_SESSION_FEEDBACK_FORM,
	};
}
