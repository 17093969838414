// React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// UI
import { FlexboxGrid, Col, ButtonToolbar, Button } from 'rsuite';

// Reducer
import { RootState } from '../../reducers/index';
import { store } from '../../ReduxRoot';

// FutureLab
import { acceptInvitation, declineInvitation } from '../../services/chatService';
import { removePendingApprovalChatDetails } from '../../actions/pendingApprovalActions';
import { chatItemInFocus } from '../../actions/chatItemInFocusActions';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { ModalConfirmation } from '../popup/modalConfirmation';
import mixpanel from 'mixpanel-browser';

export function ChatListChatRequestOption() {
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const [displayModalConfirmation, setDisplayModalConfirmation] = React.useState<boolean>(false);
	const optionStore = { action: 'rejectInvitation' };
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const navigate = useNavigate();
	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const acceptChatInvitation = async () => {
		const loggedInUserParticipantId = chatInFocus.participants.find((participant) => {
			if (getWindowSize(windowSize.width) === windowSizeType.XS) {
				navigate('chat/chat-room');
			}
			return participant.userId === loggedInUserDetails.id;
		});

		const chatDetails = {
			chatId: chatInFocus.id,
			participantId: loggedInUserParticipantId?._id,
		};

		try {
			await acceptInvitation(chatDetails);
			store.dispatch(removePendingApprovalChatDetails([chatInFocus]));
			store.dispatch(chatItemInFocus(null));
		} catch (error) {
			console.error('The following error occured: ', error);
		}

		mixpanel.track('Accepted Group Chat Invitation', {
			'Group Name': chatInFocus.name,
			'Group Id': chatInFocus.id,
		});
	};

	const declineChatInvitation = async () => {
		const loggedInUserParticipantId = chatInFocus.participants.find((participant) => {
			if (getWindowSize(windowSize.width) === windowSizeType.XS) {
				navigate('chat/chat-room');
			}
			return participant.userId === loggedInUserDetails.id;
		});

		const chatDetails = {
			chatId: chatInFocus.id,
			participantId: loggedInUserParticipantId?._id,
		};

		try {
			await declineInvitation(chatDetails);
			store.dispatch(removePendingApprovalChatDetails([chatInFocus]));
			store.dispatch(chatItemInFocus(null));
		} catch (error) {
			console.error('The following error occured: ', error);
		}
		mixpanel.track('Rejected Group Chat Invitation', {
			'Group Name': chatInFocus.name,
			'Group Id': chatInFocus.id,
		});
	};

	return (
		<FlexboxGrid.Item colspan={24} className="chat-request-option">
			<FlexboxGrid.Item colspan={24} as={Col} className="px-2 py-6 text-base text-center text-white">
				You're invited to the group. Accept to start chat with other groupmates.
			</FlexboxGrid.Item>
			<FlexboxGrid.Item colspan={24} as={Col}>
				<ButtonToolbar className="text-center pb-5">
					<Button
						className="text-base font-normal text-white bg-red-400 w-32"
						/* onClick={declineChatInvitation} */ onClick={() =>
							setDisplayModalConfirmation(!displayModalConfirmation)
						}
					>
						Reject
					</Button>
					<Button appearance='primary' className="text-base font-normal text-white w-32" onClick={acceptChatInvitation}>
						Accept
					</Button>
				</ButtonToolbar>
			</FlexboxGrid.Item>
			{displayModalConfirmation && (
				<ModalConfirmation
					displayModalConfirmation={displayModalConfirmation}
					setDisplayModalConfirmation={setDisplayModalConfirmation}
					optionStore={optionStore}
					callBackProceedMethodProp={declineChatInvitation}
				/>
			)}
		</FlexboxGrid.Item>
	);
}
