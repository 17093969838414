// FutureLab
import { ChatListDataInterface } from '../components/chat/ChatListItemsMobile';
import { ChatItemInFocusActions, ChatItemInFocus } from '../model/index';
import createReducer from './createReducer';

export const chatInFocus = createReducer<ChatListDataInterface | null>(null, {
	[ChatItemInFocus.CHAT_ITEM_IN_FOCUS](state: ChatListDataInterface | null, action: ChatItemInFocusActions) {
		state = action.payload;
		return state;
	},
});
