// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import { FlexboxGrid, Col } from 'rsuite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadIcon from '@rsuite/icons/FileDownload';

// 3rd Party
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

// FutureLab
import { PinnedMessageItem } from './ChatListMenu';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { ChatListDataInterface } from './ChatListItemsDesktop';
import { store } from '../../ReduxRoot';
import { deleteMessages, unpinMessage } from '../../services/chatService';
import { removePinnedChatMessage } from '../../actions/pinnedMessagesActions';
import { LoggedInUserDetails } from '../../reducers/index';
import { ModalConfirmation } from '../popup/modalConfirmation';
import { chatItemInFocus } from '../../actions/chatItemInFocusActions';
import { pinnedMessageInFocus } from '../../actions/pinnedMessageInFocusActions';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';

interface PinnedMessagesProps {
	userListProfilesDetails: UserProfileDetails[];
	pinnedMessage: PinnedMessageItem;
	setPinnedMessages: any;
	chatListsItems: ChatListDataInterface[];
	// filterQuery: any;
	loggedInUserDetail: LoggedInUserDetails;
}

const downloadAttachment = async (attachment: { key: string; fileName: string }) => {
	const link = document.createElement('a');

	try {
		const response = await axios.get(attachment.key, { responseType: 'blob' });
		const url = window.URL.createObjectURL(new Blob([response.data]));

		link.href = url;
		link.setAttribute('download', `${attachment.fileName}`);

		document.body.appendChild(link);
		link.click();
	} catch (error) {
		console.error(error);
		throw new Error('Error downloading attachment');
	} finally {
		document.body.removeChild(link);
	}
};

const renderChatMessage = (pinnedMessage: PinnedMessageItem) => {
	return (
		<>
			{_.isEmpty(pinnedMessage?.chatMessageId?.attachments) ||
			pinnedMessage?.chatMessageId?.attachments?.[0] === null ? (
				<>{pinnedMessage?.chatMessageId?.message}</>
			) : (
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={24} className="pb-1.5">
						{pinnedMessage?.chatMessageId?.message}
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={24} className="chat-attachment-border">
						{/* <FlexboxGrid.Item as={Col} xs={3} md={3} lg={2}>
              <MoreVertIcon />
            </FlexboxGrid.Item> */}
						<FlexboxGrid.Item
							as={Col}
							xs={19}
							md={18}
							lg={20}
							className="min-h-10 flex items-center break-all"
						>
							<span className="attachment-name">
								{pinnedMessage?.chatMessageId?.attachments?.[0].fileName}
							</span>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item as={Col} xs={5} md={3} lg={2} className="text-right">
							<IconButton>
								<FileDownloadIcon
									onClick={() => downloadAttachment(pinnedMessage?.chatMessageId?.attachments?.[0])}
								/>
							</IconButton>
						</FlexboxGrid.Item>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</>
	);
};

export function ChatListPinnedMessages(pinnedMessagesProps: PinnedMessagesProps) {
	const {
		userListProfilesDetails,
		loggedInUserDetail,
		pinnedMessage,
		setPinnedMessages,
		chatListsItems /* , filterQuery, loggedInUserDetail */,
	} = pinnedMessagesProps;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const open = Boolean(anchorEl);
	const [displayModalConfirmation, setDisplayModalConfirmation] = React.useState<boolean>(false);
	const [optionStore, setOptionStore] = React.useState({});
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const getUserNameForDisplay = (pinnedMessage: PinnedMessageItem): string => {
		const targetUser = userListProfilesDetails.find(
			(userProfilesDetails) => userProfilesDetails.id === pinnedMessage.messageSenderId.userId
		);
		return `${targetUser?.firstname} ${targetUser?.lastname ?? ''}`;
	};

	const chatMessagesOption = [
		{ option: 'Unpin Message', action: 'noConfirmationRequired' },
		{ option: 'Delete Message', action: 'deleteMessage' },
	];

	const checkMessagesOption =
		loggedInUserDetail.id === pinnedMessage.messageSenderId.userId ? chatMessagesOption : [chatMessagesOption[0]];

	const optionSelectorChatMessages = (option: string, pinnedMessage: PinnedMessageItem) => {
		if (option === chatMessagesOption[0].option) {
			unpinMessage({
				chatId: pinnedMessage.chatId,
				pinnedMessagesId: pinnedMessage.id,
			}).then((res) => {
				store.dispatch(removePinnedChatMessage([res.data.data]));
				setPinnedMessages(store.getState().pinnedMessages);
				handleClose();
			});
		}

		if (option === chatMessagesOption[1].option) {
			deleteMessageCallBack(pinnedMessage);
		}
	};

	const deleteMessageCallBack = (pinnedMessage: PinnedMessageItem) => {
		deleteMessages({
			chatId: pinnedMessage.chatId,
			messageId: pinnedMessage.chatMessageId?.id,
		}).then((res) => {
			if (res.data.data) {
				store.dispatch(removePinnedChatMessage([pinnedMessage]));
				setPinnedMessages(store.getState().pinnedMessages);
				handleClose();
				console.log('successfully deleted mesage'); // TODO put a handling here for successfully deleting a message
			}
		});
	};

	const openPopUp = (option: string, action: string, pinnedMessage: PinnedMessageItem) => {
		if (action !== 'noConfirmationRequired') {
			setDisplayModalConfirmation(!displayModalConfirmation);
			handleClose();
			// optionSelectorChatMessages(option, pinnedMessage);
		}

		if (action === 'noConfirmationRequired') {
			optionSelectorChatMessages(option, pinnedMessage);
		}
	};

	const renderMessageOption = (pinnedMessage: PinnedMessageItem) => {
		return checkMessagesOption.map((chatMessageOption, index) => (
			<div key={index} onClick={() => setOptionStore(chatMessageOption)}>
				<MenuItem
					key={chatMessageOption.option}
					onClick={() => openPopUp(chatMessageOption.option, chatMessageOption.action, pinnedMessage)}
				>
					{chatMessageOption.option}
				</MenuItem>
			</div>
		));
	};

	// {messagesOptions.map((option) => (
	//   <MenuItem key={option} onClick={() => optionSelectorChatMessages(option, pinnedMessage)}>
	//     {option}
	//   </MenuItem>
	// ))}

	const goToPinnedMessageInChatRoom = (pinnedMessage: PinnedMessageItem) => {
		const chatInFocus = chatListsItems.find(
			(chatListsItem) =>
				chatListsItem.id === (pinnedMessage.chatId as unknown as number) ||
				chatListsItem.id === pinnedMessage.chatId.id
		);
		if (chatInFocus) {
			store.dispatch(pinnedMessageInFocus(pinnedMessage));
			store.dispatch(chatItemInFocus(chatInFocus));

			if (getWindowSize(windowSize.width) === windowSizeType.XS) {
				navigate('../chat/chat-room');
			}
		}
	};

	const ITEM_HEIGHT = 38;

	return (
		<div>
			<FlexboxGrid className="pt-4">
				<FlexboxGrid.Item colspan={24} onClick={() => goToPinnedMessageInChatRoom(pinnedMessage)}>
					<FlexboxGrid.Item
						as={Col}
						colspan={23}
						xsOffset={1}
						mdOffset={1}
						lgOffset={1}
						className="pl-1 pb-1 text-sm text-gray-900 font-medium"
					>
						{getUserNameForDisplay(pinnedMessage)}
					</FlexboxGrid.Item>
					{/* {store.getState().pinnedMessages[index].participantId?.id ===
							store.getState().pinnedMessages[index].messageSenderId?.id ? (
							<FlexboxGrid.Item as={Col} colspan={8} className="pinned-by-me">
								<PinIcon style={{ marginRight: 4 }} />
								<span>Pinned by Me</span>
							</FlexboxGrid.Item>
							) : (
								''
							)} */}
				</FlexboxGrid.Item>
				<FlexboxGrid.Item
					as={Col}
					colspan={24}
					xs={20}
					md={20}
					lg={20}
					xsOffset={1}
					mdOffset={1}
					lgOffset={1}
					className="border-2 border-solid p-2 rounded-lg text-sm text-gray-900 shadow-sm"
					onClick={() => goToPinnedMessageInChatRoom(pinnedMessage)}
				>
					{renderChatMessage(pinnedMessage)}
				</FlexboxGrid.Item>
				<div>
					<IconButton onClick={handleClick} style={{ padding: 0 }}>
						<MoreVertIcon htmlColor="#575757" />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: '20ch',
								textAlign: 'center',
							},
						}}
					>
						{renderMessageOption(pinnedMessage)}
					</Menu>
				</div>
				<FlexboxGrid.Item
					as={Col}
					colspan={23}
					xsOffset={1}
					mdOffset={1}
					lgOffset={1}
					className="p-1 text-xs text-gray-500"
					onClick={() => goToPinnedMessageInChatRoom(pinnedMessage)}
				>
					{moment(pinnedMessage?.chatMessageId?.createdAt).format('dddd, MMMM Do YYYY, h:mma')}
				</FlexboxGrid.Item>
				{displayModalConfirmation && (
					<ModalConfirmation
						displayModalConfirmation={displayModalConfirmation}
						setDisplayModalConfirmation={setDisplayModalConfirmation}
						optionStore={optionStore}
						callBackProceedMethodProp={() => deleteMessageCallBack(pinnedMessage)}
					/>
				)}
			</FlexboxGrid>
		</div>
	);
}
