import createReducer from './createReducer';
import { SessionFeedbackAnswerInterface } from '../components/feedback/SessionFeedback';
import { FeedbackSessionAnswer, FeedbackSessionAnswerAction } from '../model/index';

import _ from 'lodash';

export const programAnswer = createReducer(
	{
		sessionDetailForm: {},
		sessionFeedbackForm: {},
	},
	{
		[FeedbackSessionAnswer.UPDATE_SESSION_DETAIL_FORM](
			state: SessionFeedbackAnswerInterface,
			action: FeedbackSessionAnswerAction
		) {
			return {
				...state,
				sessionDetailForm: {
					...state.sessionDetailForm,
					...action.payload,
				},
			};
		},
		[FeedbackSessionAnswer.UPDATE_SESSION_FEEDBACK_FORM](
			state: SessionFeedbackAnswerInterface,
			action: FeedbackSessionAnswerAction
		) {
			return {
				...state,
				sessionFeedbackForm: {
					...state.sessionFeedbackForm,
					...action.payload,
				},
			};
		},
		[FeedbackSessionAnswer.RESET_SESSION_FEEDBACK_FORM]() // state: SessionFeedbackAnswerInterface,
		// action: FeedbackSessionAnswerAction
		{
			return {
				sessionDetailForm: {},
				sessionFeedbackForm: {},
			};
		},
	}
);
