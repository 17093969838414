// React
import { useState, useEffect } from 'react';

// FutureLab
import SessionDetailForm from './form/SessionDetailForm';
import {
	listOfGroupInPrograms,
	listOfServices,
	programQuestions,
	userDetailsEndPoint,
} from '../../services/railsService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { userListDetails } from '../../actions/userListDetailsActions';
import { store } from '../../ReduxRoot';
import { userDetails } from '../../services/userService';
import { loggedInUserDetails } from '../../actions/loggedInUserDetailsActions';
import SessionFeedbackForm from './form/SessionFeedbackForm';
import SessionSummary from './SessionSummary';
import { feedbackSessionFormInFocus } from '../../actions/feedbackFormInFocusAction';
import { feedbackSessionQuestion } from '../../actions/feedbackQuestionAction';
import { updateSessionDetailForm, updateSessionFeedbackForm } from '../../actions/feedbackAnswerAction';

// UI
import { Col, FlexboxGrid, Form } from 'rsuite';

// 3rd Party
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import mixpanel from 'mixpanel-browser';

interface ProgramsDetailsInterface {
	id: number;
	full_name: string;
	profile_photo_url: string;
	programs: { id: number; name: string; description: string }[];
	user_type: string;
}

interface ServicesDetailsInterface {
	id: number;
	service_name: string;
	label: string;
	value: number;
}

export interface GroupDetailsInterface {
	group_id: number;
	mentee: { id: number; full_name: string; profile_photo: string };
	mentor: { id: number; full_name: string; profile_photo: string };
}

export interface ProgramQuestionInterface {
	active: boolean;
	category: string;
	checkboxes: string[];
	created_at: string;
	description: string;
	enterprise_id: number | null;
	id: number;
	options: string[];
	primary: boolean;
	primary_tag: any;
	program_id: number;
	required: boolean;
	stars: number;
	title: string;
	updated_at: string;
}

export interface SessionFeedbackAnswerInterface {
	sessionDetailForm: SessionDetailFormInterface;
	sessionFeedbackForm: SessionFeedbackFormInterface;
}

export interface SessionDetailFormInterface {
	bookingId: number;
	ratedParticipantId: number | undefined;
	programme: {
		label: string;
		value: number;
	};
	sessionWith: {
		label: string;
		value: {
			group_id: number;
			mentee: {
				id: number;
				full_name: string;
				// profile_photo: string;
				// score: number;
			};
			mentor: {
				id: number;
				full_name: string;
				// profile_photo: string;
				// score: number;
			};
		};
	};
	sessionType: number;
	sessionDate: Date | null;
	sessionTime: Date | null;
	sessionDuration: {
		label: string;
		value: number;
	};
	sessionService: {
		label: string;
		value: number;
	};
}

export interface SessionFeedbackFormInterface {
	submitted: string;
	feedback: {
		question_id: number;
		question_category: string;
		text: string | string[] | null;
		value: number | null;
	}[];
}

export default function SessionFeedback() {
	const methods = useForm();
	const userListProfilesDetails = useSelector((state: RootState) => state.userListProfilesDetails);
	const loggedInUserDetail = useSelector((state: RootState) => state.loggedInUserDetails);
	const currentFormInFocus = useSelector((state: RootState) => state.feedbackFormInFocus);
	const programQuestion = useSelector((state: RootState) => state.programQuestion);
	const programAnswer = useSelector((state: RootState) => state.programAnswer);
	const [programsDetails, setProgramsDetails] = useState<ProgramsDetailsInterface>();
	const [servicesDetails, setServicesDetails] = useState<ServicesDetailsInterface[]>([]);
	const [groupDetails, setGroupDetails] = useState<GroupDetailsInterface[]>([]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentFormInFocus === null) {
			dispatch(feedbackSessionFormInFocus(1));
		}

		async function fetchUserDetails() {
			if (_.isEmpty(loggedInUserDetail) && currentFormInFocus === 1) {
				const { data } = await userDetails();
				dispatch(loggedInUserDetails(data));
			}
		}

		async function fetchProgramDetails() {
			if (_.isEmpty(programsDetails) && !_.isEmpty(loggedInUserDetail) && currentFormInFocus === 1) {
				const { data } = await userDetailsEndPoint(loggedInUserDetail?.id);
				setProgramsDetails(data);
			}
		}

		async function fetchServiceDetails() {
			if (_.isEmpty(servicesDetails) && currentFormInFocus === 1) {
				const { data } = await listOfServices();
				setServicesDetails(data);
			}
		}

		fetchUserDetails();
		fetchProgramDetails();
		fetchServiceDetails();
	}, [currentFormInFocus]);

	const handleProgrammeChange = (programId: number) => {
		if (programId) {
			listOfGroupInPrograms(programId).then((res) => {
				const groups: GroupDetailsInterface[] = res.data.filter(
					(group: { mentee: { id: any }; mentor: { id: any } }) => {
						return group.mentee.id === loggedInUserDetail.id || group.mentor.id === loggedInUserDetail.id;
					}
				);
				setGroupDetails(groups);
			});
		}
	};

	const groupData = groupDetails.map((groupDetail) => ({
		label:
			groupDetail.mentee.id !== loggedInUserDetail.id
				? `${groupDetail.mentee.full_name}`
				: `${groupDetail.mentor.full_name}`,
		value: groupDetail,
	}));

	const programData = programsDetails?.programs.map((program) => ({ label: program.name, value: program.id }));

	const serviceData = servicesDetails.map((serviceDetails) => ({
		label: serviceDetails.label,
		value: serviceDetails.value,
	}));

	const sessionDurationData = [
		{ durationName: '30 minutes', durationValue: 30 },
		{ durationName: 'an hour', durationValue: 60 },
		{ durationName: 'an hour 30 minutes', durationValue: 90 },
		{ durationName: '2 hours', durationValue: 120 },
	].map((time) => ({ label: time.durationName, value: time.durationValue }));

	const handleSubmitSessionDetail = async (sessionDetails: SessionDetailFormInterface) => {
		try {
			const userRole = sessionDetails.sessionWith.value.mentee.id !== loggedInUserDetail.id ? 'mentee' : 'mentor';
			await dispatch(updateSessionDetailForm(sessionDetails));
			const programQuestionsResponse = await programQuestions(sessionDetails.programme.value, userRole);
			dispatch(feedbackSessionQuestion(programQuestionsResponse.data));
			await dispatch(feedbackSessionFormInFocus(2));
		} catch (error) {
			console.log('error: ', error);
		}

		mixpanel.track('Filled Session Details');
	};

	const handleSubmitSessionFeedback = (sessionFeedback: SessionFeedbackFormInterface) => {
		dispatch(updateSessionFeedbackForm(sessionFeedback));
		dispatch(feedbackSessionFormInFocus(3));
		mixpanel.track('Filled Session Feedback Form');
	};

	return (
		<FormProvider {...methods}>
			<Form>
				{currentFormInFocus === 1 && (
					<SessionDetailForm
						programData={programData}
						handleProgrammeChange={handleProgrammeChange}
						groupData={groupData}
						sessionDurationData={sessionDurationData}
						serviceData={serviceData}
						onSubmit={handleSubmitSessionDetail}
					/>
				)}
				{currentFormInFocus === 2 && <SessionFeedbackForm onSubmit={handleSubmitSessionFeedback} />}
				{currentFormInFocus === 3 && (
					<SessionSummary
						programQuestion={programQuestion}
						programAnswer={programAnswer}
						loggedInUserDetail={loggedInUserDetail}
					/>
				)}
			</Form>
		</FormProvider>
	);
}
