import { LoadUserProfilesDetailsActions, LoggedInUserDetails } from '../model/index';
import createReducer from './createReducer';

export const loggedInUserDetails = createReducer<{}>(
	{},
	{
		[LoggedInUserDetails.LOGGED_IN_USER_DETAILS](state: any[], action: LoadUserProfilesDetailsActions) {
			state = action.payload;
			return state;
		},
	}
);
