// FutureLab
import { ProgramQuestionInterface } from '../components/feedback/SessionFeedback';
import { FeedbackSessionQuestion, FeedbackSessionQuestionAction } from '../model/index';

export function feedbackSessionQuestion(
	feedbackSessionQuestion: ProgramQuestionInterface[]
): FeedbackSessionQuestionAction {
	return {
		type: FeedbackSessionQuestion.FEEDBACK_SESSION_QUESTION,
		payload: feedbackSessionQuestion,
	};
}
