// FutureLab
import { ChatListDataInterface, ChatListItemsDesktop } from './ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../reducers/index';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { getQueryResults } from '../../utilities/Chat';

interface ChatListItemsDesktopLoopProps {
	chatListsItems: ChatListDataInterface[];
	loggedInUserDetails: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	filterQuery: string;
	targetRef: any;
}

export function ChatListItemsDesktopLoop(chatListItemsDesktopLoop: ChatListItemsDesktopLoopProps) {
	const { chatListsItems, loggedInUserDetails, userListProfilesDetails, filterQuery, targetRef } =
		chatListItemsDesktopLoop;
	const results = getQueryResults(chatListsItems, loggedInUserDetails, userListProfilesDetails, filterQuery);

	const target = results.length - 5 || 0;
	return (
		<>
			{results.map((chatListItem: ChatListDataInterface, index: number) => {
				return (
					<ChatListItemsDesktop
						key={index}
						targetRef={index === target ? targetRef : null}
						chatListItem={chatListItem}
					></ChatListItemsDesktop>
				);
			})}
		</>
	);
}
